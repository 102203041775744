import React, { useState, useContext, useEffect } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { DOCUMENT_UPLOAD_TYPES } from "../../../constants/index.js";
import { RegisterDownloadContext } from "../../Application/RegisterDownloadContext.js";
import { downloadAllNewSubmissionDocuments, downloadFile } from "../../../utils/downloadFile.js";

export const DownloadButton = ({ 
    disabled, 
    className,
    display,
    document, 
    submissionDocumentsArray,
    type,
    style, 
    showIcon,
    loading,
    text, 
    beforeDownload, 
    afterDownload,
    ...otherProps
}) => { 
    const [downloadUnderway, setDownloadUnderway] = useState(false);
    const [isMounted, setIsMounted] = useState(true);
    const registerDownload = useContext(RegisterDownloadContext);
    
    // on component unmount, set a flag for use later :: 
    useEffect(() => () => setIsMounted(false), []);

    // handling of styles :: 
    const defaultStyle = {
        display: display === false ? "none" : "inherit" 
    };
    const appliedStyle = style && typeof style === "object" ? style : {};
    const styleToUse = Object.assign({}, defaultStyle, appliedStyle);

    // default type is for submission documents :: 
    const typeToUse = type && typeof type === "string" ? type : DOCUMENT_UPLOAD_TYPES.SUBMISSION;
    const classNameToUse = typeof className === "string" ? className : "";
    const textToUse = text && typeof text === "string" ? text : "Download";
    const filenameToUse = typeToUse === DOCUMENT_UPLOAD_TYPES.ARCHIVED ? document.id : document?.name;

    return (
        <Button
            disabled={Boolean(disabled) === true}
            className={classNameToUse}
            loading={loading || downloadUnderway}
            type="primary"
            icon={ showIcon === false ? null : <DownloadOutlined /> }
            style={styleToUse}
            onClick={async () => {
                setDownloadUnderway(true);
                if (typeof beforeDownload === "function") {
                    beforeDownload();
                }

                if (Array.isArray(submissionDocumentsArray)) {
                    await downloadAllNewSubmissionDocuments(submissionDocumentsArray, registerDownload);
                } else {
                    await downloadFile({ 
                        id: document.id, 
                        name: filenameToUse, 
                        type: typeToUse, 
                        path: document?.path, 
                        register: registerDownload 
                    });
                }
                
                if (isMounted) {
                    setDownloadUnderway(false);
                }
                if (typeof afterDownload === "function") {
                    afterDownload();
                }
            }}
            { ...otherProps }
        >
            { textToUse }
        </Button>
    );
};