import { useQuery } from "@apollo/client";
import { Pagination, Radio } from "antd";
import { Table } from "../common/Table/index.js";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../Application/UserContext.js";
import { formatDate, isoDateRange } from "../../utils/functions.js";
import { dateable, searchable } from "../../utils/index.js";
import { Main } from "../common/Main/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { ContractTypesCsvExport } from "./export.js";
import { contractTypesQuery } from "./query.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { defaultTablePageSize } from "../../constants/index.js";

const { usePersistentState } = PersistentState();

export const ContractTypes = () => {
    const defaultSortOn = "name";
    const defaultSortBy = "ascend";

    // normal state :: 
    const [dataToUse, setDataToUse] = useState(null);
    
    // persistent state :: 
    const [contractTypeTablePage, setContractTypeTablePage] = usePersistentState(1);
    const [contractTypeTablePageSize, setContractTypeTablePageSize] = usePersistentState(defaultTablePageSize);
    const [activeToggle, setActiveToggle] = usePersistentState(true);
    const [nameSearch, setNameSearch] = usePersistentState("");
    const [specifierSearch, setSpecifierSearch] = usePersistentState("");
    const [startDateFilter, setStartDateFilter] = usePersistentState([]);
    const [endDateFilter, setEndDateFilter] = usePersistentState([]);
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);

    const clearSearch = () => {
        setContractTypeTablePage(1);
        setActiveToggle(true);
        setNameSearch("");
        setSpecifierSearch("");
        setStartDateFilter([]);
        setEndDateFilter([]);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setContractTypeTablePageSize(defaultTablePageSize);

    };
    const {
        userPermCreateContractTypes,
        userPermViewContractTypes
    } = useContext(UserContext);

    let queryVariables = {
        offset: (contractTypeTablePage - 1) * contractTypeTablePageSize,
        limit: contractTypeTablePageSize,
        nameSearch,
        activeFilter: activeToggle,
        descriptionSearch: "",
        specifierSearch,
        startDateFilter: isoDateRange(startDateFilter),
        endDateFilter: isoDateRange(endDateFilter),
        sortOn,
        sortBy
    };

    const { loading, error, data } = useQuery(contractTypesQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse) {
        return null;
    }

    if (!userPermViewContractTypes) {
        return <NoPermission />;
    }

    const { contractTypes } = dataToUse;
    const contractTypesCount = contractTypes?.find(ct => "count" in ct)?.count ?? (contractTypes?.length ?? 0);

    const rightAlignedContent = userPermCreateContractTypes 
        ? <Radio.Group
            defaultValue={activeToggle}
            value={activeToggle}
            onChange={(e) => {
                setActiveToggle(e.target.value);
                setContractTypeTablePage(1);
            }}
            buttonStyle="solid"
        >
            <Radio.Button value={true}> Active </Radio.Button>
            <Radio.Button value={false}> Inactive </Radio.Button>
            <Radio.Button value={undefined}> All </Radio.Button>
        </Radio.Group>
        : null;


    const contractTypesTable = (
        <Table
            exportButton={<ContractTypesCsvExport variables={queryVariables} />}
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={clearSearch} 
                filtersData={[
                    { currentValue: activeToggle, defaultValue: true },
                    { currentValue: nameSearch, defaultValue: "" },
                    { currentValue: specifierSearch, defaultValue: "" },
                    { currentValue: startDateFilter, defaultValue: [] },
                    { currentValue: endDateFilter, defaultValue: [] } 
                ]}
            />}
            rightAlignedButtonBarElement={rightAlignedContent}
            style={{
                backgroundColor: "#fff"
            }}
            size="middle"
            pagination={false}
            columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, [
                searchable({
                    title: "Name",
                    dataIndex: "name",
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"],
                    handleSearch: setNameSearch,
                    setPage: setContractTypeTablePage,
                    searchedText: nameSearch,
                    width: "25%",
                    handleReset: () => setNameSearch(""),
                    render: (name, { id }) => {
                        return (
                            <PCDULink to={`/contract-types/${id}`}>{name}</PCDULink>
                        );
                    }
                }),
                searchable({
                    title: "ID",
                    dataIndex: "specifier",
                    sortDirections: ["ascend", "descend", "ascend"],
                    handleSearch: setSpecifierSearch,
                    searchedText: specifierSearch,
                    setPage: setContractTypeTablePage,
                    handleReset: () => setSpecifierSearch(""),
                    width: "25%",
                    sorter: true
                }),
                dateable({
                    title: "Start Date",
                    dataIndex: "startDate",
                    width: "25%",
                    filter: startDateFilter,
                    setFilter: setStartDateFilter,
                    setPage: setContractTypeTablePage,
                    render: (startDate) => {
                        const text = formatDate(startDate);
                        return (<span>{text}</span>);
                    },
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"]
                }),
                dateable({
                    title: "End Date",
                    dataIndex: "endDate",
                    key: "end-date",
                    width: "25%",
                    filter: endDateFilter,
                    setFilter: setEndDateFilter,
                    setPage: setContractTypeTablePage,
                    render: (endDate) => {
                        const text = formatDate(endDate);
                        return (<span>{text}</span>);
                    },
                    sorter: true,
                    sortDirections: ["ascend", "descend", "ascend"]
                })
            ])}
            rowKey="id"
            dataSource={contractTypes}
            onChange={(pagination, filters, { field, order }) => {
                const fieldToUse = Array.isArray(field) ? field[field.length - 1] : field;
                setSortOn(fieldToUse);
                setSortBy(order);
            }}
            loading={loading}
        />
    );

    const contractTypesTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setContractTypeTablePage(targetPage);
                setContractTypeTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setContractTypeTablePage(targetPage);
                setContractTypeTablePageSize(pageSize);
            }}
            current={contractTypeTablePage}
            pageSize={contractTypeTablePageSize}
            defaultCurrent={contractTypeTablePage}
            defaultPageSize={contractTypeTablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={contractTypesCount}
        />
    );


    return (
        <Main
            title="Contract Types"
            breadcrumbs={["Contract Types"]}
            details={
                <PCDULink
                    className="ant-btn ant-btn-primary"
                    to="/contract-types/create"
                    style={{
                        display: userPermCreateContractTypes ? "inline-block" : "none",
                        float: "right"
                    }}
                >
                    Create a Contract Type
                </PCDULink>
            }
        >
            <div className="main-content-margin-top">
                {contractTypesTable}
                {contractTypesTablePagination}               
            </div>
        </Main>
    );
};
