import React, { useState, useEffect, useContext } from "react";
import { Button, DatePicker, Modal, Input } from "antd";
import { isoDate, formatDate, isUndefinedOrNull } from "../../../utils/functions.js";
import { useAttemptToModifyDueDate } from "../../../utils/attemptMutationHooks.js";
import { 
    CREATE_COMMENT, 
    MODIFY_SUBMISSION, 
    CREATE_TIMELINE_COMMENT 
} from "../../../constants/mutations.js";
import moment from "moment";
import { UserContext } from "../../Application/UserContext.js";
import { useMutation } from "@apollo/client";
import { handleMutation } from "../../../utils/errorHandling.js";
import { NOTIFICATION_TYPES } from "../../../constants/index.js";

const { TextArea } = Input;

// props :: 

// submission -- if the due date extension is for a submission, this prop is necessary. 
// It should be an object with the data for the submission, and it *must* include the id. 

// timeline -- If the due date extension is for a timeline,this prop is necessary. It has to be an object with the data for the timeline that is being extended. 
// It should have all of its fields including id 

// latestSubmissionVersionId -- if the due date extension is for a submission, you need to provide the ID of the latest version of that submission. 
// This should be a string (the ID of the latest submission version)

// refetch -- pass in the refetch function of the containing component here, so that the UI updates correctly after a successful save 
// (will trigger a new render cycle of the parent component with the new data, which otherwise wouldn't happen) 

// refetch prop is technically optional, but if you don't include it you will have to handle the refetch logic in either the parent component or through 
// the afterSave prop (see below)


// completely optional props :: 

// afterSave -- an optional callback that will be invoked after the user clicks "Save" AND the mutations are successful 

// invokeAfterSaveOnFailure -- boolean, defaults to false; if set to true, this component will call the afterSave callback even if the mutation(s) are not successful 

// afterClose -- an optional callback that will be invoked whenever the due date extension modal is closed 


// little helper fn :: 
const safeCall = (cb) => typeof cb === "function" ? cb() : undefined;


export const ExtendDueDate = ({
    submission, 
    latestSubmissionVersionId, 
    timeline, 
    refetch, 
    afterSave, 
    invokeAfterSaveOnFailure = false,
    afterClose, 
    noComment = false
}) => { 

    const [modifiedDueDate, setModifiedDueDate] = useState();
    const [modalVis, setModalVis] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCommentBox, setShowCommentBox] = useState(false);
    const [comment, setComment] = useState("");

    const [attemptToModifyDueDate] = useAttemptToModifyDueDate();
    const [createComment] = useMutation(CREATE_COMMENT); 
    const [modifySubmission] = useMutation(MODIFY_SUBMISSION);
    const [createTimelineComment] = useMutation(CREATE_TIMELINE_COMMENT);

    const resetState = () => {
        setModifiedDueDate();
        setModalVis(false); 
        setLoading(false); 
        setShowCommentBox(false); 
        setComment(""); 
    };

    // cleanup when modal is closed :: 
    useEffect(() => {
        if (!modalVis) {
            resetState();
        }
    }, [modalVis]); 

    const { userPermCreateExtension } = useContext(UserContext);
    const invalidSubmissionToExtend = !isUndefinedOrNull(submission) && !submission.submitterDueDate;
    const noSubmissionOrTimeline = isUndefinedOrNull(submission) && isUndefinedOrNull(timeline);

    const parentId = submission?.id ? submission.id : timeline?.id; 
    const currentDueDate = timeline ? formatDate(timeline.currentDueDate) : formatDate(submission?.submitterDueDate);
    const currentDate = moment();
    
    const minimumDate = timeline
        ? currentDate.isBetween(moment(timeline.endDate), moment(timeline.initialDueDate))
            ? currentDate
            : (((currentDate.isSameOrAfter(moment(timeline.endDate)))
                ? moment(timeline.initialDueDate) : moment(timeline.endDate)))
        : submission?.initialSubmitterDueDate 
            ? moment(submission.initialSubmitterDueDate) 
            : moment();
    
    const defaultDate = timeline ? moment(timeline.currentDueDate) : moment(submission?.submitterDueDate); 

    return !userPermCreateExtension || invalidSubmissionToExtend || noSubmissionOrTimeline ? null : (
        <>
            <Button
                size="small"
                onClick={() => setModalVis(true)} 
                disabled={modalVis}
            >
                Change Due Date
            </Button>
            <Modal
                title="Change Due date"
                visible={modalVis}
                okText={"Save"}
                maskClosable={false}
                onCancel={() => {
                    setModalVis(false);
                }}
                okButtonProps={{
                    loading, 
                    disabled: !modifiedDueDate || (modifiedDueDate.isSame(moment(timeline?.currentDueDate)))
                }}
                afterClose={() => safeCall(afterClose)}
                destroyOnClose={true}
                onOk={async () => {
                    setLoading(true); 
                    const potentialComment = comment ? comment : " ";

                    if (comment || (currentDueDate && modifiedDueDate) ) {
                        let createCommentSuccess = false;
                        if (submission && parentId) {
                            createCommentSuccess = await handleMutation(
                                createComment({
                                    variables: {
                                        newComment: {
                                            content: comment,
                                            parentId,
                                            readyForReview: true,
                                            submissionVersionId: latestSubmissionVersionId,
                                            previousDueDate: isoDate(currentDueDate),
                                            updatedDueDate: isoDate(modifiedDueDate.clone())
                                        }
                                    }
                                })
                            ); 
                        } else if (timeline && parentId) {
                            createCommentSuccess = await handleMutation(
                                createTimelineComment({
                                    variables: {
                                        newTimelineComment: {
                                            content: potentialComment, 
                                            timelineId: parentId, 
                                            previousDueDate: isoDate(timeline.currentDueDate), 
                                            updatedDueDate: isoDate(modifiedDueDate.clone())
                                        }
                                    }
                                })
                            );
                        }
                        
                        if (!createCommentSuccess) {
                            setLoading(false);
                            if (invokeAfterSaveOnFailure === true) {
                                safeCall(afterSave);
                            } 

                            // there was an error with creating the comment; do not continue w/ attempt to modify due date 
                            // so return early :: 
                            return;
                        } 
                    }

                    let dueDateModifySuccess = false;

                    if (timeline) {
                        const { success: modifyDueDateSuccess } = await attemptToModifyDueDate({
                            variables: {
                                timelineId: timeline.id,
                                newDueDate: isoDate(modifiedDueDate.clone())
                            }
                        });

                        dueDateModifySuccess = modifyDueDateSuccess;
                    } else {
                        const modifySubmissionSuccess = await handleMutation(
                            modifySubmission({
                                variables: {
                                    updateSubmission: {
                                        id: submission.id,
                                        dueDate: modifiedDueDate,
                                        action: NOTIFICATION_TYPES.SUBMISSION_DUE_DATE_EXTENSION 
                                    }
                                }
                            })
                        );
                        dueDateModifySuccess = modifySubmissionSuccess;
                    }


                    if (dueDateModifySuccess) {
                        setModalVis(false); 
                        safeCall(refetch); 
                        safeCall(afterSave); 
                    } else {
                        setLoading(false);
                        if (invokeAfterSaveOnFailure === true) {
                            safeCall(afterSave);
                        }
                    }
                }}
            >
                <p>The due date is currently: <strong>{currentDueDate}</strong></p>
                <p>Select a new due date:</p>
                <DatePicker
                    className={"alignCenter"}
                    value={modifiedDueDate}
                    onChange={setModifiedDueDate}
                    format={"MM/DD/YYYY"}
                    disabledDate={(d) => d < minimumDate}
                    showTime={{
                        hideDisabledOptions: true
                    }}
                    defaultPickerValue={defaultDate}
                    showNow={false}

                /> 
                { noComment ? null : <p style={{ marginTop: "20px" }}> You may leave a comment explaining the due date extension. </p> } 
                { noComment ? null : showCommentBox 
                    ? <>
                        <Button 
                            size="small"
                            onClick={() => {
                                setComment("");
                                setShowCommentBox(false);
                            }} 
                        >
                            Cancel Comment     
                        </Button>
                        <TextArea 
                            style={{
                                marginTop: "15px"
                            }}
                            rows={4}
                            value={comment}
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                        />
                    </> 
                    : <>
                        <Button
                            size="small"
                            onClick={() => setShowCommentBox(true)} 
                        >
                            Enter Comment 
                        </Button>
                    </> 
                }
            </Modal>
        </>
    );
};
