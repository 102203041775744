import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { TextAreaDetail } from "../TextAreaDetail/index.js";
import { useMutation } from "@apollo/client";
import { CREATE_REACTIONS, MODIFY_ISSUES } from "../../../constants/mutations.js";
import { handleMutation } from "../../../utils/errorHandling.js";
import { ISSUE_REACTION, ISSUE_STATUS_LIST } from "../../../constants/index.js";

const isValidIssue = (issue) => {
    return issue && typeof issue === "object" && issue.id && issue.reason;
};

const issueContent = (issue, commentData, setCommentData, rejecting) => {
    const key = issue.id;
    return (
        <div key={key} style={{marginTop: "10px"}}>
            <p style={{fontSize: "16px"}}> Issue: {issue.reason} </p>
            <TextAreaDetail 
                value={commentData[key]}
                strict={true}
                onValueUpdated={(e) => {
                    const newCommentData = {
                        ...commentData,
                        [key]: e.target.value
                    };
                    setCommentData(newCommentData);
                }}
                placeholder={`Reason for ${rejecting ? "rejection" : "disagreement"}...`}
            />
        </div>
    );
};

export const DisagreeModal = ({ issues = [], onOk, onCancel, afterClose, rejecting = false, submissionId }) => {

    const [isVisible, setIsVisible] = useState(false);
    const [commentTextData, setCommentTextData] = useState({});
    const [createReactions] = useMutation(CREATE_REACTIONS);
    const [modifyIssues] = useMutation(MODIFY_ISSUES);

    useEffect(() => {
        if (
            Array.isArray(issues) && 
            issues.length > 0 && 
            issues.some(issue => isValidIssue(issue))
        ) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [issues]);


    const content = !isVisible ? <></> : (
        <>
            {
                !Array.isArray(issues) 
                    ? null 
                    : issues.filter(issue => isValidIssue(issue)).map(issue => issueContent(issue, commentTextData, setCommentTextData, rejecting))
            }
        </>
    );

    return (
        <Modal
            visible={Array.isArray(issues) && isVisible}
            title={`Please add comments explaining your ${rejecting ? "rejection" : "disagreement"}`}
            okText={"Done"}
            maskClosable={false}
            cancelText={"Cancel"}
            destroyOnClose={true}
            onCancel={() => {
                if (typeof onCancel === "function") {
                    onCancel();
                }
            }}
            onOk={ async () => {
                let reactions;
                if (rejecting) {
                    reactions = issues.map(issue => {
                        const comment = commentTextData[issue.id];
                        return {
                            issueId: issue.id,
                            specifier: issue.specifier,
                            disposition: ISSUE_REACTION.disagree,
                            comment
                        };
                    });
                } else {
                    reactions = issues.map(issue => {
                        const comment = commentTextData[issue.id];
                        return {
                            issueId: issue.id,
                            specifier: issue.specifier,
                            disposition: ISSUE_REACTION.disagree,
                            comment
                        };
                    });
                }
                await handleMutation(
                    createReactions({
                        variables: {
                            newReactions: reactions
                        }
                    })
                );


                if (rejecting) {

                    const newIssues = issues.map(issue => {
                        return {
                            id: issue.id,
                            submissionId: submissionId ? submissionId : issue?.submission?.id,
                            status: ISSUE_STATUS_LIST.reject 
                        };
                    });

                    await handleMutation(
                        modifyIssues({
                            variables: {
                                newIssues,
                                notify: true
                            }
                        })
                    );
                }

                if (typeof onOk === "function") {
                    onOk();
                }
            }}
            afterClose={() => {
                setCommentTextData({});
                if (typeof afterClose === "function") {
                    afterClose();
                }
            }}
        > 
            <>
                { content }
                { rejecting 
                    ? null 
                    : <p> Note: Any comments you add to an issue here are considered disagreement notes that will be deleted if you change your stance on that issue from disagreement to agreement </p> } 
            </>
        </Modal>
    );
};
