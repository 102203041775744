import React from "react"; 
import { Badge } from "antd";
import { useQuery } from "@apollo/client";
import { formatDate, isoDateRange as castToIsoDateRange } from "../../utils/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { createDueRange, restructureToDosData, showClickForMore } from "./helpers.js";
import { dateDetailQuery } from "./queries.js";
import { CloseCircleOutlined } from "@ant-design/icons";


export const MonthCell = ({ 
    date, 
    onSelect,
    getListData,
    queryVariables
}) => {
    const dateRange = createDueRange(date, "month");
    const isoDateRange = castToIsoDateRange(dateRange);

    const { data, loading, error } = useQuery(dateDetailQuery, {
        variables: {
            ...queryVariables, 
            submissionDueRange: isoDateRange,
            toDoDateRangeFilter: isoDateRange,
            limitSubmissions: 5, 
            offsetSubmissions: 0, 
            limitToDos: 5, 
            offsetToDos: 0
        },
        fetchPolicy: "no-cache"
    });

    if (error) {
        return <p><CloseCircleOutlined style={{color: "red"}} /> Error loading data for this month </p>;
    }

    if (loading) {
        return <LoadingContent />;
    }

    const listData = getListData(date, "month", {
        submissions: data?.submissionsList?.submissions ?? [],
        toDos: data?.toDos ? restructureToDosData(data?.toDos) : []
    });

    return (
        <div onClick={() => onSelect(date, "month")}>
            <ul className="events noPadding">
                {listData.map((item, i) => {
                    return (
                        <li key={`item-content-event-month-${isoDateRange[0]}-${i}`}>
                            <Badge color={item.color} text={`${item.text} (${formatDate(item.dueDate)})`} />
                        </li>
                    );
                })}
            </ul>
            { showClickForMore(listData) }
        </div>
    );
};
