import { useQuery } from "@apollo/client";
import { Button, Modal } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";

import {
    SUBMISSION_STATUS_PENDING_FAILED,
    S3_UPLOAD_STATUS,
    FREQUENCY_OPTIONS
} from "../../constants/index.js";

import {
    checkLocationSearch,
    inclusiveDateTimeRange,
    currentYear
} from "../../utils/functions.js";

import {
    isUndefinedOrNull,
    dateable,
    formatTimeValue,
    formatDate,
    filterable, 
    searchable,
    displayResubmissionDueDate
} from "../../utils/index.js";
import { UserContext } from "../Application/UserContext.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { TablePagination } from "../common/TablePagination/index.js";
import { SubmissionsCsvExport } from "./export.js";
import { submissionsQuery } from "./query.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { DocumentsTable } from "../common/DocumentsTable/index.js";
import { SubmissionYearDropDown } from "../common/SubmissionYearSelection/index.js";
import { ScrollableTable } from "../common/ScrollableTable/index.js";
import { getDefaultPageLink, getSubmissionLink } from "../NavigationSidebar/submission-options-sorted-byrole.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { useAutoFlag } from "../../utils/useAutoFlag.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const { usePersistentState } = PersistentState();

const getTitleAndBreadcrumbs = (params) => {
    const defaultSubmissionsPage = getDefaultPageLink();
    const defaultSubmissionLink = <PCDULink 
        to={defaultSubmissionsPage.props.to} 
        text={"Submissions"} 
        tooltip={defaultSubmissionsPage.props.text}
    > 
        Submissions 
    </PCDULink>;
    const options = { resetBreadcrumbs: false, resetPersistentState: false };
    switch (params["*"]) {
        case "submissions/archived":
            return { 
                title: "Archived Submissions",
                breadcrumbs: [defaultSubmissionLink, getSubmissionLink("archived", options)]
            };
        case "submissions/finalized":
            return { 
                title: "Finalized Submissions",
                breadcrumbs: [defaultSubmissionLink, getSubmissionLink("finalized", options)]
            };
        case "submissions/current":
            return { 
                title: "Current Submissions",
                breadcrumbs: [defaultSubmissionLink, getSubmissionLink("current", options)]
            };
        case "submissions/fix":
            return { 
                title: "Submissions to Re-Upload",
                breadcrumbs: [defaultSubmissionLink, getSubmissionLink("fix", options)]
            };
        case "submissions/review":
            return { 
                title: "Submissions to Review",
                breadcrumbs: [defaultSubmissionLink, getSubmissionLink("review", options)]
            };
        case "submissions/pending-failed":
            return { 
                title: "Pending and Failed Submissions",
                breadcrumbs: [defaultSubmissionLink, getSubmissionLink("pending-failed", options)]
            };
        default:
            return { 
                title: "All Submissions",
                breadcrumbs: [defaultSubmissionLink, getSubmissionLink("submissions", options)]
            };
    }
};

export const Submissions = () => {
    const params = useParams();
    const location = useLocation();
    const {
        userPermCreateSubmission,
        reviewerFilter,
        userPermViewContractTypes,
        userIsSubmitter
    } = useContext(UserContext);
    const { title, breadcrumbs } = getTitleAndBreadcrumbs(params);
    const newBreadcrumb = {
        label: breadcrumbs[1].props.text,
        path: breadcrumbs[1].props.to 
    };

    const tabParam = location.pathname ? location.pathname.split("/").pop() : "";
    const tabSelected = tabParam ? tabParam : "submissions";
    const pendingSubmissionsFilter = (tabSelected === "pending-failed");
    const reviewerCurrentOnly = tabSelected === "review";
    const displayInTabs = ["submissions", "finalized", "archived"];

    const { preloadedData } = usePreloadedData();

    const [dataToUse, setDataToUse] = useState(null);
    const [reuploadSubmission, setReuploadSubmission] = useState(null);
    const [resetFilterSearch, setResetFilterSearch] = useAutoFlag(false);
    const [tab, setTab] = useState(tabSelected);

    // Persistant state
    const defaultSortOn = "latestVersionCreatedAt";
    const defaultSortBy = "descend";
    const [sortOn, setSortOn, resetSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy, resetSortBy] = usePersistentState(defaultSortBy);
    const [submissionsTablePage, setSubmissionsTablePage, resetSubmissionsTablePage] = usePersistentState(1);
    const [submissionsTablePageSize, setSubmissionsTablePageSize, resetSubmissionsTablePageSize] = usePersistentState(10);
    const [categoryFilter, setCategoryFilter, resetCategoryFilter] = usePersistentState([]);
    const [organizationsFilter, setOrganizationsFilter, resetOrganizationsFilter] = usePersistentState([]);
    const [createdFilter, setCreatedFilter, resetCreatedFilter] = usePersistentState([]);
    const [dueDateFilter, setDueDateFilter, resetDueDateFilter] = usePersistentState([]);
    const [statusFilter, setStatusFilter, resetStatusFilter] = usePersistentState([]);
    const [businessUnitsFilter, setBusinessUnitsFilter, resetBusinessUnitsFilter] = usePersistentState([]);
    const [contractTypesFilter, setContractTypesFilter, resetContractTypesFilter] = usePersistentState([]);
    const [uploadStatusFilter, setUploadStatusFilter, resetUploadStatusFilter] = usePersistentState([]);
    const [specifierSearch, setSpecifierSearch, resetSpecifierSearch] = usePersistentState("");
    const [primaryReviewerSearch, setPrimaryReviewerSearch, resetPrimaryReviewerSearch] = usePersistentState("");
    const [yearFilter, setYearFilter, resetYearFilter] = usePersistentState(currentYear);
    const [submissionTypesFilter, setSubmissionTypesFilter, resetSubmissionTypesFilter] = usePersistentState([]);


    const clearSearch = () => {
        setSubmissionsTablePage(1);
        setSpecifierSearch("");
        setCreatedFilter([]);
        setOrganizationsFilter([]);
        setSubmissionTypesFilter([]);
        setCategoryFilter([]);
        setStatusFilter([]);
        setPrimaryReviewerSearch("");
        setBusinessUnitsFilter([]);
        setContractTypesFilter([]);
        setDueDateFilter([]);
        setUploadStatusFilter([]);
        setYearFilter(currentYear);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setResetFilterSearch(true);
    };

    const resetToPersistentStateValues = () => {
        resetSortOn();
        resetSortBy();
        resetCategoryFilter();
        resetSubmissionsTablePage();
        resetSubmissionsTablePageSize();
        resetOrganizationsFilter();
        resetCreatedFilter();
        resetDueDateFilter();
        resetStatusFilter();
        resetBusinessUnitsFilter();
        resetContractTypesFilter();
        resetUploadStatusFilter();
        resetSpecifierSearch();
        resetPrimaryReviewerSearch();
        resetYearFilter();
        resetSubmissionTypesFilter();
        setTab(tabSelected);
    };

    const queryVariables = {
        tab,
        offset: (submissionsTablePage - 1) * submissionsTablePageSize,
        limit: submissionsTablePageSize,
        submissionTypesFilter,
        reviewerCurrentOnly,
        organizationsFilter,
        specifierSearch,
        latestVersionCreatedFilter: inclusiveDateTimeRange(createdFilter),
        statusFilter,
        uploadStatusFilter,
        sortOn,
        sortBy,
        dueDateFilter,
        categoryFilter,
        reviewerFilter : reviewerFilter,
        primaryReviewerSearch,
        businessUnitsFilter,
        contractTypesFilter, 
        libraryAssociated: userPermCreateSubmission,
        yearFilter: displayInTabs.includes(tab) ? yearFilter : null
    };

    const exportButton = <SubmissionsCsvExport
        variables={queryVariables}
        userPermCreateSubmission={userPermCreateSubmission}
    />;

    const { loading, error, data, refetch } = useQuery(submissionsQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    useEffect(() => {
        resetToPersistentStateValues();
        if (location.search) {
            setSubmissionTypesFilter(checkLocationSearch(location.search, "submission-type="));
        } 
    }, [location]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse || !preloadedData) {
        return <LoadingContent />;
    }

    const { submissionsList } = dataToUse;
    const { submissions, count: submissionsCount } = submissionsList;
    const { 
        categories, 
        submitterOrganizations, 
        submissionTypes, 
        constants, 
        businessUnits,
        primaryReviewers
    } = preloadedData;
    const contractTypes = userPermCreateSubmission ? preloadedData.libraryAssociatedContractTypes : preloadedData.contractTypes;
    const STATUS_FILTERS_BY_SUBMISSION_TAB = constants?.STATUS_FILTERS_BY_SUBMISSION_TAB;
    const SUBMISSION_STATUS = constants?.SUBMISSION_STATUS_LIST;
    const dbFrequencyOptions = constants?.FREQUENCY_OPTIONS;

    const submissionStatusOptions = STATUS_FILTERS_BY_SUBMISSION_TAB?.[tab] ?? [];

    const submissionDateColumn = () => {
        return (tab === "fix") ?
            dateable({
                title: "Resubmission Due",
                key: "submitterDueDate",
                dataIndex: "submitterDueDate",
                filter: createdFilter,
                setFilter: setCreatedFilter,
                setPage: setSubmissionsTablePage,
                render: (submitterDueDate, { initialSubmitterDueDate }) => {
                    
                    return displayResubmissionDueDate(submitterDueDate, initialSubmitterDueDate);
                },
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"]
            }) :
            dateable({
                title: "Submission Date",
                key: "latestVersionCreatedAt",
                dataIndex: "latestVersionCreatedAt",
                filter: createdFilter,
                setFilter: setCreatedFilter,
                setPage: setSubmissionsTablePage,
                render: (latestVersionCreatedAt) => formatTimeValue(latestVersionCreatedAt),
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"]
            });
    };
    
    let submissionTableColumn = [
        searchable({
            title: "ID",
            searchedText: specifierSearch,
            handleSearch: (specifierSearch) => {
                setSpecifierSearch(specifierSearch);
                setSubmissionsTablePage(1);
            },
            handleReset: () => setSpecifierSearch(""),
            sortDirections: ["ascend", "descend", "ascend"],
            dataIndex: "specifier",
            sorter: true,
            render: (text, { id }) => {
                return (
                    <PCDULink to={`/submissions/${id}`} newBreadcrumbs={newBreadcrumb}>{text}</PCDULink>
                );
            }
        }),
        submissionDateColumn(),
        filterable({
            title: "Submission Type",
            dataIndex: "submissionTypeId",
            key: "submission-type",
            domain: submissionTypes.map(({ id, specifier }) => {
                return {
                    label: specifier,
                    value: id
                };
            }),
            resetFilterSearch,
            searchFilters: true,
            filter: submissionTypesFilter,
            setFilter: (submissionType) => {
                setSubmissionTypesFilter(submissionType);
                setSubmissionsTablePage(1);
            },
            render: (submissionTypeId) => {
                const submissionType = submissionTypes?.find?.(({ id }) => id === submissionTypeId);
                if (submissionType) {
                    const { id, name, specifier } = submissionType;
                    return (
                        <PCDULink 
                            to={`/submission-types/${id}`}
                            newBreadcrumbs={newBreadcrumb}
                        >
                            {`${specifier} - ${name}`}
                        </PCDULink>
                    );
                } else {
                    return null;
                }
            },
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        }),
        searchable({
            title: "Primary Reviewer",
            searchedText: primaryReviewerSearch,
            handleSearch: (primaryReviewerSearch) => {
                setPrimaryReviewerSearch(primaryReviewerSearch);
                setSubmissionsTablePage(1);
            },
            handleReset: () => setPrimaryReviewerSearch(""),
            sortDirections: ["ascend", "descend", "ascend"],
            dataIndex: "primaryReviewerId",
            sorter: true,
            transform: (primaryReviewerId) => {
                const primaryReviewer = primaryReviewers?.find?.(({ id }) => id === primaryReviewerId);
                return primaryReviewer?.name;
            }
        })
    ];

    const businessUnitCol = [
        filterable({
            title: "Business Unit",
            dataIndex: "businessUnitId",
            key: "businessUnit",
            domain: businessUnits.map(({ id, name }) => {
                return {
                    label: name,
                    value: id
                };
            }),
            resetFilterSearch, 
            searchFilters: true,
            filter: businessUnitsFilter,
            setFilter: (businessUnit) => {
                setBusinessUnitsFilter(businessUnit);
                setSubmissionsTablePage(1);
            },
            render: (businessUnitId) => {
                const businessUnit = businessUnits?.find?.(({ id }) => id === businessUnitId ?? "");
                return businessUnit?.name;
            },
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        })
    ];

    const currentDueDateCol = pendingSubmissionsFilter ? [
        dateable({
            title: "Current Due Date",
            key: "currentOrAdhocDueDate",
            filter: dueDateFilter,
            setFilter: setDueDateFilter,
            setPage: setSubmissionsTablePage,
            dataIndex: "frequencyId",
            sorter: true,
            render: (frequencyId, submission ) => {
                const isAdhoc = dbFrequencyOptions?.find?.(({ id, name }) => (id === frequencyId) && name === FREQUENCY_OPTIONS.adhoc);
                if (isAdhoc) {
                    return isUndefinedOrNull(submission?.adhocDueDate) ? "Ad Hoc" : formatDate(submission.adhocDueDate);
                } else if (submission?.timeline?.currentDueDate) {
                    return formatDate(submission.timeline.currentDueDate);
                } else {
                    return "N/A";
                }
            },
            sortDirections: ["ascend", "descend", "ascend"]
        })
    ] : [];

    const contractTypeCol = [
        filterable({
            title: "Contract Type",
            dataIndex: "contractTypeId",
            key: "contractType",
            domain: contractTypes.map(({ id, name }) => {
                return {
                    label: name,
                    value: id
                };
            }),
            resetFilterSearch,
            searchFilters: true,
            filter: contractTypesFilter,
            setFilter: (contractType) => {
                setContractTypesFilter(contractType);
                setSubmissionsTablePage(1);
            },
            render: (contractTypeId) => {
                const contractType = contractTypes?.find?.(({ id }) => id === contractTypeId);
                if (contractType) {
                    const { id, name } = contractType;
                    return userPermViewContractTypes
                        ? <PCDULink to={`/contract-types/${id}`} newBreadcrumbs={newBreadcrumb}>{name}</PCDULink>
                        : name;
                } else {
                    return null;
                }
            },
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        })
    ];

    const dhhsUserAdditionalCol = !userIsSubmitter ? [
        filterable({
            title: "Organization",
            dataIndex: "organizationId",
            key: "Organization",
            domain: submitterOrganizations.map(({ id, name }) => {
                return {
                    label: name,
                    value: id
                };
            }),
            resetFilterSearch,
            searchFilters: true,
            filter: organizationsFilter,
            setFilter: (organization) => {
                setOrganizationsFilter(organization);
                setSubmissionsTablePage(1);
            },
            render: (organizationId) => {
                const org = submitterOrganizations?.find?.(({ id }) => id === organizationId);
                if (org) {
                    const { id, name } = org;
                    return (<PCDULink to={`/organizations/${id}`} newBreadcrumbs={newBreadcrumb}>{name}</PCDULink>);
                } else {
                    return null;
                }
            },
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        })
    ] : [];

    const allSubTabStatusCol = (tab === "finalized" || tab === "archived") ?
        [
            {
                title: "Submission Status",
                key: "status",
                dataIndex: "statusId",
                render: (submissionStatus) => {
                    return SUBMISSION_STATUS?.find?.(({ id }) => id === submissionStatus)?.displayValue;
                }
            }
        ]
        : [
            filterable({
                title: "Submission Status",
                key: "status",
                dataIndex: "statusId",
                render: (submissionStatus) => {
                    return SUBMISSION_STATUS?.find?.(({ id }) => id === submissionStatus)?.displayValue;
                },
                domain: submissionStatusOptions.map(value => ({
                    label: value,
                    value
                })),
                filter: statusFilter,
                setFilter: setStatusFilter,
                setPage: setSubmissionsTablePage,
                sorter: true,
                sortDirections: ["ascend", "descend", "ascend"]
            })
        ];
    
    const submissionStatusCol = pendingSubmissionsFilter ? [
        filterable({
            title: "Submission Status",
            key: "uploadStatus",
            dataIndex: "uploadStatus",
            render: (uploadStatus) => {
                if (uploadStatus === SUBMISSION_STATUS_PENDING_FAILED.failed) {
                    return (<div style={{ color: "red", fontWeight: "bold" }}>
                        {uploadStatus}
                    </div>);
                }
                return uploadStatus;
            },
            domain: submissionStatusOptions.map(value => ({
                label: value,
                value
            })),
            filter: uploadStatusFilter,
            setFilter: setUploadStatusFilter,
            setPage: setSubmissionsTablePage,
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"]
        })
    ] : allSubTabStatusCol;
    
    const reUploadActionCol = (pendingSubmissionsFilter && userPermCreateSubmission) ?
        [
            {
                title: "Action",
                key: "reUpload",
                width: "130px",
                render: (submission) => {
                    const { uploadStatus } = submission;
                    let reupload = false;
                    if (uploadStatus === "Failed") {
                        reupload = true;
                    }
                    return (
                        <Button
                            className="downloadLink spaceBetween-md"
                            type="primary"
                            size="small"
                            style={{
                                display: reupload ? "inherit" : "none",
                                marginLeft: "6px"
                            }}
                            onClick={() => setReuploadSubmission(submission)}
                        >
                            Re-Upload
                        </Button>
                    );
                }
            }
        ] : [];

    if (currentDueDateCol.length > 0) {
        submissionTableColumn = submissionTableColumn.concat(currentDueDateCol);
    }

    submissionTableColumn = submissionTableColumn.concat(businessUnitCol).concat(contractTypeCol);

    if (dhhsUserAdditionalCol.length > 0) {
        submissionTableColumn = submissionTableColumn.concat(dhhsUserAdditionalCol);
    }

    submissionTableColumn = submissionTableColumn.concat(submissionStatusCol);

    if (reUploadActionCol.length > 0) {
        submissionTableColumn = submissionTableColumn.concat(reUploadActionCol);
    }

    const submissionsTable = (
        <ScrollableTable
            size="middle"
            pagination={false}
            columns={handleControlledDefaultSortOrder({ 
                sortBy, 
                sortOn, 
                customHandler: ({ dataIndex, key }) => dataIndex ? dataIndex  === sortOn : key  === sortOn 
            }, submissionTableColumn)}
            dataSource={submissions}
            loading={loading}
            rowKey="id"
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={clearSearch} 
                filtersData={[
                    { currentValue: categoryFilter, defaultValue: [] },
                    { currentValue: organizationsFilter, defaultValue: [] },
                    { currentValue: createdFilter, defaultValue: [] },
                    { currentValue: dueDateFilter, defaultValue: [] },
                    { currentValue: statusFilter, defaultValue: [] },
                    { currentValue: businessUnitsFilter, defaultValue: [] },
                    { currentValue: contractTypesFilter, defaultValue: [] },
                    { currentValue: uploadStatusFilter, defaultValue: [] },
                    { currentValue: specifierSearch, defaultValue: "" },
                    { currentValue: primaryReviewerSearch, defaultValue: "" },
                    { currentValue: yearFilter, defaultValue: currentYear },
                    { currentValue: submissionTypesFilter, defaultValue: [] } 
                ]}
            />}
            exportButton={exportButton}
            onChange={(_, __, { field, order, columnKey }) => {
                const fieldToUse = field ? field : columnKey;
                setSortOn(fieldToUse);
                setSortBy(order);
            }}
        />
    );

    const submissionsTablePagination = (
        <TablePagination
            setPage={setSubmissionsTablePage}
            setPageSize={setSubmissionsTablePageSize}
            total={submissionsCount}
            defaultPage={submissionsTablePage}
            defaultPageSize={submissionsTablePageSize}
            currentPageNumber={submissionsTablePage}
        />
    );

    const categoryTypeDropdown = <SelectableDetail
        title={"Category Type"}
        blurOnSelectionId={"library-search-ct-dropdown"}
        value={categoryFilter}
        strict={true}
        onValueUpdated={(value) => {
            setCategoryFilter(value);
            setSubmissionsTablePage(1);
        }}
        multiple={true}
        showArrow={true}
        options={categories.map(({ name, id }) => {
            return {
                key: id,
                text: `${name}`,
                value: id
            };
        })}
    />;

    return (
        <Main
            title={title}
            breadcrumbs={[ breadcrumbs[0], breadcrumbs[1].props.text ]}
            details={
                <PCDULink
                    className="ant-btn ant-btn-primary"
                    to="/submissions/create"
                    style={{
                        float: "right",
                        display: userPermCreateSubmission ? ((tab === "finalized" || tab === "archived" || tab === "pending-failed") ? "none" : "inline-block") : "none"
                    }}
                    newBreadcrumbs={newBreadcrumb}
                >
                    Create Submission
                </PCDULink>
            }
        >
            <div className="year-category-dropdown-container">
                { displayInTabs.includes(tab) 
                    ? <div className="year-dropdown">
                        <SubmissionYearDropDown
                            yearFilter = {yearFilter}
                            setYearFilter={setYearFilter}
                            title="Submission Year"
                            onChange={() => {
                                setSubmissionsTablePage(1);
                            }}
                        ></SubmissionYearDropDown>
                    </div>
                    : null
                }
                <div className="category-dropdown">
                    { categoryTypeDropdown }
                </div>
            </div>

            {submissionsTable}
            {submissionsTablePagination}
            {pendingSubmissionsFilter && userPermCreateSubmission && 
                <Modal
                    style={{ minWidth: "900px" }}
                    visible={Boolean(reuploadSubmission && reuploadSubmission?.id && typeof reuploadSubmission?.id === "string")}
                    onCancel={() => setReuploadSubmission(null)}
                    destroyOnClose={true}
                    okButtonProps={{ style: { display: "none" } }}
                    maskClosable={false}
                >
                    <DocumentsTable 
                        subtitle={reuploadSubmission?.specifier ? `For Submission ${ reuploadSubmission.specifier }` : ""}
                        submissionId={reuploadSubmission?.id}
                        shortened={true}
                        newBreadcrumbs={newBreadcrumb}
                        afterSuccessfulUpload={refetch}
                        onStatusChangeOfDocuments={(_, currentDocuments) => {
                            // the "after upload" callback will handle switching it to Pending; this is just for when it has resolved -- 
                            // i.e., all documents are not uploading :: 
                            if (currentDocuments.every(doc => doc.S3UploadStatus !== S3_UPLOAD_STATUS.uploading)) {
                                refetch();
                            }
                        }}
                    />
                </Modal> 
            }
        </Main>
    );
};


