import React, { useEffect, useState, useContext } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Button, Input, Divider, message } from "antd";
import { UserContext } from "../Application/UserContext.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { Main } from "../common/Main/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { isUndefinedOrNull } from "../../utils/functions.js";
import { LibraryDocumentsTable } from "./library-documents-table.js";
import { useNavigate } from "react-router-dom";
import { getLibraryLinkData } from "../../utils/getLibraryLinkData.js";
import { getContractTypesQuery, getFolderPathwayQuery } from "./queries.js";

const SearchBar = Input.Search;

const generateSearchMessage = (search, contractTypes) => {
    const selected = Array.isArray(search?.contractTypes) && search.contractTypes.length > 0 ? search.contractTypes : null;
    const line1 = search?.name ? `Searching for "${search.name}"` : "";
    let line2 = "";
    if (selected) {
        const names = selected.map(id => contractTypes?.find?.(ct => ct.id === id)?.name).filter(item => !!item);
        const nameString = names.join(", ");
        line2 = `In ${nameString}`;
    }
    return (
        <div>
            <h4>{line1}</h4>
            <h4>{line2}</h4>
            <Divider />
        </div>
    );
};


export const LibrarySearch = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [folders, setFolders] = useState([]); 

    const {
        userPermViewLibDoc,
        userPermViewAssociatedLibDoc
    } = useContext(UserContext);

    const userHasPermission = userPermViewLibDoc || userPermViewAssociatedLibDoc;

    const { loading, error, data } = useQuery(
        getContractTypesQuery,
        {
            fetchPolicy: "no-cache"
        }
    ); 

    const [getFolderPathway, { loading: folderPathwayLoading, error: folderPathwayError, data: folderPathwayData }] = useLazyQuery(getFolderPathwayQuery);

    useEffect(() => { 
        if (folderPathwayData) {
            const { libraryFolderPathway: pathway } = folderPathwayData;
            const redirectObject = getLibraryLinkData(pathway); 
            if (!redirectObject) {
                message.error("There was an error redirecting to the selected folder."); 
            } else {
                navigate(redirectObject.pathname, redirectObject); 
            }
        }
    }, [folderPathwayData]); 

    if (error) {
        return handleGraphQLError(error);
    }

    if (folderPathwayError) {
        return handleGraphQLError(folderPathwayError);
    }

    if (folderPathwayLoading) {
        return <LoadingContent delay={600} />;
    }

    if (!userHasPermission) {
        return <NoPermission />;
    }    

    const clearOut = () => {
        setFolders([]);
        setSearchInput("");
        setSearch(null);
    };

    const contractTypeOptions = data?.contractTypes ?? [];
    const displayContractTypeDropdown = contractTypeOptions.length > 0;

    const contractTypeDropdown = <div style={{ maxWidth: "500px" }}>
        <SelectableDetail
            title={"Contract Types"}
            value={folders}
            strict={true}
            onValueUpdated={setFolders}
            multiple={true}
            blurOnSelectionId={"library-search-ct-dropdown"}
            options={contractTypeOptions.map(({ id, name }) => {
                return {
                    key: id,
                    text: `${name}`,
                    value: id
                };
            })}
        />
    </div>;    

    return (
        <Main
            title="Search Library Documents"
            breadcrumbs={
                ["Library Documents", "Search"]
            }
        >
            <div>
                <Divider />
                <div>
                    { displayContractTypeDropdown && contractTypeDropdown }
                    <div style={{ maxWidth: "650px" }}>
                        <SearchBar 
                            loading={!!search && loading}
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.currentTarget.value)}
                            onSearch={() => { 
                                const searchInputToUse = searchInput.trim();

                                if (!searchInputToUse) {
                                    message.error({
                                        content: "Invalid search input"
                                    });
                                } else {
                                    const contractTypes = [...folders];

                                    setSearch({
                                        contractTypes,
                                        name: searchInputToUse
                                    });
                                }
                            }}
                        />
                    </div>
                    <Button 
                        style={{ marginTop: "10px" }}
                        onClick={() => clearOut()}
                    >
                        Clear
                    </Button>
                </div>
                <Divider />
                { !isUndefinedOrNull(search) && 
                    <div>
                        <h2> Search Results </h2>
                        {generateSearchMessage(search, contractTypeOptions)}
                        <LibraryDocumentsTable 
                            search={true}
                            searchData={search}
                            getFolderPathway={getFolderPathway}
                            contractTypeId={"null"}
                        />
                    </div>
                }
            </div>
        </Main>
    );
};
