import React, { useEffect, useState, useContext} from "react";
import { Button, Modal, Spin } from "antd";
import { useMutation } from "@apollo/client";
import { TextAreaDetail } from "../common/TextAreaDetail/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { UserContext } from "../Application/UserContext.js";
import {
    CREATE_ISSUE,
    MODIFY_ISSUES,
    MODIFY_REVIEW,
    CREATE_REACTIONS,
    CREATE_REVIEWER_ASSIGNMENT,
    MODIFY_SUBMISSION
} from "../../constants/mutations.js";
import {
    ISSUE_STATUS_LIST,
    ISSUE_REACTION,
    SUBMISSION_STATUS_LIST,
    SUBMISSION_STATUS_SUCCESS,
    S3_UPLOAD_STATUS,
    FEEDBACK_DOCUMENTS_BLOCKING_SEND_FEEDBACK_MESSAGE,
    NOTIFICATION_TYPES
} from "../../constants/index.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { DisagreeModal } from "../common/DisagreeModal/index.js";
import { filterCriteriaVersionsByTimestamp } from "../../utils/filterCriteriaVersions.js";
import { UploadFeedbackDocument } from "../common/UploadFeedbackDocument/index.js";
import { RelatedDocumentsSelection, RELATED_DOCUMENTS_NULL_OPTION } from "../common/RelatedDocumentsSelection/index.js";
import { IssueTable } from "../common/IssueTable/index.js";


const { confirm, warning } = Modal;

const issueMayNotBeSaved = (modified) => !modified.reason;

const getActiveIssues = (issues, submission, currentVersionOnly = false) => {
    return !Array.isArray(issues) ? [] : issues.filter(id => {
        return submission.issues.some(({ id: issueId, status, raisedInReview }) => {
            const latestVersion = submission?.submissionNewestVersion?.specifier ?? "";
            const isMatch = id === issueId;
            const isCurrentVersion = raisedInReview?.submissionVersion?.specifier === latestVersion;
            const isActive = status !== ISSUE_STATUS_LIST.reject && status !== ISSUE_STATUS_LIST.close;
            return currentVersionOnly ? isMatch && isActive && isCurrentVersion : isMatch && isActive;
        });
    }).map(id => {
        return submission.issues.find(({ id: issueId }) => id === issueId);
    });
};

const getValidIssuesToDisagreeWith = (issues, userId) => {
    return issues.filter(issue => {
        const userAlreadyDisagrees = issue?.reactions?.some?.(reaction => {
            return reaction?.disposition === ISSUE_REACTION.disagree && reaction?.reviewerId === userId;
        }); 
        return !userAlreadyDisagrees;
    });
};

const disableDisagreeButton = (issues, userId) => {
    const validIssues = getValidIssuesToDisagreeWith(issues, userId);
    return !(Array.isArray(validIssues) && validIssues.length > 0);
};

export const SubmissionIssues = ({ 
    setRefetchData,
    submission,
    submitterIssues,
    isPackagedSubmission,
    showRescindAndContinue,
    newBreadcrumbs,
    setShowRescindAndContinue
}) => {
    const [issueSelection, setIssueSelection] = useState([]);
    const [modified, setModified] = useState({});
    const [issueVis, setIssueVis] = useState(false);
    const [createIssue, { loading: createIssueLoading }] = useMutation(CREATE_ISSUE);
    const [modifyIssues] = useMutation(MODIFY_ISSUES);
    const [createReactions] = useMutation(CREATE_REACTIONS);
    const [modifyReview] = useMutation(MODIFY_REVIEW);
    const [createReviewerAssignment] = useMutation(CREATE_REVIEWER_ASSIGNMENT);
    const [modifySubmission] = useMutation(MODIFY_SUBMISSION);

    const [disagreedIssues, disagreeWithIssues] = useState([]);
    const [rejecting, setRejecting] = useState(false);
    const [issueModalLoading, setIssueModalLoading] = useState(false);
    const [createFeedbackDocumentFiles, setCreateFeedbackDocumentFiles] = useState({});

    const { 
        userIsSubmitter, 
        userId, 
        userIsAdmin,
        userIsDataQualityAdmin, 
        userAdminAssignment,
        userDataQualityAdminAssignment,
        userPermCreateIssue, 
        userPermRejectnCloseIssue
    } = useContext(UserContext);

    useEffect(() => {
        if (issueVis === false) {
            setIssueModalLoading(false);
        }
    }, [issueVis]);

    const currentUserId = userId;

    const userCreateIssue = userPermCreateIssue(submission.obligation.submissionType.id); 
    const userCanRejectnCloseIssue = userPermRejectnCloseIssue(submission.obligation.submissionType.id); 
    const documentStatusReviewed = (submission.status === SUBMISSION_STATUS_LIST.final || submission.status === SUBMISSION_STATUS_LIST.version || submission.status === SUBMISSION_STATUS_LIST.final_requested || submission.archived);
    const isDQSubmission = submission.obligation.submissionType.dqFlag === true;
    const getCurrentReview = () => {
        return currentUserId === null ? submission.submissionNewestVersion.reviews[0] :
            submission.submissionNewestVersion.reviews.find(({ reviewer: { id } }) => id === currentUserId);
    };

    const currentReview = getCurrentReview(); 

    const latestVersionDate = submission.submissionNewestVersion.createdAt; 
    const allCriteria = submission.obligation.submissionType.allCriteria;
    const applicableCriteria = filterCriteriaVersionsByTimestamp(allCriteria, latestVersionDate);
    
    /**
     * Add Reaction for Active Issues in current version
     */
    const triggerCreateReactions = async (value) => {
        // Filter Issues which are not Rejected / Resolved 
        const activeIssues = getActiveIssues(issueSelection, submission);

        if (Array.isArray(activeIssues) && activeIssues.length > 0) {
            if (value === ISSUE_REACTION.disagree){
                const toDisagreeWith = getValidIssuesToDisagreeWith(activeIssues, currentUserId);
                disagreeWithIssues(toDisagreeWith);
            } else {
                if (activeIssues && activeIssues.length > 0) {
                    const newReactions = activeIssues.map(({ id, specifier }) => {
                        return {
                            issueId: id,
                            specifier:specifier,
                            disposition: value
                        };
                    });
        
                    await handleMutation(
                        createReactions({
                            variables: {
                                newReactions
                            }
                        })
                    );
                    setIssueSelection([]);
                    setRefetchData(true);
                }
            }
        }
    };

    const resetStateForFailedMutation = () => {
        setModified({});
        setIssueVis(false);
        setRefetchData(true);
    };

    const disableBothReactionButtons = () => {
        if (!issueSelection.length > 0 || documentStatusReviewed) {
            return true;
        } else if (userIsAdmin) {
            return false;
        } else {
            return currentReview === undefined;
        }
    };

    const getFeedbackDocFailedIssue = (issueSelection) => {
        const issuesWithFeedbackDocError = submission.issues.filter(({ id, feedbackDocuments }) => {
            if (
                feedbackDocuments.length > 0 &&
                ((feedbackDocuments[0]["S3UploadStatus"] === S3_UPLOAD_STATUS.error) ||
                (feedbackDocuments[0]["S3UploadStatus"] === S3_UPLOAD_STATUS.uploading)) &&
                (issueSelection.indexOf(id) >= 0)
            ) {
                return feedbackDocuments;
            }
            return;
        });
        return issuesWithFeedbackDocError;
    };

    const warningMessage = () => {
        return (
            warning({
                title: "Warning message",
                content: FEEDBACK_DOCUMENTS_BLOCKING_SEND_FEEDBACK_MESSAGE 
            })
        );
    };

    return (
        <>
            {(!userIsSubmitter || userIsAdmin) && (
                <>
                    <div style={{ display: "flex" }}>
                        <Button
                            className="buttonLine"
                            size="small"
                            disabled={disableBothReactionButtons()}
                            onClick={() => triggerCreateReactions(ISSUE_REACTION.agree)}
                        >
                            Agree with {issueSelection.length} Issue(s)
                        </Button>
                        <Button
                            className="buttonLine"
                            size="small"
                            disabled={
                                disableBothReactionButtons() || 
                                disableDisagreeButton(
                                    getActiveIssues(issueSelection, submission), 
                                    currentUserId
                                )
                            }
                            onClick={() => triggerCreateReactions(ISSUE_REACTION.disagree)}
                        >
                            Disagree with {issueSelection.length} Issue(s)
                        </Button>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Button
                            className="buttonLine"
                            size="small"
                            type="primary"
                            disabled={issueSelection.length <= 0}
                            style={{ display: userCanRejectnCloseIssue || userIsAdmin ? "inherit" : "none" }}
                            onClick={async () => {

                                let issuesWithFeedbackDocError = getFeedbackDocFailedIssue(issueSelection);

                                if (issuesWithFeedbackDocError.length > 0) {
                                    warningMessage();
                                } else {
                                    confirm({
                                        title: "Are you sure you want to close the selected issues?",
                                        okText: "Yes",
                                        onOk: async () => {
                                            const issueList = issueSelection.map((id) => {
                                                return {
                                                    id,
                                                    status: ISSUE_STATUS_LIST.close,
                                                    submissionId: submission.id,
                                                    readyForReview: false
                                                };
                                            });
                                            if (issueList && issueList.length > 0) {
                                                await handleMutation(
                                                    modifyIssues({
                                                        variables: {
                                                            newIssues: issueList,
                                                            notify: true
                                                        }
                                                    })
                                                );
                                                setRefetchData(true);
                                                setIssueSelection([]);
                                            }
                                        }
                                    });
                                }
                                
                            }}
                        >
                            Mark {issueSelection.length} Issue(s) as Closed
                        </Button>

                        <Button
                            className="buttonLine"
                            style={{ display: userCanRejectnCloseIssue || userIsAdmin ? "inherit" : "none" }}
                            size="small"
                            type="danger"
                            disabled={documentStatusReviewed || (issueSelection.length <= 0)}
                            onClick={() => {
                                let issuesWithFeedbackDocError = getFeedbackDocFailedIssue(issueSelection);

                                if (issuesWithFeedbackDocError.length > 0) {
                                    warningMessage();
                                } else {
                                    confirm({
                                        title: "Are you sure you want to reject the selected issues?",
                                        okText: "Yes",
                                        onOk: () => {
                                            const activeIssues = getActiveIssues(issueSelection, submission);
                                            setRejecting(true);
                                            disagreeWithIssues(activeIssues);
                                        }
                                    });
                                }
                                
                            }}
                        >
                            Reject {issueSelection.length} Issue(s)
                        </Button>
                    </div>
                </>
            )}

            <IssueTable
                persistState
                submission={submission}
                issues={submission.issues}
                submitterIssues={submitterIssues}
                issueSelection={issueSelection}
                newBreadcrumbs={newBreadcrumbs}
                setIssueSelection={setIssueSelection}
                isPackagedSubmission={isPackagedSubmission}
                setRefetchData={setRefetchData}
                displayForSubmissionDetail={true}
            />
            <Button
                id="createIssue"
                style={{
                    float: "right",
                    display: (userCreateIssue || (isDQSubmission && userIsDataQualityAdmin)) && !currentReview?.completed && !submission.archived && !documentStatusReviewed && submission.uploadStatus == SUBMISSION_STATUS_SUCCESS ? "initial" : "none"
                }}
                type="primary"
                className="spaceBelow-xs btn btn-sm btn-primary"
                onClick={() => setIssueVis(true)}
            >
                Create a New Issue
            </Button>
            <Modal
                title={"Explain the Issue"}
                visible={issueVis || showRescindAndContinue}
                maskClosable={false}
                destroyOnClose={true}
                afterClose= {()=>{
                    setModified({});
                    setCreateFeedbackDocumentFiles({});
                }}
                onOk={async (e) => {
                    e.preventDefault();
                    setIssueModalLoading(true);
                    const contractReviewer = getCurrentReview();
                    let newIssue;
                    let relatedDocuments = isPackagedSubmission 
                        ? modified?.relatedDocuments 
                        : [submission?.submissionDocuments?.[0]?.id];

                    relatedDocuments = !Array.isArray(relatedDocuments) 
                        ? [] 
                        : relatedDocuments.filter(id => id && id !== RELATED_DOCUMENTS_NULL_OPTION.id);

                    if (userAdminAssignment || userIsDataQualityAdmin && !contractReviewer) {
                        const contractAdminId = userId;

                        const newAssignment = {
                            nodeId: submission.id,
                            assigneeId: contractAdminId,
                            roleId: userAdminAssignment ? userAdminAssignment.role.id : userDataQualityAdminAssignment.role.id 
                        };
                        const assignmentMutation = createReviewerAssignment({
                            variables: {
                                newAssignment
                            }
                        });

                        const assignmentResult = await handleMutation(assignmentMutation, { returnResponse: true });

                        const raisedInReviewId = assignmentResult?.data?.createReviewerAssignment?.id;
                        const successfulAssignment = raisedInReviewId && typeof raisedInReviewId === "string";

                        if (!successfulAssignment) {
                            resetStateForFailedMutation();
                            return;
                        }

                        newIssue = {
                            raisedInReviewId,
                            reason: modified.reason,
                            submissionId: submission.id,
                            criteriaId: modified.criteriaId, 
                            relatedDocuments
                        };

                    } else {
                        newIssue = {
                            reason: modified.reason,
                            raisedInReviewId: currentReview.id,
                            submissionId: submission.id,
                            criteriaId: modified.criteriaId,
                            relatedDocuments
                        };

                        const successfulReviewModification = await handleMutation(
                            modifyReview({
                                variables: {
                                    id: currentReview.id,
                                    submissionId: submission.id
                                }
                            })
                        );

                        if (!successfulReviewModification) {
                            resetStateForFailedMutation();
                            return;
                        }
                    }

                    // we need to mark this as "ready for review", or else the submitter won't be able to see it ::  
                    if (showRescindAndContinue) {
                        newIssue.readyForReview = true;
                    }

                    await handleMutation(
                        createIssue({
                            variables: {
                                newIssue: { 
                                    ...newIssue, 
                                    reviewerId: currentUserId === null ? currentReview.reviewer.id : currentUserId,
                                    feedbackDocumentFile: {
                                        name: createFeedbackDocumentFiles?.name, 
                                        size: createFeedbackDocumentFiles?.size, 
                                        file: createFeedbackDocumentFiles?.originFileObj 
                                    }
                                }
                            }
                        })
                    );

                    if (showRescindAndContinue) {
                        const updateSubmission = {
                            id: submission.id,
                            status: SUBMISSION_STATUS_LIST.version,
                            action: NOTIFICATION_TYPES.SUBMISSION_RESCINDED
                        };
                        await handleMutation(
                            modifySubmission({
                                variables: {
                                    updateSubmission
                                }
                            })
                        );
                        setShowRescindAndContinue(false);
                    }

                    setRefetchData(true);
                    setIssueVis(false);
                    setModified({});
                    setCreateFeedbackDocumentFiles({});
                }}
                confirmLoading={issueModalLoading}
                okText={showRescindAndContinue ? "Save and Rescind" : "Save"}
                okButtonProps={{
                    disabled: issueMayNotBeSaved(modified)
                }}
                cancelButtonProps={{
                    disabled: issueModalLoading
                }}
                closable={!issueModalLoading}
                onCancel={() => {
                    setModified({});
                    setIssueVis(false);
                    setShowRescindAndContinue(false);
                }}
            >
                <Spin size="large" spinning={createIssueLoading}>
                    <TextAreaDetail
                        title="Reason"
                        key="reason"
                        placeholder="Describe the reason this issue is being created, and note any changes required to resolve this issue..."
                        value={modified.reason ?? ""}
                        strict={true}
                        readOnly={issueModalLoading}
                        onValueUpdated={(e) => {
                            setModified({
                                ...modified,
                                reason: e.target.value
                            });
                        }}
                    />
                    <SelectableDetail
                        title="Related Criteria"
                        passedKey="criteriaId"
                        multiple={false}
                        defaultValue={modified.criteriaId}
                        value={modified.criteriaId}
                        readOnly={issueModalLoading}
                        onValueUpdated={(e) => {
                            return setModified({
                                ...modified,
                                criteriaId: e
                            });
                        }}
                        options={[
                            ...applicableCriteria.map(
                                ({ id, description, specifier, citation }) => {
                                    return {
                                        id,
                                        value: id,
                                        text: `${specifier ?? ""}${specifier ? ".) " : ""}${citation ?? ""}${citation ? " -  " : ""}${description}`
                                    };
                                }
                            ),
                            {
                                id: null,
                                value: null,
                                text: "This issue does not relate to any explicit criteria."
                            }
                        ]}
                    />
                    { isPackagedSubmission && 
                        <RelatedDocumentsSelection 
                            submissionDocuments={submission?.submissionDocuments ?? []}
                            selectedRelatedDocuments={modified?.relatedDocuments ?? []}
                            setSelectedRelatedDocuments={(selectedDocs) => setModified({
                                ...modified,
                                relatedDocuments: [...selectedDocs]
                            })}
                            readOnly={issueModalLoading}
                        /> 
                    }
                    <hr style= {{opacity:"20%"}}></hr>
                    <UploadFeedbackDocument 
                        feedbackDocument={createFeedbackDocumentFiles}
                        setFeedbackDocument={setCreateFeedbackDocumentFiles}
                    />
                </Spin>
            </Modal>
            <DisagreeModal 
                issues={disagreedIssues}
                rejecting={rejecting}
                submissionId={submission.id}
                onOk={() => {
                    disagreeWithIssues(null);
                    setIssueSelection([]);
                    setRefetchData(true);
                    setRejecting(false);
                }}
                onCancel={() => {
                    disagreeWithIssues(null);
                    setRejecting(false);
                }}
                afterClose={() => {
                    disagreeWithIssues(null);
                    setRejecting(false);
                }}
            />
        </>
    );
};


