import React, { useContext} from "react";
import { UserContext } from "../Application/UserContext.js";
import { Main } from "../common/Main/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { ContractsTable } from "./contractTable.js";

export const Contracts = () => {

    const {
        userPermCreateContracts,
        userPermViewContracts
    } = useContext(UserContext);

    if (!userPermViewContracts) {
        return <NoPermission />;
    }

    return (
        <Main
            title="Contracts"
            breadcrumbs={["Contracts"]}
            details={
                userPermCreateContracts && <PCDULink
                    className="ant-btn ant-btn-primary"
                    to="/contracts/create"
                    style={{
                        float: "right"
                    }}
                >
                    Create a Contract
                </PCDULink>
            }
        >
            <ContractsTable />
        </Main>
    );
};
