import React from "react";
import { useParams } from "react-router-dom";
import { SubmissionTypeCategoryChangelogsQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";
import { PCDULink } from "../common/PCDULink/index.js";

export const SubmissionTypeCategoryChangelogs = (({
    actions,
    roles,
    subjectData,
    setSubjectData
}) => {
    const params = useParams();
    const currentPageSubjectId = params.id;

    const subjectBreadcrumb = {
        path: "/changelogs/submissionTypeCategory",
        label: "Submission Type Category Changelogs"
    };

    return (
        <div style={{ minWidth: "900px" }}>
            <ChangelogsTable
                subjectBreadcrumb={subjectBreadcrumb}
                subjectData={subjectData}
                setSubjectData={setSubjectData}
                filterOptions={{
                    actions,
                    roles
                }}
                query={SubmissionTypeCategoryChangelogsQuery}
                exportFilename={"SubmissionTypeCategory_Changelogs.csv"}
                subjectHeader={{ label: "Submission Type Category", key: "name" }}
                subjectTransform={(changelog) => changelog?.subject?.name}
                subjectColumn={{
                    title: "Submission Type Category",
                    dataIndex: ["subject", "name"],
                    render: (highlightableText, { subject }) => {
                        return (
                            <>
                                <PCDULink
                                    to={`/submission-types/category/${subject.id}`}
                                    newBreadcrumbs={[
                                        { label: "Changelogs", path: "/changelogs" },
                                        subjectBreadcrumb
                                    ]}
                                >
                                    {highlightableText}
                                </PCDULink>
                                {currentPageSubjectId !== subject.id &&
                                    <div>
                                        <span>
                                            <PCDULink to={`/changelogs/submissionTypeCategory/${subject.id}`}> Changelogs </PCDULink>
                                        </span>
                                    </div>
                                }
                            </>
                        );
                    }
                }}
            />
        </div>
    );
});
