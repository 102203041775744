import React, { useState } from "react";
import { Col, Form, Row, Input } from "antd";

export const EditableDetail = ({
    title,
    inputId,
    placeholder,
    value,
    onValueUpdated,
    additionalRules = [],
    strict = false,
    readOnly = false,
    required = false,
    maxLength = 255,
    type,
    name
}) => {
    const [updatedValue, setUpdatedValue] = useState(value);

    if (!strict) {
        if (typeof value === "undefined" && typeof updatedValue !== "undefined") {
            setUpdatedValue(undefined);
        }
    }
    const validation = [{
        required: true,
        message: `Please input ${name ? name : title}`,
        whitespace: true
    }, ...additionalRules];

    return (
        <>
            {required ? (
                <>
                    <label style={{ color: "#7771ab", fontSize: "15px", fontWeight: "600" }}>{title}</label>
                    <Form.Item
                        name={inputId}
                        rules={validation}
                        initialValue={value}
                    >
                        <Input
                            size="small"
                            value={strict ? value : updatedValue}
                            placeholder={placeholder || title}
                            onChange={(e) => {
                                if (!strict) {
                                    setUpdatedValue(e.target.value);
                                }
                                onValueUpdated(e);
                            }}
                            readOnly={readOnly}
                        />
                    </Form.Item>
                </>
            ) : (
                <Row>
                    <Col span={24}>
                        <div className="ant-form-vertical ">
                            <Form.Item label={title} name={name} style={{marginRight:"10px"}}>
                                <Input name={name || inputId}
                                    placeholder={title}
                                    readOnly={readOnly}
                                    maxLength={maxLength}
                                    value={strict ? value : updatedValue}
                                    onChange={(e) => {
                                        if (!strict) {
                                            setUpdatedValue(e.target.value);
                                        }
                                        onValueUpdated(e);
                                    }}
                                    type={type} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};
