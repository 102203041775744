import moment from "moment";
import React, { useState } from "react";
import { formatDate } from "../../utils/functions.js";
import { dateable, isValidString, searchable } from "../../utils/index.js";
import { Table } from "../common/Table/index.js";
import { FilterIcon } from "../common/FilterIcon/index.js";
import { PCDULink } from "../common/PCDULink/index.js";

const isActiveDateFilter = (arr) => Array.isArray(arr) && arr.length === 2;

const filterContracts = (contracts, { start, end, specifier }) => {
    if (Array.isArray(contracts) && contracts.length > 0) {
        return contracts.filter(contract => {
            
            if (isActiveDateFilter(start) && !moment(contract.startDate).isBetween(start[0], start[1])) {
                return false;
            } 
            
            if (isActiveDateFilter(end) && !moment(contract.endDate).isBetween(end[0], end[1])) {
                return false;
            }

            if (isValidString(specifier) && isValidString(contract.specifier)) {
                return contract.specifier.toLowerCase().includes(specifier.toLowerCase());
            }
            
            return true;
        });
    } else {
        return [];
    }
}; 


export const OrganizationContractsTable = ({ 
    newBreadcrumb, 
    contracts, 
    contractTypes 
}) => {
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [contractSpecifierSearch, setContractSpecifierSearch] = useState("");

    const filteredContracts = filterContracts(contracts, {
        start: startDateFilter,
        end: endDateFilter, 
        specifier: contractSpecifierSearch 
    });

    return (
        <>
            <h2>Contracts</h2>
            <Table
                id="contractsTable"
                rowKey="id"
                columns={[
                    searchable({
                        title: "Contract ID",
                        key: "specifier",
                        dataIndex: "specifier",
                        width: "25%",
                        render: (specifier, { id }) => {
                            return (
                                <PCDULink 
                                    to={`/contracts/${id}`}
                                    newBreadcrumbs={newBreadcrumb}
                                >
                                    {specifier}
                                </PCDULink>
                            );
                        },
                        defaultSortOrder: "ascend",
                        sorter: (a, b) => a.specifier.localeCompare(b.specifier),
                        sortDirections: ["ascend", "descend", "ascend"], 
                        handleReset: () => setContractSpecifierSearch(""),
                        handleSearch: (text) => setContractSpecifierSearch(text),
                        searchedText: contractSpecifierSearch 
                    }),
                    {
                        title: "Contract Type",
                        key: "contract-type",
                        dataIndex: "contractType",
                        width: "25%",
                        render: ({ id, name }) => {
                            return (
                                <PCDULink 
                                    to={`/contract-types/${id}`}
                                    newBreadcrumbs={newBreadcrumb}
                                >
                                    {name}
                                </PCDULink>
                            );
                        },
                        filters: contractTypes.map(({ id, name }) => {
                            return {
                                text: name,
                                value: id
                            };
                        }), 
                        sorter: (a, b) => a.specifier.localeCompare(b.specifier),
                        sortDirections: ["ascend", "descend", "ascend"], 
                        onFilter: (value, record) => (record.contractType.id === value), 
                        filterIcon: FilterIcon 
                    },
                    dateable({ 
                        title: "Start Date",
                        key: "start date",
                        dataIndex: "startDate",
                        sorter: (a, b) => moment(a.startDate) - moment(b.startDate),
                        sortDirections: ["descend", "ascend", "descend"],
                        render: (startDate) => formatDate(startDate),
                        filter: startDateFilter,
                        setFilter: (dateSelection) => setStartDateFilter(dateSelection),
                        handleReset: () => setStartDateFilter(null)
                    }),
                    dateable({
                        title: "End Date",
                        key: "end date",
                        dataIndex: "endDate",
                        sorter: (a, b) => moment(a.endDate) - moment(b.endDate), 
                        sortDirections: ["descend", "ascend", "descend"],
                        render: (endDate) => formatDate(endDate),
                        filter: endDateFilter, 
                        setFilter: (dateSelection) => setEndDateFilter(dateSelection),
                        handleReset: () => setEndDateFilter(null) 
                    })
                ]}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50"]
                }}
                dataSource={filteredContracts}
            />
        </>
    );
};