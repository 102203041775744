import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { Button } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { CommentsList } from "../common/CommentsList/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { HeaderDetail } from "../common/HeaderDetail/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { LabeledDetail } from "../common/LabeledDetail/index.js";
import { isUndefinedOrNull } from "../../utils/functions.js";
import { CreateAttestationForm } from "../../utils/createAttestationPdf.js";
import { CriteriaTable } from "../common/CriteriaTable/index.js";
import {
    ROLE_DISPLAY_CONVERSION,
    SUBMISSION_STATUS_LIST,
    ISSUE_STATUS_LIST,
    S3_UPLOAD_STATUS,
    SUBMISSION_STATUS_SUCCESS,
    DOCUMENT_STATUS
} from "../../constants/index.js";
import { handleGraphQLError, FILE_UNAVAILABLE } from "../../utils/errorHandling.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";
import { DocumentDetailQuery } from "./query.js";
import { IssueTable, IssuesTableHeader } from "../common/IssueTable/index.js";
import { DownloadButton } from "../common/DownloadButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { DocumentDQStatus } from "../common/DocumentDQStatus/index.js";

const getArchivedDocumentVersions = ({ submission, documentVersions }) => {
    if (submission.archived === true) {
        // all document versions are effectively archived, so : 
        return documentVersions.slice();
    } else {
        
        const archivedVersionSpecifiers = submission.submissionVersions
            .filter(({ archived }) => archived === true)
            .map(( { specifier }) => parseInt(specifier, 10));
        
        return documentVersions.filter(docVersion => archivedVersionSpecifiers.includes(docVersion.submissionVersionSpecifier));
    }
};

const excludeFirstBreadcrumb = (lastHistoryBreadcrumb, secondToLastHistoryBreadcrumb) => {
    return lastHistoryBreadcrumb?.label?.includes("Submissions") || 
        lastHistoryBreadcrumb?.path === "/submissions/create" || 
        lastHistoryBreadcrumb?.path?.includes("/issues") || 
        secondToLastHistoryBreadcrumb?.path === "/submissions/create" || 
        secondToLastHistoryBreadcrumb?.label?.includes("Submissions");
};

const excludeSecondBreadcrumb = (lastHistoryBreadcrumb, secondToLastHistoryBreadcrumb) => {
    return lastHistoryBreadcrumb?.path === "/submissions/create" || 
        lastHistoryBreadcrumb?.path?.includes("/issues") || 
        secondToLastHistoryBreadcrumb?.path === "/submissions/create" || 
        secondToLastHistoryBreadcrumb?.label?.includes("Submissions");
};

export const DocumentDetail = (() => {
    const location = useLocation();
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);
    const secondToLastHistoryBreadcrumb = getHistoryBreadcrumbs(-2);
    const [displayedVersionId, setDisplayedVersionId] = useState(null);
    const [refetchData, setRefetchData] = useState(false);
    const [record, setRecord] = useState({
        loading: true
    });
    const [s3Status, sets3Status] = useState("");
    const {
        userId,
        userIsSubmitter,
        userPermDownloadAllDocs,
        userPermDownloadDocument,
        userPermDownloadFinalDoc,
        userPermViewComments
    } = useContext(UserContext);
    const params = useParams();

    const { loading, error, data, refetch } = useQuery(
        DocumentDetailQuery, 
        {
            variables: {
                id: params.id
            },
            fetchPolicy: "no-cache"
        }
    );

    useEffect(() => {
        if (!isUndefinedOrNull(data)) {
            let id = data?.submissionDocument?.documentNewestVersion?.id;

            if (displayedVersionId && displayedVersionId !== id) {
                setRecord({ loading: true });
                id = displayedVersionId;
            }
            let s3_upload_status = data?.submissionDocument?.documentVersions.find(({ id }) => id === displayedVersionId).S3UploadStatus;
            if (id && record?.id !== id) {
                fetch(`/archive/list/${id}?path=Documents`)
                    .then(response => response.json())
                    .then(newData => {
                        if (newData?.[0]) {
                            setRecord(newData[0]);
                        }
                        else {
                            setRecord(null);
                            if (s3_upload_status === S3_UPLOAD_STATUS.quarantine) {
                                sets3Status("File processing error");
                            } else if (s3_upload_status === S3_UPLOAD_STATUS.uploaded) {
                                sets3Status("File processing");
                            } else {
                                sets3Status(FILE_UNAVAILABLE);
                            }
                        }
                    }).catch(() => {
                        setRecord(null);
                        sets3Status(null);
                    });
            }
        }
    }, [data, displayedVersionId]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (refetchData) {
        refetch().then(() => {
            setDisplayedVersionId(null);
            setRefetchData(false);
        });
    }

    const { submissionDocument } = data;

    if (isUndefinedOrNull(submissionDocument)) {
        return <NoContent />;
    } 

    const userCanDownloadDocument = userPermDownloadDocument(submissionDocument.submission.obligation.submissionType.id);

    if (displayedVersionId === null && Array.isArray(submissionDocument.documentVersions)) {
        setDisplayedVersionId(submissionDocument.documentNewestVersion.id);
    }

    const displayedDocumentVersion = submissionDocument.documentVersions.find(({ id }) => id === displayedVersionId); 
    const submissionVersions = submissionDocument?.submission?.submissionVersions ?? []; 
    const submissionVersionOfDisplayedDocument = submissionVersions.find(({ specifier }) => specifier === displayedDocumentVersion?.submissionVersionSpecifier);
    const submissionDateOfDocument = submissionVersionOfDisplayedDocument?.createdAt; 
    const archivedDocumentVersions = getArchivedDocumentVersions(submissionDocument);
    const newBreadcrumb = { 
        label: submissionDocument.specifier, 
        path: location.pathname.includes("documents") && !location.pathname.includes("submissions") ? `/documents/${submissionDocument.id}` : `/submissions/${submissionDocument.submissionId}/documents/${submissionDocument.id}`, 
        tooltip: `Submission Document: ${submissionDocument.specifier}`
    };

    // just for shortening the path for references to this
    const thisSubmissionType = submissionDocument.submission.obligation.submissionType;

    const submitterIssues = submissionDocument?.issues?.filter?.(({ status, readyForReview }) => status === ISSUE_STATUS_LIST.active && readyForReview === true);

    const getPiiPhibyVersion = (key) => {
        let selectedVal = submissionDocument?.documentVersions.find(({ id }) => (displayedVersionId === id));
        return selectedVal?.[key];
    };

    const details = (
        <div id="details">
            {submissionDocument?.submission?.archived === true && <span style={{ fontSize: "16px", color: "red" }}> Archived </span>}
            <HeaderDetail
                title="Submission"
                text=""
                value={submissionDocument.submissionId}
                to={`/submissions/${submissionDocument.submissionId}`}
                linkTitle={submissionDocument.submission.specifier}
                newBreadcrumbs={newBreadcrumb}
            />
            <HeaderDetail
                title="Organization"
                text=""
                value={submissionDocument.submission.submitter.name}
                to={`/organizations/${submissionDocument.submission.submitter.id}`}
                linkTitle={submissionDocument.submission.submitter.name}
                newBreadcrumbs={newBreadcrumb}
            />
            <HeaderDetail
                title="Submission Type"
                text=""
                to={`/submission-types/${thisSubmissionType.id}`}
                linkTitle={
                    `${thisSubmissionType.specifier} - ${thisSubmissionType.name}`
                }
                newBreadcrumbs={newBreadcrumb}
            />
            {submissionDocument?.documentType?.name && <HeaderDetail
                title="Sub-Submission Type (Child)"
                text={submissionDocument.documentType.name}
            />}
            <br />
            <LabeledDetail
                title="Date Submitted"
                value={moment.unix(submissionDocument.documentNewestVersion.createdAt / 1000).format("MM/DD/YYYY")}
            />
            <LabeledDetail
                title="Document Status"
                value={submissionDocument.deleted || submissionDocument?.submission?.archived === true ? `Document Archived (Last Version ${parseInt(submissionDocument.documentNewestVersion.specifier, 10) + 1})` : (submissionDocument?.submission?.uploadStatus === SUBMISSION_STATUS_SUCCESS) ? DOCUMENT_STATUS[submissionDocument.S3UploadStatus] : submissionDocument?.submission?.uploadStatus}
            />
            {(thisSubmissionType?.dqFlag === true && !userIsSubmitter)
                ? <LabeledDetail title={"DQ Status"} >
                    <DocumentDQStatus 
                        showSpinner={false}
                        loading={loading} 
                        document={submissionDocument?.documentNewestVersion} 
                    />
                </LabeledDetail>
                : null 
            } 
            <LabeledDetail
                title="Personally Identifiable Information"
                value={getPiiPhibyVersion("containsPii") ? "Contains PII" : "Does Not Contain PII"}
            />
            <LabeledDetail
                title="Protected Health Information"
                value={getPiiPhibyVersion("containsPhi") ? "Contains PHI" : "Does Not Contain PHI"}
            />
            <div
                className="detail-panel spaceAbove-sm"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "200px"
                }}
            >

                {submissionDocument?.documentNewestVersion?.S3UploadStatus === S3_UPLOAD_STATUS.uploaded && 
                    <div style={{ minWidth: `${archivedDocumentVersions.length > 0 ? "150px" : "75px"}`, marginRight: "20px" }}>
                        <SelectableDetail
                            title="Version"
                            passedKey="version-selection"
                            options={submissionDocument.documentVersions.map(({ id, specifier }) => {
                                const isArchived = archivedDocumentVersions.some(vers => vers.id === id);
                                return {
                                    id,
                                    text: `${parseInt(specifier, 10) + 1}${isArchived === true ? " - Archived" : ""}`,
                                    value: id
                                };
                            })}
                            value={displayedVersionId}
                            onValueUpdated={(value) => {
                                setDisplayedVersionId(value);
                            }}
                            refetch={() => refetch()}
                        />
                    </div> 
                }
                {record === null ? <div style={{ flex: "none" }}>{s3Status} </div> :  (
                    <DownloadButton 
                        className="spaceBetween-md"
                        disabled={record?.loading}
                        display={(userIsSubmitter || userCanDownloadDocument || userPermDownloadAllDocs)}
                        document={displayedDocumentVersion}
                        showIcon={false}
                    /> 
                )}
                <Button
                    type="primary"
                    style={{
                        display: submissionDocument.submission.obligation.submissionType.useAttestation ? "block" : "none"
                    }}
                    onClick={() => {
                        CreateAttestationForm({
                            name: submissionDocument.submission.submissionNewestVersion.submitter.name,
                            title: ROLE_DISPLAY_CONVERSION[submissionDocument.submission.submissionNewestVersion.attestationRoleName]
                        });
                    }}
                >
                    Download Attestation
                </Button>
                <DownloadButton 
                    className="spaceBetween-md"
                    display={(userPermDownloadFinalDoc && submissionDocument.status === SUBMISSION_STATUS_LIST.final)}
                    document={submissionDocument.documentNewestVersion} 
                    showIcon={false}
                    text="Download Finalized Version"
                />
            </div>
        </div>
    );

    const criteriaList = submissionDocument.submission?.obligation?.submissionType?.allCriteria; 

    const breadcrumbs = params.submissionId 
        ? [
            excludeFirstBreadcrumb(lastHistoryBreadcrumb, secondToLastHistoryBreadcrumb) 
                ? null 
                : <PCDULink key="submission" to={"/submissions"} tooltip="All Submissions"> Submissions</PCDULink>,
            excludeSecondBreadcrumb(lastHistoryBreadcrumb, secondToLastHistoryBreadcrumb)
                ? null 
                : <PCDULink key="submissionDetail" to={`/submissions/${params.submissionId}`} tooltip={`Submission: ${submissionDocument.submission.specifier}`}>
                    {submissionDocument.submission.specifier}
                </PCDULink>,
            <PCDULink key="document" to="/documents" tooltip="All Documents">Documents</PCDULink>,
            submissionDocument.specifier 
        ]
        : [
            lastHistoryBreadcrumb?.path === "/documents" ? null : <PCDULink key="document" to="/documents" tooltip="All Documents">Documents</PCDULink>,
            submissionDocument.specifier 
        ];

    const finalize = submissionDocument.status === SUBMISSION_STATUS_LIST.final ? "_final" : "";
    const isPackagedSubmission = Boolean(submissionDocument?.submission?.submissionType?.packagedSubmission);
    return (
        <Main
            title={`Submission Document - ${submissionDocument.specifier} ${finalize}`}
            details={details}
            breadcrumbs={breadcrumbs}
        >
            <CriteriaTable 
                persistState
                criteriaList={criteriaList} 
                versionFilterTimestamp={submissionDateOfDocument} 
                loadingOnUndefined={true}
            />
            <IssuesTableHeader></IssuesTableHeader>
            <IssueTable
                persistState
                submission={submissionDocument.submission}
                issues={submissionDocument.issues}
                submissionId={submissionDocument.submissionId}
                documentId={submissionDocument.id}
                submitterIssues={submitterIssues}
                isPackagedSubmission={isPackagedSubmission}
                setRefetchData={setRefetchData}
                newBreadcrumbs={newBreadcrumb}
                hideRowSelection={true}
                displayFeedbackDocsRow={false}
                displayResolvedDocCol={true}
            />
            {userPermViewComments &&
                <CommentsList
                    title="Document Chat Board (Internal only comments field)"
                    parent={submissionDocument}
                    userId={userId}
                    refetch={() => refetch()}
                    showTitleWithoutComments={true}
                />
            }
        </Main>
    );
});
