import React, { useState, useEffect } from "react";
import { Upload, message, Divider } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedical, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { PlusCircleTwoTone, CheckCircleTwoTone, InfoCircleFilled } from "@ant-design/icons";

import { EditableDetail } from "../EditableDetail/index.js";
import { SelectableDetail } from "../SelectableDetail/index.js";
import {
    SUBMISSION_FILE_EXTENSION_STRING,
    SUBMISSION_FILE_EXTENSION_ARRAY,
    validFileUploadedExtension
} from "../../../utils/index.js";
import {
    SUBMISSION_MAX_FILE_SIZE_UPLOAD,
    oneGB
} from "../../../constants/index.js";
import { FILE_UPLOAD_ERROR_MSG } from "../../../utils/errorHandling.js";
const { Dragger } = Upload;

const documentTypeToDisplay = (doctype) => {
    if (!doctype || typeof doctype !== "object") {
        return "";
    } else {
        const specifierText = doctype.specifier ? `${doctype.specifier} - ` : "";
        const nameText = doctype.name ? `${doctype.name}` : "";
        return `${specifierText}${nameText}`;
    }
};

export const SubmissionDocumentUpload = ({
    maxDocumentCount,
    createSubmissionFiles,
    setCreateSubmissionFiles,
    newVersionSubmissionFiles,
    setNewVersionSubmissionFiles,
    documentTypes,
    newVersion = false,
    finalVersion,
    submissionDocumentsData
}) => {
    const [uploadMsg, setUploadMsg] = useState(false);
    const [newVersionFiles, setNewVersionFiles] = useState([]);
    const showDocumentTypes = Array.isArray(documentTypes) && documentTypes.length > 0;
    const nullDocumentType = {
        text: " -- None -- ",
        value: null,
        id: "null-document-type"
    };
    const fileType = SUBMISSION_FILE_EXTENSION_STRING;
    const fileTypeUpload = SUBMISSION_MAX_FILE_SIZE_UPLOAD;
    const fileTypeArray = SUBMISSION_FILE_EXTENSION_ARRAY;

    const fileUploadIcon = (size = "4x", color = "#262626") => {
        return (
            <p className="ant-upload-drag-icon">
                <FontAwesomeIcon icon={faFileUpload} color={color} size={size} />
            </p>
        );
    };

    const uploadNotes = (
        <div style={{ textAlign: "center" }}>
            <div style={{ wordBreak: "break-word" }}>
                <InfoCircleFilled twoToneColor="#52c41a" style={{ fontSize: "15px", marginRight: "5px" }} />
                <span style={{ fontWeight: 600 }}>
                    Note:
                </span>
                <span>{" "}Accepted file extensions</span>
                <div style={{ fontSize: "12px" }}>
                    {fileType}
                </div>
            </div>
            <br />
            {finalVersion && (
                <p>
                    Note: This will be saved as the FINAL version for this document
                </p>
            )}
            <p>Individual file size limit is {fileTypeUpload / oneGB}GB</p>
        </div>

    );

    const defaultUploadContent = (
        <>
            {fileUploadIcon()}
            <p className="ant-upload-text">
                Upload your file by clicking or dragging it here
            </p>
        </>
    );

    const canUploadManyFile = (maxDocumentCount && maxDocumentCount === 1 && createSubmissionFiles && !submissionDocumentsData) ? (Object.keys(createSubmissionFiles).length <= 0 ? true : false) : true;

    const uploadFileDiv = (draggerContent, documentId, metaData) => {

        return canUploadManyFile && (
            <Dragger
                className="spaceBelow-sm"
                name="file"
                multiple={false}
                showUploadList={false}
                accept={fileType}
                customRequest={() => {
                    // To stop default xhr request  
                }}
                maxCount={maxDocumentCount}
                onChange={(info) => {

                    if (info.file.size === 0) {
                        message.error(FILE_UPLOAD_ERROR_MSG);
                        info.fileList.pop();
                        return;
                    }

                    setUploadMsg(true);
                    let totalFileSize = 0;

                    if (!validFileUploadedExtension(info.file, fileTypeArray) || info.file.size > fileTypeUpload) {
                        info.fileList.pop();
                        if (info.file.size > fileTypeUpload) {
                            message.error(`File size can not exceed more than ${fileTypeUpload / oneGB}GB!`);

                        } else {
                            message.error(`${info.file.name} is not a valid file extension`);
                        }

                        setUploadMsg(false);
                    } else {
                        info.fileList.forEach((file) => {
                            totalFileSize = totalFileSize + file.size;
                        });

                        if (totalFileSize > fileTypeUpload) {
                            message.error(`Total file size can not exceed more than ${fileTypeUpload / oneGB}GB!`);
                            info.fileList.pop();
                        }

                        // Submission Document Upload
                        if (createSubmissionFiles) {
                            let addSubmissionFiles = {};

                            if (newVersion && documentId) {
                                let addNewVersion = [];
                                info.file["documentId"] = documentId;
                                info.file["metaData"] = metaData;

                                if (newVersionFiles.length > 0) {

                                    const filteredFiles = newVersionFiles.filter((file) => {
                                        if (file.documentId === documentId) {
                                            message.success("Previously uploaded file got replaced with newly selected file");
                                        }
                                        return file.documentId !== documentId;
                                    });
                                    addNewVersion.push(...filteredFiles);
                                }
                                addNewVersion.push(info.file);
                                setNewVersionFiles(addNewVersion);
                                addSubmissionFiles = {
                                    ...addNewVersion
                                };
                                setNewVersionSubmissionFiles(addSubmissionFiles);

                            } else {
                                const newFiles = info.fileList
                                    .filter(({ uid }) => {
                                        return !Object.keys(createSubmissionFiles).includes(uid);
                                    })
                                    .map((item) => ({ [item.uid]: item }));
                                let newSubmissionFilesToAdd = Object.assign({}, ...newFiles);

                                const fileListIds = info.fileList.map(({ uid }) => uid);
                                const existingFilesInFileList = Object.values(createSubmissionFiles)
                                    .filter((value) => {
                                        return fileListIds.includes(value.uid);
                                    })
                                    .map((item) => ({ [item.uid]: item }));
                                const existingSubmissionFilesToKeep = Object.assign({}, ...existingFilesInFileList);
                                addSubmissionFiles = {
                                    ...existingSubmissionFilesToKeep,
                                    ...newSubmissionFilesToAdd
                                };
                                setCreateSubmissionFiles(addSubmissionFiles);
                            }
                        }

                        setUploadMsg(false);
                    }
                }}
            >
                {draggerContent}
            </Dragger>
        );
    };

    const isNewVersionUploaded = (documentId) => {
        let isFileUploaded = false;
        let filesKey = Object.keys(newVersionSubmissionFiles);
        isFileUploaded = filesKey.find((id) => newVersionSubmissionFiles[id].documentId === documentId);
        return newVersionSubmissionFiles[isFileUploaded];
    };

    const submissionNewVersionUploader = submissionDocumentsData && (
        <>
            {submissionDocumentsData.map(({ nickname, id, documentType }) => {
                const uploadedFileInfo = isNewVersionUploaded(id);

                return (
                    <div style={{ textAlign: "left" }} key={id}>
                        {uploadFileDiv((
                            <>
                                {uploadedFileInfo ? (
                                    <>
                                        {fileUploadIcon("3x", "#237804")}
                                        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "20px", marginRight: "5px" }} />
                                        Uploaded
                                    </>
                                ) : (
                                    <>
                                        {fileUploadIcon("3x", "#262626")}
                                        <PlusCircleTwoTone twoToneColor="#69c0ff" style={{ fontSize: "20px", marginRight: "5px" }} />
                                        Upload
                                    </>
                                )}
                                &nbsp;{finalVersion ? "final" : "new"} Version Document
                                <div>
                                    <strong>Original File Name :</strong> {nickname}
                                </div>
                                {uploadedFileInfo && (
                                    <div>
                                        <strong>Uploaded File Name :</strong> {uploadedFileInfo.name}
                                    </div>
                                )}
                            </>
                        ), id, { nickname, documentType })}
                        {uploadedFileInfo &&
                            <PhiNPiiComponent
                                file={uploadedFileInfo}
                                createSubmissionFiles={createSubmissionFiles}
                                setCreateSubmissionFiles={setCreateSubmissionFiles}
                                newVersionSubmissionFiles={newVersionSubmissionFiles}
                                setNewVersionSubmissionFiles={setNewVersionSubmissionFiles}
                                newVersion={newVersion}
                            >
                            </PhiNPiiComponent>
                        }
                    </div>);
            })}
        </>
    );

    const newVersionCheck = newVersion ? (maxDocumentCount > 1 ? true : false) : true;

    const newSubmissionDocument = !finalVersion && newVersionCheck && createSubmissionFiles && (
        <>
            {
                Object.values(createSubmissionFiles).map((file, i) => {
                    return (
                        <div key={`create-sub-files-modal-list-${i}`}
                            style={{ border: "1px", borderRadius: "8px", marginTop: "18px" }}
                        >
                            <h2
                                style={{
                                    display: "flex",
                                    fontStyle: "italic",
                                    width: "100%",
                                    fontSize: "18px"
                                }}
                            >
                                <FontAwesomeIcon icon={faFileMedical} color="black" size="sm" />
                                <span style={{ wordBreak: "break-word" }}>{file.name}</span>
                            </h2>
                            <EditableDetail
                                title="Name"
                                key={file.uid}
                                value={typeof file.editedName === "undefined" ? file.name : file.editedName}
                                readOnly={true}
                                onValueUpdated={(e) => {
                                    setCreateSubmissionFiles({
                                        ...createSubmissionFiles,
                                        [file.uid]: {
                                            ...createSubmissionFiles[file.uid],
                                            editedName: e.target.value
                                        }
                                    });
                                }}
                            />
                            {showDocumentTypes ?
                                <SelectableDetail
                                    title="Sub-Submission Type (Child)"
                                    passedKey="documentTypeId"
                                    value={file.documentTypeId}
                                    onValueUpdated={(value) => {
                                        setCreateSubmissionFiles({
                                            ...createSubmissionFiles,
                                            [file.uid]: {
                                                ...createSubmissionFiles[file.uid],
                                                documentTypeId: value
                                            }
                                        });
                                    }}
                                    options={[nullDocumentType].concat(documentTypes.filter(({ id, name }) => id && name && typeof id === "string" && typeof name === "string").map((doctype) => {
                                        return {
                                            text: documentTypeToDisplay(doctype),
                                            value: doctype.id,
                                            id: `${doctype.id}`
                                        };
                                    }))}
                                />
                                : null
                            }
                            <PhiNPiiComponent
                                file={file}
                                createSubmissionFiles={createSubmissionFiles}
                                setCreateSubmissionFiles={setCreateSubmissionFiles}
                                newVersionSubmissionFiles={newVersionSubmissionFiles}
                                setNewVersionSubmissionFiles={setNewVersionSubmissionFiles}
                            ></PhiNPiiComponent>

                            {canUploadManyFile && (<Divider></Divider>)}
                        </div>
                    );
                }
                )}
            {uploadFileDiv(defaultUploadContent)}
            {uploadNotes}
        </>
    );

    return (
        <>
            {submissionNewVersionUploader}

            <div style={{ display: uploadMsg ? "block" : "none" }}>
                Upload Pending......
            </div>
            {newSubmissionDocument}
        </>

    );
};

const PhiNPiiComponent = ({
    file,
    createSubmissionFiles,
    setCreateSubmissionFiles,
    newVersionSubmissionFiles,
    setNewVersionSubmissionFiles,
    newVersion
}) => {
    const [fileInfo, setFileInfo] = useState();

    useEffect(() => {
        if (file.uid) {
            setFileInfo(file);
        }
    }, [file]);

    const selectedPhinPiiValue = (key) => {
        if (createSubmissionFiles && Object.keys(createSubmissionFiles).length > 0 && createSubmissionFiles[fileInfo?.uid]) {
            return createSubmissionFiles[fileInfo?.uid][key] ?? createSubmissionFiles[fileInfo?.uid][key];
        }

        if (newVersion && Object.keys(newVersionSubmissionFiles).length > 0) {
            let filesKey = Object.keys(newVersionSubmissionFiles);
            let selectedVersionDoc = filesKey.find((id) => newVersionSubmissionFiles[id].documentId === fileInfo?.documentId);
            return (newVersionSubmissionFiles[selectedVersionDoc] && newVersionSubmissionFiles[selectedVersionDoc]?.[key]) ?? newVersionSubmissionFiles[selectedVersionDoc]?.[key];
        }
    };

    const selectableValueUpdate = (key, value) => {
        if (newVersion && Object.keys(newVersionSubmissionFiles).length > 0) {
            let filesKey = Object.keys(newVersionSubmissionFiles);
            filesKey.map((id) => {
                if (newVersionSubmissionFiles[id].documentId === fileInfo?.documentId) {
                    newVersionSubmissionFiles[id][key] = value;
                }
            });
            setNewVersionSubmissionFiles({
                ...newVersionSubmissionFiles
            });
        }
        
        if (createSubmissionFiles && Object.keys(createSubmissionFiles).length > 0) {
            let filesKey = Object.keys(createSubmissionFiles);
            filesKey.map((id) => {
                if (id === fileInfo?.uid) {
                    createSubmissionFiles[id][key] = value;
                }
            });
            
            setCreateSubmissionFiles({
                ...createSubmissionFiles
            });
        }
    };

    return (
        <>
            <SelectableDetail
                title="Personally Identifiable Information* (Required)"
                passedKey="containsPii"
                value={selectedPhinPiiValue("containsPii")}
                onValueUpdated={(value) => {
                    selectableValueUpdate("containsPii", value);
                }}
                options={[
                    {
                        text: "Does Not Contain PII",
                        value: false,
                        id: "doesNotContainPii"
                    },
                    {
                        text: "Contains PII",
                        value: true,
                        id: "doesContainPii"
                    }
                ]}
            />
            <SelectableDetail
                title="Protected Health Information* (Required)"
                passedKey="containsPhi"
                value={selectedPhinPiiValue("containsPhi")}
                onValueUpdated={(value) => {
                    selectableValueUpdate("containsPhi", value);
                }}
                options={[
                    {
                        text: "Does Not Contain PHI",
                        value: false,
                        id: "doesNotContainPhi"
                    },
                    {
                        text: "Contains PHI",
                        value: true,
                        id: "doesContainPhi"
                    }
                ]}
            />
        </>
    );
};
