import React from "react";
import { EXPORTSIZE, ROLE_DISPLAY_CONVERSION } from "../../constants/index.js";
import { formatTimeValue, isUndefinedOrNull } from "../../utils/functions.js";
import { safeJSONParse } from "../../utils/index.js";
import { CsvExport } from "../common/CsvExport/index.js";

const safeObjReduceToString = (data) => {
    try {
        if (!data) {
            return "";
        } else {
            return Object.keys(data).map((key) => {
                const value = data[key];
                if (!isUndefinedOrNull(value)) {
                    return `${key}: ${value}`;
                }
            }).filter(_ => _).join(", ");
        }
    } catch (err) {
        console.log("Error converting data for export");
        console.log(err);
        return "";
    }
};

const transformDataForChangelogs = (data, options) => {

    const { subjectTransform, subjectHeader, suppressDrilldown, suppressDrilldownActions } = options;

    const subjectKey = subjectHeader?.key;
    const changelogs = data?.changelogs ?? [];

    return changelogs.map(changelog => {
        const createdAt = formatTimeValue(changelog?.createdAt, true);
        const roleSpecifier = ROLE_DISPLAY_CONVERSION[changelog?.roleSpecifier];
        const data = changelog?.data;

        const transformedData = {
            ...changelog,
            createdAt, 
            username: changelog?.user?.name,
            ncid: changelog?.user?.ncid,
            roleSpecifier
        };

        if (typeof subjectTransform === "function" && subjectKey && typeof subjectKey === "string") {
            const subject = subjectTransform(changelog);
            transformedData[subjectKey] = subject;
        }

        if (suppressDrilldown !== true && data) {
            if (suppressDrilldownActions.length === 0 || !suppressDrilldownActions.includes(changelog?.action)) {
                const dataToUse = safeJSONParse(data);
                if (dataToUse) {
                    const { type, previous, updated } = dataToUse;
    
                    return {
                        ...transformedData,
                        type: type ?? "", 
                        previous: safeObjReduceToString(previous),
                        updated: safeObjReduceToString(updated)
                    };
                }
            } 
        }

        return transformedData;
    });
};

const getHeadersForChangelogs = (data, subjectHeader, subjectTransform, suppressDrilldown) => {
    const csvHeaders = [
        { label: "User", key: "username" },
        { label: "NCID", key: "ncid" },
        { label: "Role", key: "roleSpecifier" },
        { label: "Date", key: "createdAt" },
        { label: "Action", key: "action" },
        { label: "Action Details", key: "description"}
    ];

    let subjectKey = null;

    if (subjectHeader && typeof subjectHeader === "object" && typeof subjectTransform === "function") {
        const subjectLabel = subjectHeader?.label;
        subjectKey = subjectHeader?.key;
        if (subjectLabel && subjectKey) {
            csvHeaders.unshift({ 
                label: subjectLabel ?? "", 
                key: subjectKey ?? ""
            });
        }
    }

    const changelogs = data?.changelogs ?? [];
    const containsExpandedData = suppressDrilldown !== true && changelogs.some(changelog => changelog?.data);

    return containsExpandedData  
        ? csvHeaders.concat([
            { label: "Item Changed", key: "type" },
            { label: "Previous", key: "previous"},
            { label: "Updated", key: "updated"}
        ]) 
        : csvHeaders; 
};


export const ChangelogsExport = ({ 
    query, 
    variables, 
    filename, 
    subjectHeader, 
    subjectTransform, 
    suppressDrilldown = false,
    suppressDrilldownActions = []
}) => {

    return <CsvExport
        csvFilename={filename && typeof filename === "string" ? filename : "Changelogs.csv"}
        csvHeadersGetter={(data) => getHeadersForChangelogs(data, subjectHeader, subjectTransform, suppressDrilldown)}
        customMessage={"The data you downloaded has been limited to the maximum export size of 1000 changelogs, with data for related changes."}
        query={query}
        variables={{...variables, offset: 0, limit: EXPORTSIZE }}
        transformData={(data) => transformDataForChangelogs(data, {
            subjectTransform, 
            subjectHeader, 
            suppressDrilldown,
            suppressDrilldownActions 
        })}
    />;
};
