import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { Button, Modal } from "antd";
import { Table } from "../common/Table/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import {
    ROLE_DISPLAY_CONVERSION,
    REVIEWER_ROLES
} from "../../constants/index.js";
import {
    handleMutation
} from "../../utils/errorHandling.js";
import {
    CREATE_ASSIGNMENTS, 
    DELETE_ASSIGNMENT
} from "../../constants/mutations.js";
import { FilterIcon } from "../common/FilterIcon/index.js";
import { PCDULink } from "../common/PCDULink/index.js";


const isAlreadyAssigned = (submissionTypeId, exclusions = []) => {
    return exclusions.some(exclusion => exclusion.submissionTypeId === submissionTypeId);
};

const includeSubmissionTypeForRole = ({ id, category, hasPrimaryReviewer }, selectedRole, exclusions) => {
    if (!selectedRole) {
        return true;
    } else if (isAlreadyAssigned(id, exclusions)) {
        return false;
    } else {
        if (selectedRole === ROLE_DISPLAY_CONVERSION.PRIMARY_REVIEWER && hasPrimaryReviewer) {
            return false;
        }
        if (category.secondaryReviewerFlag === true) {
            return selectedRole === ROLE_DISPLAY_CONVERSION.PRIMARY_REVIEWER || selectedRole === ROLE_DISPLAY_CONVERSION.SECONDARY_REVIEWER;
        }
        if (category.secondaryNotifierFlag === true) {
            return selectedRole === ROLE_DISPLAY_CONVERSION.PRIMARY_REVIEWER || selectedRole === ROLE_DISPLAY_CONVERSION.SECONDARY_NOTIFIER;
        }
        return true;
    }
};

const getMessage = (selectedRole) => {
    switch (selectedRole) {
        case ROLE_DISPLAY_CONVERSION.PRIMARY_REVIEWER:
            return "Excluding submission types that already have a primary reviewer";
        default:
            return "";
    }
};

export const UserSubmissionTypeRoles = (({
    newBreadcrumbs,
    userDetails,
    assignmentData,
    creating,
    refetch
}) => {

    const {
        userPermAssignReviewerandRole
    } = useContext(UserContext);

    const [modified, setModified] = useState({});
    const [createAssignmentsVis, setCreateAssignmentsVis] = useState(false);
    const [createAssignments] = useMutation(CREATE_ASSIGNMENTS);
    const [deleteAssignment] = useMutation(DELETE_ASSIGNMENT);
    const [addRoleProcessing, setAddRoleProcessing] = useState(false);

    const { confirm } = Modal;

    const submissionTypes = assignmentData?.data?.submissionTypesList?.submissionTypes ?? [];

    const roles = assignmentData?.data?.roles;

    const userName = (
        typeof modified?.name === "undefined"
    ) ? (
            typeof userDetails?.name === "undefined" ? "this user" : userDetails.name
        ) : modified.name;
    const reviewerRoles = roles?.filter((role) => REVIEWER_ROLES[role.specifier.toUpperCase()]);
    const rawRole = reviewerRoles?.find?.(role => role?.id === modified?.roleId)?.specifier;
    
    const selectedRole = ROLE_DISPLAY_CONVERSION[rawRole] ?? null;
    const message = getMessage(selectedRole);

    const subAssignments = Array.isArray(userDetails?.assignments) ? userDetails?.assignments.filter(
        (assignment) => assignment.node?.__typename === "SubmissionType"
    ) : [];

    const newAssignmentExclusions = subAssignments ? subAssignments.map((assignment) => {
        return {
            submissionTypeId: assignment?.node?.id,
            roleId: assignment?.role?.id
        };
    }) : [];

    const showConfirmAssignment = (id, assignmentType) => {
        confirm({
            title:
                `Are you sure you want to remove this ${assignmentType} assignment from this user?`,
            okText: "Yes, remove",
            async onOk() {
                await handleMutation(
                    deleteAssignment({
                        variables: { id }
                    })
                );
                setModified({});
                refetch();
            }
        });
    };

    const submissionTypeAssignmentModal = () => {
        return (
            <Modal
                title={`Assign Reviews to ${userName}`}
                visible={createAssignmentsVis}
                destroyOnClose={true}
                maskClosable={false}
                onOk={async () => {
                    try {
                        setAddRoleProcessing(true);
                        const newAssignments = modified.nodeIds.map((id) => {
                            return {
                                nodeId: id,
                                assigneeId: userDetails.id,
                                roleId: modified.roleId
                            };
                        });
    
                        await handleMutation(
                            createAssignments({
                                variables: {
                                    newAssignments
                                }
                            })
                        );
    
                        refetch();
                        setCreateAssignmentsVis(false);
                        setModified({});
                    } finally {
                        setAddRoleProcessing(false);
                    }
                }}
                okText="Save"
                okButtonProps={{
                    loading: addRoleProcessing,
                    disabled:
                        typeof modified.roleId === "undefined" ||
                        typeof modified.nodeIds === "undefined" ||
                        modified.nodeIds.length === 0
                }}
                cancelButtonProps={{
                    disabled: addRoleProcessing
                }}
                onCancel={() => {
                    setModified({});
                    setCreateAssignmentsVis(false);
                }}
            >
                <SelectableDetail
                    title="Role"
                    passedKey="roleId"
                    multiple={false}
                    value={modified.roleId}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            nodeIds: [],
                            roleId: value
                        });
                    }}
                    options={reviewerRoles?.map(({ id, specifier }) => {
                        return {
                            text: ROLE_DISPLAY_CONVERSION[specifier],
                            value: id,
                            key: id
                        };
                    })}
                />
                {selectedRole &&
                    <div>
                        <SelectableDetail
                            title="Submission Types"
                            passedKey="nodeIds"
                            multiple={true}
                            strict={true}
                            value={modified?.nodeIds ?? []}
                            onValueUpdated={(value) => {
                                setModified({
                                    ...modified,
                                    nodeIds: [...value]
                                });
                            }}
                            options={submissionTypes?.reduce((filteredArray, st) => {
                                if (includeSubmissionTypeForRole(st, selectedRole, newAssignmentExclusions)) {
                                    const { id, name, specifier } = st;
                                    filteredArray.push({
                                        text: `${specifier} - ${name}`,
                                        value: id,
                                        key: id
                                    });
                                }
                                return filteredArray;
                            }, [])}
                        />
                        <br />
                        { message 
                            ? <div>
                                <span style={{ fontWeight: "bold" }}><strong>Note</strong></span>
                                <br />
                                <span>{getMessage(selectedRole)}</span>
                            </div>
                            : null 
                        }
                    </div>}
            </Modal>
        );
    };

    let submissionTypeTableColumn = [
        {
            title: "Submission Type",
            dataIndex: "node",
            key: "sub-type",
            width: 200,
            sorter: (a, b) => a.node.specifier.localeCompare(b.node.specifier),
            sortDirections: ["ascend", "descend", "ascend"],
            render: ({ id, name, specifier }) => {
                return (
                    <PCDULink 
                        to={`/submission-types/${id}`}
                        newBreadcrumbs={newBreadcrumbs}
                    >
                        {specifier} - {name}
                    </PCDULink>
                );
            },
            filters: subAssignments?.map(({ node: { id, name } }) => {
                return {
                    text: name,
                    value: id
                };
            }),
            onFilter: (value, record) => (record.node.id === value),
            filterIcon: FilterIcon
        },
        {
            title: "Role",
            dataIndex: ["role", "specifier"],
            key: "role",
            width: 200,
            sorter: (a, b) => a.role.specifier.localeCompare(b.role.specifier),
            sortDirections: ["ascend", "descend", "ascend"],
            filters: [
                {
                    text: "Primary Reviewer",
                    value: "PRIMARY_REVIEWER"
                },
                {
                    text: "Secondary Reviewer",
                    value: "SECONDARY_REVIEWER"
                },
                {
                    text: "Secondary Notifier",
                    value: "SECONDARY_NOTIFIER"
                }
            ],
            onFilter: (value, record) => record.role.specifier === value,
            filterIcon: FilterIcon,
            render: (specifier) => ROLE_DISPLAY_CONVERSION[specifier]
        }
    ];
    
    if (userPermAssignReviewerandRole) {
        submissionTypeTableColumn.push({
            title: "Actions",
            key: "actions",
            width: "10%",
            render: ({ id }) => {
                return (
                    <Button
                        type="danger"
                        size="small"
                        onClick={() => {
                            showConfirmAssignment(id, "submission type review");
                        }}
                    >
                        Delete
                    </Button>
                );
            }
        });
    }

    const assignmentsTable = (
        <>
            <h2>Submission Type Assignments</h2>
            <Button
                style={{ display: userPermAssignReviewerandRole && !creating ? "inherit" : "none" }}
                size="small"
                id="createAssignments"
                className="spaceBelow-xs btn btn-sm btn-primary"
                onClick={() => setCreateAssignmentsVis(true)}
            >
                Add
            </Button>
            <Table
                id="assignmentsTable"
                rowKey="id"
                pagination={true}
                columns={submissionTypeTableColumn}
                dataSource={subAssignments}
            />
            
        </>
    );
    return (
        <>
            {assignmentsTable}
            {submissionTypeAssignmentModal()}
        </>
    );
});