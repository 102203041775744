import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import { Button, Modal, Spin, Divider } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { UserContext } from "../Application/UserContext.js";
import { LIBRARY_BASE_FOLDER } from "../../constants/index.js";
import { Main } from "../common/Main/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { CREATE_LIBRARY_DOCUMENT, CREATE_LIBRARY_FOLDER } from "../../constants/mutations.js";
import { handleGraphQLError, handleMutation, FILE_UPLOAD_ERROR_MSG } from "../../utils/errorHandling.js";
import { DocumentUploadModal } from "../common/DocumentUploadModal/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { LibraryFoldersTable } from "./library-folders-table.js";
import { LibraryDocumentsTable } from "./library-documents-table.js";
import { LibraryQuery } from "./queries.js";
import { LIBRARY_FILE_EXTENTION_ARRAY } from "../../utils/index.js";

export const Library = () => {
    const params = useParams();
    const location = useLocation();
    const [modified, setModified] = useState({});
    const [uploadLibraryModal, setUploadLibraryModal] = useState(false);
    const [uploadFolderModal, setUploadFolderModal] = useState(false);
    const [createLibraryFiles, setCreateLibraryFiles] = useState({});
    const [currentFolderName, setCurrentFolderName] = useState(location?.state?.currentFolderName ?? "");
    const [currentFolderId, setCurrentFolderId] = useState(location?.state?.currentFolderId ?? "");
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [refetchFolders, setRefetchFolders] = useState(false);
    const [refetchDocuments, setRefetchDocuments] = useState(false);
    const [createLibraryDocument, createLibraryDocumentProgress] = useMutation(CREATE_LIBRARY_DOCUMENT);
    const [createLibraryFolder] = useMutation(CREATE_LIBRARY_FOLDER);

    const {
        userPermUploadnModifyLibDoc,
        userPermViewLibDoc,
        userPermViewAssociatedLibDoc
    } = useContext(UserContext);

    const userHasPermission = userPermViewLibDoc || userPermViewAssociatedLibDoc;

    const baseFolderId = params.baseFolderId;
    const contractTypeId = params.contractTypeId;
    const paramsBaseFolderName = params.baseFolderName;
    const libraryFolderId = currentFolderId ? currentFolderId : baseFolderId;

    const resetStateToBaseFolder = () => {
        setCurrentFolderName("");
        setCurrentFolderId("");
    };

    const resetStateOnFolderChange = () => {
        setModified({});
        setCreateLibraryFiles({});
        setSelectedCategoryId("");
    };

    useEffect(() => {
        const isBaseFolder = location.state?.isBaseFolder ?? false;
        if (isBaseFolder) {
            resetStateToBaseFolder();
        }
        if (location?.state?.clearOut) {
            resetStateOnFolderChange();
        }
    }, [location.state]);

    useEffect(() => {
        resetStateOnFolderChange();
    }, [currentFolderId]);

    const { loading, error, data, refetch } = useQuery(
        LibraryQuery,
        {
            variables: {
                libraryFolderId,
                contractTypeId
            },
            fetchPolicy: "no-cache"
        }
    );

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading && typeof data === "undefined") {
        return <LoadingContent delay={600} />;
    }

    if (!userHasPermission) {
        return <NoPermission />;
    }

    const contractType = data?.contractTypes?.find?.(({ id }) => id === params.contractTypeId);
    const baseFolderName = data?.libraryBaseFolder.find(({ name }) => name === paramsBaseFolderName)?.name;
    const goodParams = contractType && baseFolderName;
    const isFolderActive = data?.libraryFolderInfo?.active ?? true; 
    const folderPathway = data?.libraryFolderPathway ?? [];

    if (!goodParams) {
        return <NoContent />;
    }

    const uploadDocument = (
        <>
            <Modal
                title="Upload Library Document"
                destroyOnClose={true}
                maskClosable={false}
                closable={false}
                visible={uploadLibraryModal}
                okText="Save"
                onCancel={() => {
                    setModified({
                        ...modified
                    });
                    setUploadLibraryModal(false);
                    setCreateLibraryFiles({});
                }}
                okButtonProps={{
                    disabled: Object.keys(createLibraryFiles).length === 0 || createLibraryDocumentProgress.loading || createLibraryFiles.size === 0
                }}
                cancelButtonProps={{
                    disabled: createLibraryDocumentProgress.loading
                }}
                onOk={async (e) => {
                    e.preventDefault();

                    const LibraryDocumentInput = {
                        documentName: createLibraryFiles.name,
                        fileSize: (createLibraryFiles.size).toString(),
                        libraryFolderId,
                        contractTypeId: contractTypeId,
                        baseFolderName: baseFolderName,
                        file: createLibraryFiles.originFileObj
                    };
                    await handleMutation(
                        createLibraryDocument({
                            variables: {
                                libraryDocumentInput: LibraryDocumentInput
                            }
                        })
                    );
                    setUploadLibraryModal(false);
                    setCreateLibraryFiles({});
                    setModified({});
                    setRefetchDocuments(true);
                }}
            >
                <Spin size="large" spinning={createLibraryDocumentProgress.loading}>
                    <DocumentUploadModal
                        uploadFileMetaData={createLibraryFiles}
                        setUploadFileMetaData={setCreateLibraryFiles}
                        maxDocumentCount={1}
                        acceptingFileTypeList={LIBRARY_FILE_EXTENTION_ARRAY}
                    />
                </Spin>
                {Object.keys(createLibraryFiles).length != 0 && createLibraryFiles.size === 0 && (
                    <span style={{
                        marginTop: "10px",
                        color: "red",
                        fontStyle: "italic"
                    }}>
                        <CloseCircleTwoTone twoToneColor="red" /> {FILE_UPLOAD_ERROR_MSG}
                    </span>
                )}
            </Modal>
            {isFolderActive && (<Button
                type="primary"
                size="small"
                style={{
                    display: userPermUploadnModifyLibDoc ? "inline-block" : "none"
                }}
                onClick={() => {
                    setUploadLibraryModal(true);
                }}
            >
                Upload 
            </Button>
            )}
        </>
    );
    
    const atBase = libraryFolderId === baseFolderId;
    const restrictToCategories = baseFolderName === LIBRARY_BASE_FOLDER.guidance_documents || baseFolderName === LIBRARY_BASE_FOLDER.report_templates;
    const allCategories = data?.contractType?.categories ?? [];
    const libraryFolders = data?.libraryFolders ?? [];
    const categories = allCategories.filter(({ name, deleted }) => !deleted && !libraryFolders.some(folder => folder.name === name || folder?.contractTypeCategory?.name === name));
    const removeFolderDisplay = !atBase && restrictToCategories;
    
    const categoryOptions = data.libraryExclusions.map(({ name, id }) => {
        return {
            text: name,
            value: id,
            id
        };
    });

    const createNewFolder = (
        <>
            <Modal
                title="Create Folder"
                destroyOnClose={true && !removeFolderDisplay}
                visible={uploadFolderModal}
                okText="Save"
                onCancel={() => {
                    setModified({
                        ...modified
                    });
                    setUploadFolderModal(false);
                    setSelectedCategoryId("");
                }}
                okButtonProps={{
                    disabled: Object.keys(modified).length === 0
                }}
                onOk={async (e) => {
                    e.preventDefault();

                    const LibraryFolderInput = {
                        folderName: modified.libraryFolderName,
                        contractTypeId: contractTypeId,
                        baseFolderName: baseFolderName,
                        baseFolderId,
                        libraryFolderId
                    };
                    await handleMutation(
                        createLibraryFolder({
                            variables: {
                                libraryFolderInput: LibraryFolderInput
                            }
                        })
                    );
                    setUploadFolderModal(false);
                    setModified({});
                    setSelectedCategoryId("");
                    setRefetchFolders(true);
                    refetch();
                }}
            >
                {restrictToCategories
                    ? <SelectableDetail
                        title="Select Name from Available Categories"
                        passedKey="select-folder-name-ct-category"
                        strict={true}
                        value={selectedCategoryId}
                        onValueUpdated={(id) => {
                            setSelectedCategoryId(id);
                            const catName = categories.find(category => category.id === id)?.name ?? "";
                            if (catName) {
                                setModified({
                                    ...modified,
                                    libraryFolderName: catName
                                });
                            }                                                  
                        }}
                        options={categoryOptions}
                    />
                    : <EditableDetail
                        title="Folder Name"
                        key="libraryFolderName"
                        placeholder="Add new folder name ..."
                        value={modified.libraryFolderName}
                        onValueUpdated={(e) => {
                            setModified({
                                ...modified,
                                libraryFolderName: e.target.value
                            });
                        }}
                    />
                }
            </Modal>
            {!removeFolderDisplay && isFolderActive && <Button
                type="primary"
                size="small"
                style={{
                    display: userPermUploadnModifyLibDoc ? "inline-block" : "none"
                }}
                onClick={() => {
                    setUploadFolderModal(true);
                    setSelectedCategoryId("");
                }}
                disabled={restrictToCategories && categories.length < 1}
            >
                Create Folder
            </Button>}
        </>
    );

    const breadCrumbFolderSelected = folderPathway.map(({name, id}) => {
        return (
            <PCDULink
                key={`breadcrumb-folder-${id}`}
                to={{}}
                onClick={() => {
                    setCurrentFolderName(name);
                    setCurrentFolderId(id);
                }}
            > 
                {name} 
            </PCDULink>
        );
    });

    const subtitleString = !currentFolderName ? "" : ` Folder Selected - ${isFolderActive ? currentFolderName : `${currentFolderName} (Archived)`} `;

    return (
        <Main
            title={`${contractType.name}  - ${baseFolderName}`}
            breadcrumbs={
                [
                    "Library Documents",
                    contractType.name,
                    <PCDULink
                        key="libDocumentBaseFolder"
                        to={{
                            pathname: location.pathname,
                            state: {
                                isBaseFolder: true
                            }

                        }}
                        onClick={() => {
                            resetStateToBaseFolder();
                        }}
                        tooltip={`Library Folder: ${baseFolderName}`}
                    >
                        {baseFolderName}
                    </PCDULink>,
                    ...breadCrumbFolderSelected
                ]
            }
        >
            <div>
                <div
                    key="libraryDocumentContent"
                >
                    {!removeFolderDisplay && 
                        <LibraryFoldersTable 
                            createFolderButton={createNewFolder}
                            subtitle={subtitleString}
                            isActive={isFolderActive}
                            contractTypeId={contractTypeId}
                            libraryFolderId={libraryFolderId}
                            setCurrentFolderName={setCurrentFolderName}
                            setCurrentFolderId={setCurrentFolderId}
                            locationState={location?.state}
                            refetchFolders={refetchFolders}
                            setRefetchFolders={setRefetchFolders}
                        />
                    } 
                    {!location.state?.isBaseFolder && !atBase && (
                        <>
                            {!removeFolderDisplay && <Divider />}
                            <LibraryDocumentsTable 
                                uploadLibraryDocumentButton={uploadDocument}
                                subtitle={subtitleString}
                                locationState={location?.state}
                                refetchDocuments={refetchDocuments}
                                setRefetchDocuments={setRefetchDocuments}
                                contractTypeId={contractTypeId}
                                libraryFolderId={libraryFolderId}
                                inArchivedFolder={!isFolderActive}
                            />
                        </>
                    )}
                </div>
            </div> 
        </Main>
    );
};