import { gql } from "@apollo/client";

export const submissionsQuery = gql`
    query CalendarSubmissionsQuery (
        $submissionDueRange: [String] 
        $submissionTypesFilter: [ID]
        $organizationsFilter: [ID]
        $statusFilter: [String]
        $categoryFilter: [String]
        $reviewerFilter: ID
        $reviewerCurrentOnly: Boolean 
        $preSelectedStatusFilter: Boolean
        $offset: Int 
        $limit: Int 
        $sortOn: String 
        $sortBy: String
    ) {
        submissionsList(
            statusFilter: $statusFilter
            submissionDueRange: $submissionDueRange
            organizationsFilter: $organizationsFilter
            submissionTypesFilter: $submissionTypesFilter
            categoryFilter: $categoryFilter, 
            reviewerFilter: $reviewerFilter,
            reviewerCurrentOnly: $reviewerCurrentOnly,
            preSelectedStatusFilter: $preSelectedStatusFilter,
            offset: $offset
            limit: $limit
            sortOn: $sortOn
            sortBy: $sortBy
        ) {
            count 
            submissions {
                id
                specifier
                createdAt
                status 
                latestVersionCreatedAt 
                reviewerDueDate
                submitterDueDate
                initialSubmitterDueDate
                submissionNewestVersion {
                    id
                }
                contract {
                        id
                        name
                        contractType {
                            name
                        }
                }
                submissionType {
                        id
                        specifier
                        name
                        daysToReview
                        daysToReSubmit
                        useAttestation
                }
                submitter {
                    __typename
                    ... on Organization {
                        id
                        name
                    }
                }
                comments {
                    content
                    previousDueDate
                    updatedDueDate
                    createdAt
                    modifiedAt
                    author {
                        name
                        organizations {
                            id 
                            name 
                        }
                    }
                }
            }
        }
    }
`;

export const toDosQuery = gql`
    query ToDosQuery (
        $submissionTypesFilter: [ID]
        $organizationsFilter: [ID]
        $dateRangeFilter: [String]
        $categoryFilter: [String]
        $offset: Int 
        $limit: Int 
        $sortOn: String 
        $sortBy: String 
    ) {
        toDos: optimizedToDos(
            submissionTypesFilter: $submissionTypesFilter
            organizationsFilter: $organizationsFilter
            dateRangeFilter: $dateRangeFilter
            categoryFilter: $categoryFilter 
            offset: $offset 
            limit: $limit 
            sortOn: $sortOn 
            sortBy: $sortBy 
        )
    }
`;

export const calendarQuery = gql`
    query CalendarQuery (
        $submissionDueRange: [String] 
        $submissionTypesFilter: [ID]
        $organizationsFilter: [ID]
        $statusFilter: [String]
        $toDoDateRangeFilter: [String]
        $categoryFilter: [String]
        $yearView: Boolean 
    ) {
        calendar (
            statusFilter: $statusFilter
            submissionDueRange: $submissionDueRange
            organizationsFilter: $organizationsFilter
            submissionTypesFilter: $submissionTypesFilter
            categoryFilter: $categoryFilter
            dateRangeFilter: $toDoDateRangeFilter 
            yearView: $yearView 
            limit: 365 
            offset: 0  
        ) {
            submissionsCount 
            submissions {
                id
                specifier
                createdAt
                status
                reviewerDueDate
                submitterDueDate
                contract {
                        id
                        name
                        contractType {
                            name
                        }
                }
                submissionType {
                        id
                        specifier
                        name
                        daysToReview
                        daysToReSubmit
                        useAttestation
                        categoryName
                }
                submitter {
                    __typename
                    ... on Organization {
                        id
                        name
                    }
                } 
            } 
            toDosCount 
            toDos {
                key 
                contractId
                obligationId
                timelineId
                contractTypeId
                promisorId
                submissionTypeId
                currentDueDate
                contractName
                contractStartDate
                contractTypeName
                promisorName
                submissionTypeSpecifier
                submissionTypeName
                submissionTypeDaysToReview
                submissionTypeDaysToResubmit
                submissionTypeUseAttestation
                submissionTypePackagedSubmission
                categoryName
            }
        }
    }
`;

export const dateDetailQuery = gql`
    query DateDetailQuery (
        $submissionTypesFilter: [ID]
        $statusFilter: [String]
        $organizationsFilter: [ID]
        $toDoDateRangeFilter: [String]
        $categoryFilter: [String]
        $offsetSubmissions: Int 
        $limitSubmissions: Int 
        $offsetToDos: Int 
        $limitToDos: Int 
        $sortOn: String 
        $sortBy: String 
    ) {
        toDos(
            submissionTypesFilter: $submissionTypesFilter
            organizationsFilter: $organizationsFilter
            dateRangeFilter: $toDoDateRangeFilter
            categoryFilter: $categoryFilter 
            offset: $offsetToDos
            limit: $limitToDos 
            sortOn: $sortOn 
            sortBy: $sortBy 
        ) {
            key 
            count 
            contractId
            obligationId
            timelineId
            contractTypeId
            promisorId
            submissionTypeId
            currentDueDate
            contractName
            contractStartDate
            contractTypeName
            promisorName
            submissionTypeSpecifier
            submissionTypeName
            submissionTypeDaysToReview
            submissionTypeDaysToResubmit
            submissionTypeUseAttestation
            submissionTypePackagedSubmission
            categoryName
            documentTypes {
                id 
                name 
                specifier 
            }
        }
        submissionsList (
            statusFilter: $statusFilter 
            submissionTypesFilter: $submissionTypesFilter
            organizationsFilter: $organizationsFilter
            submissionDueRange: $toDoDateRangeFilter
            categoryFilter: $categoryFilter 
            offset: $offsetSubmissions 
            limit: $limitSubmissions 
        ) {
            count 
            submissions {
                id
                specifier
                createdAt
                status
                reviewerDueDate
                submitterDueDate
                contract {
                        id
                        name
                        contractType {
                            name
                        }
                }
                submissionType {
                        id
                        specifier
                        name
                        daysToReview
                        daysToReSubmit
                        useAttestation
                        categoryName
                }
                submitter {
                    __typename
                    ... on Organization {
                        id
                        name
                    }
                } 
            } 
        }
    }
`;
