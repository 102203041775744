import React, { useState } from "react";
import { Button, Modal, Spin } from "antd";
import { useAttemptToModifyOrganization } from "../../utils/attemptMutationHooks.js";
import { isUndefinedOrNull } from "../../utils/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { Table } from "../common/Table/index.js";
import { PCDULink } from "../common/PCDULink/index.js";


export const PrimaryContactTable = ({
    refetch, 
    organization, 
    activeMembers, 
    canEdit, 
    newBreadcrumb 
}) => {
    const [modified, setModified] = useState({});
    const [changePrimaryContactVis, setChangePrimaryContactVis] = useState(false);
    const [attemptToModifyOrganization, { loading: modifyOrgLoading }] = useAttemptToModifyOrganization();

    return (
        <>
            <h2>Primary Contact</h2>
            <Button
                style={{ display: canEdit ? "inherit" : "none" }}
                size="small"
                id="changePrimaryContact"
                className="spaceBelow-xs btn btn-sm btn-primary"
                onClick={() => {
                    setChangePrimaryContactVis(true);
                }}
            >
                Change
            </Button>
            <Table
                id="primaryContactTable"
                rowKey="name"
                columns={[
                    {
                        title: "Name",
                        key: "name",
                        width: 200,
                        render: ({ id, name }) => <PCDULink 
                            to={`/users/${id}`}
                            newBreadcrumbs={newBreadcrumb}
                        > 
                            {name} 
                        </PCDULink>
                    },
                    {
                        title: "Email",
                        key: "email",
                        dataIndex: "email",
                        width: 200
                    }
                ]}
                dataSource={
                    isUndefinedOrNull(organization.primaryContact)
                        ? []
                        : [organization.primaryContact]
                }
            />
            <Modal
                title="Change Primary Contact"
                visible={changePrimaryContactVis}
                onOk={async (e) => {
                    e.preventDefault();
                    const { success } = await attemptToModifyOrganization({
                        variables: {
                            id: organization.id,
                            newOrganization: {
                                primaryContactId: modified.primaryContactId
                            }
                        }
                    });

                    if (success) {
                        refetch();
                        setChangePrimaryContactVis(false);
                        setModified({});
                    }
                }}
                okText="Save"
                okButtonProps={{
                    disabled: typeof modified.primaryContactId === "undefined"
                }}
                onCancel={() => {
                    setModified({});
                    setChangePrimaryContactVis(false);
                }}
            >
                <Spin size="large" spinning={modifyOrgLoading}>
                    <SelectableDetail
                        title={`New Primary Contact for ${organization.specifier}`}
                        passedKey="primaryContactId"
                        value={modified.primaryContactId
                            ? modified.primaryContactId
                            : organization?.primaryContact?.id
                        }
                        onValueUpdated={(value) => {
                            setModified({
                                ...modified,
                                primaryContactId: value
                            });
                        }}
                        options={activeMembers.map(({ id, name, email }) => {
                            return {
                                id,
                                text: `${name} - ${email}`,
                                value: id
                            };
                        })}
                    />
                </Spin>
            </Modal>
        </>
    );
};