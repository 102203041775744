import React from "react";
import { PCDULink } from "../common/PCDULink/index.js";
import { LibraryChangelogsQuery } from "./queries.js";
import { ChangelogsTable } from "./changelogs-table.js";


export const LibraryChangelogs = (({  
    actions,
    roles,
    subjectData,
    setSubjectData 
}) => {

    const subjectBreadcrumb = {
        label: "Library Changelogs",
        path: "/changelogs/libraryFolder"
    };
    
    return (
        <div style={{minWidth: "900px"}}>
            <ChangelogsTable 
                subjectBreadcrumb={subjectBreadcrumb}
                subjectData={subjectData}
                setSubjectData={setSubjectData}
                filterOptions={{
                    actions,
                    roles
                }}
                exportFilename={"Library_Changelogs.csv"}
                query={LibraryChangelogsQuery}
                searchableSubject={false}
                suppressDrilldown={true}
                subjectColumn={
                    {
                        title: "Link to Folder",
                        dataIndex: ["subject", "name"],
                        sorter: false,
                        render: (_, { data }) => {
                            const jsonData = JSON.parse(data);
                            return jsonData.updated.baseFolderName 
                                ? (<>
                                    <PCDULink 
                                        newBreadcrumbs={[ 
                                            { label: "Changelogs", path: "/changelogs" }, 
                                            subjectBreadcrumb
                                        ]}
                                        to={{
                                            pathname: `/library/${jsonData.updated.contractType}/${jsonData.updated.baseFolderName}/${jsonData.updated.baseFolder}`,
                                            state: {
                                                currentFolderName: jsonData.updated.folderName,
                                                currentFolderId: jsonData.updated.folderId 
                                            }
                                        }}
                                    > 
                                        Go To Folder 
                                    </PCDULink> 
                                </>)
                                : null;
                        }
                    }
                }
            />
        </div>
    );
});