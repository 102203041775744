import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { Button, Modal } from "antd";
import { Table } from "../common/Table/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import {
    ROLE_DISPLAY_CONVERSION,
    TRUE_FALSE_OPTIONS,
    DHHS_ORRGANIZATION_ROLES,
    SERVIVE_ACCOUNT_ROLES,
    SUBMITTER
} from "../../constants/index.js";
import {
    handleMutation
} from "../../utils/errorHandling.js";
import {
    CREATE_ASSIGNMENTS,
    MODIFY_USER,
    DELETE_ASSIGNMENT
} from "../../constants/mutations.js";
import { FilterIcon } from "../common/FilterIcon/index.js";
import { PCDULink } from "../common/PCDULink/index.js";

export const UserRoleTable = (({
    newBreadcrumbs,
    userDetails,
    assignmentData,
    creating,
    refetch,
    attestationOptions
}) => {

    const [createOrganizationAssignVis, setOrganizationAssignVis] = useState(false);
    const [createAssignments] = useMutation(CREATE_ASSIGNMENTS);
    const { userPermAssignReviewerandRole } = useContext(UserContext);

    const [modified, setModified] = useState({});
    const [loading, setLoading] = useState(false);
    const [modifyUser] = useMutation(MODIFY_USER);
    const [deleteAssignment] = useMutation(DELETE_ASSIGNMENT);
    const assignableRoleSpecifiers = userDetails.serviceAccount ? SERVIVE_ACCOUNT_ROLES : userDetails?.isSubmitter
        ? [SUBMITTER]
        : DHHS_ORRGANIZATION_ROLES;
    
    const roles = assignmentData?.data?.roles;
    const submitter = SUBMITTER;
    const { confirm } = Modal;
    const existingAssignmentRoles = userDetails?.assignments?.map(({ role }) => role);

    const assignableRoles = roles?.filter((role) => {
        const isAlreadyAssigned = existingAssignmentRoles.some(({ id }) => role.id === id);
        return !isAlreadyAssigned && assignableRoleSpecifiers.includes(role.specifier.toUpperCase());
    });

    const orgAssignments = Array.isArray(userDetails?.assignments) ? userDetails?.assignments.filter(
        (assignment) => assignment.node?.__typename === "Organization"
    ) : [];
    const canAssignMoreRoles = (userDetails?.isSubmitter && orgAssignments.length > 0) ? false : true;

    const checkDuplicateRecord = (orgAssignments, newOrgAssignments) => {
        let result = orgAssignments.filter((assignment) => {
            return (assignment.node.id === newOrgAssignments.nodeId && assignment.role.id === newOrgAssignments.roleId);
        });

        return !(result.length > 0);
    };

    const modalError = () => {
        Modal.error({
            title: "Error",
            content: "Cannot save duplicate records."
        });
    };
    
    const showConfirmAssignment = (id, assignmentType) => {
        confirm({
            title:
                `Are you sure you want to remove this ${assignmentType} assignment from this user?`,
            okText: "Yes, remove",
            async onOk() {
                await handleMutation(
                    deleteAssignment({
                        variables: { id }
                    })
                );
                setModified({});
                refetch();
            }
        });
    };


    const createRoleModal = () => {

        return (
            <Modal
                title="Add Organization Role"
                visible={createOrganizationAssignVis}
                destroyOnClose={true}
                maskClosable={false}
                onOk={async () => {
                    try {
                        setLoading(true);
                        const userOrganization = userDetails?.memberships[0]?.organization?.id;                    
                        const newOrgData = {
                            roleId: modified.roleId,
                            assigneeId: userDetails.id,
                            nodeId: userOrganization
                        };
    
                        const newOrgAssignments = [newOrgData];
                        let isDuplicateRecords = checkDuplicateRecord(orgAssignments, newOrgData);
    
                        if (isDuplicateRecords) {
                            await handleMutation(
                                createAssignments({
                                    variables: {
                                        newAssignments: newOrgAssignments
                                    }
                                })
                            );
                        } else {
                            modalError();
                        }
    
                        if (modified.attestationFlag) {
                            const newUser = {
                                attestationFlag: true,
                                attestationRoleName: modified.additionalRoleId
                            };
    
                            await handleMutation(
                                modifyUser({
                                    variables: {
                                        id: userDetails.id,
                                        newUser
                                    }
                                })
                            );
                        } else {
                            const newUser = {
                                attestationFlag: false,
                                attestationRoleName: ROLE_DISPLAY_CONVERSION.SUBMITTER_NONE
                            };
    
                            await handleMutation(
                                modifyUser({
                                    variables: {
                                        id: userDetails.id,
                                        newUser
                                    }
                                })
                            );
                        }
    
                        await refetch();
                        setOrganizationAssignVis(false);
                        setModified({});
                    } finally {
                        setLoading(false);
                    }
                }}
                okText="Save"
                okButtonProps={{
                    loading,
                    disabled: !modified.roleId 
                }}
                cancelButtonProps={{
                    disabled: loading
                }}
                onCancel={() => {
                    setModified({});
                    setLoading(false);
                    setOrganizationAssignVis(false);
                }}
            >
                <SelectableDetail
                    title="Role"
                    passedKey="additionalRoleId"
                    multiple={false}
                    value={modified.roleId}
                    onValueUpdated={(value) => {
                        const showAttestationRole = assignableRoles?.filter(({ id }) => value == id)[0].specifier;
                        setModified({
                            ...modified,
                            roleId: value,
                            showAttestationRole: showAttestationRole,
                            attestationFlag: showAttestationRole === submitter ? false : undefined
                        });
                    }}
                    options={assignableRoles?.map(({ id, specifier }) => {
                        return {
                            text: ROLE_DISPLAY_CONVERSION[specifier],
                            value: id,
                            key: id
                        };
                    })}
                />
                {modified.showAttestationRole === submitter && (
                    <SelectableDetail
                        title="Submitter has Attestion Authority"
                        passedKey="attestationFlag"
                        multiple={false}
                        value={modified.attestationFlag}
                        onValueUpdated={(value) => {
                            setModified({
                                ...modified,
                                attestationFlag: value

                            });
                        }}
                        options={TRUE_FALSE_OPTIONS}
                    />
                )}
                {modified.attestationFlag && (
                    <SelectableDetail
                        title="Submitter Attestation Level"
                        passedKey="additionalSubmitterRoleId"
                        multiple={false}
                        value={modified.additionalRoleId}
                        onValueUpdated={(value) => {
                            setModified({
                                ...modified,
                                additionalRoleId: value
                            });
                        }}
                        options={attestationOptions}
                    />
                )}
            </Modal>
        );
    };
    let roleAssignmentTableColumn = [
        {
            title: "Organization",
            dataIndex: "node",
            key: "org",
            width: 200,
            sorter: (a, b) => a.node.name.localeCompare(b.node.name),
            sortDirections: ["ascend", "descend", "ascend"],
            render: ({ id, name }) => {
                return (
                    <PCDULink 
                        to={`/organizations/${id}`}
                        newBreadcrumbs={newBreadcrumbs}
                    >
                        {name}
                    </PCDULink>
                );
            },
            filters: orgAssignments.map(({ node: { id, name } }) => {
                return {
                    text: name,
                    value: id
                };
            }),
            onFilter: (value, record) => (record.node.id === value),
            filterIcon: FilterIcon
        },
        {
            title: "Role",
            dataIndex: ["role", "specifier"],
            key: "role-specifier",
            width: 200,
            sorter: (a, b) => a.role.specifier < b.role.specifier,
            sortDirections: ["ascend", "descend", "ascend"],
            filters: [
                {
                    text: "Read Only",
                    value: "READ_ONLY"
                },
                {
                    text: "Submitter",
                    value: "SUBMITTER"
                },
                {
                    text: "System Administrator",
                    value: "SYSTEM_ADMINISTRATOR"
                },
                {
                    text: "Contract Administrator",
                    value: "CONTRACT_ADMINISTRATOR"
                },
                {
                    text: "Internal Auditor",
                    value: "INTERNAL_AUDITOR"
                },
                {
                    text: "Super User",
                    value: "SUPER_USER"
                },
                {
                    text: "Security Administrator",
                    value: "SECURITY_ADMINSITRATOR"
                }

            ],
            onFilter: (value, record) => record.role.specifier === value,
            filterIcon: FilterIcon,
            render: (specifier) => ROLE_DISPLAY_CONVERSION[specifier]
        }
    ];

    if (userPermAssignReviewerandRole) {
        roleAssignmentTableColumn.push({
            title: "Actions",
            key: "actions",
            width: "10%",
            render: ({ id }) => {
                return (

                    <Button
                        type="danger"
                        size="small"
                        onClick={() => {
                            showConfirmAssignment(id, "additional role");
                        }}
                    >
                        Delete
                    </Button>
                );
            }
        });
    }
    
    
    const additionalRoleAssignment = (
        <>
            <h2>Role Assignments</h2>
            <Button
                style={{ display: userPermAssignReviewerandRole && canAssignMoreRoles ? "inherit" : "none" }}
                size="small"
                id="createAssignments"
                className="spaceBelow-xs btn btn-sm btn-primary"
                onClick={() => setOrganizationAssignVis(true)}
            >
                Add
            </Button>
            <Table
                rowKey="id"
                id="roleAssignmentsTable"
                pagination={{ hideOnSinglePage: true }}
                columns={roleAssignmentTableColumn}
                dataSource={orgAssignments}
            />            
        </>
    );

    return (
        <>
            {!creating && additionalRoleAssignment}
            {createRoleModal()}
        </>
    );
});