import React, { useState } from "react";
import { Modal, Spin } from "antd";
import { useMutation } from "@apollo/client";
import { CREATE_FEEDBACK_DOCUMENT, MODIFY_FEEDBACK_DOCUMENT } from "../../../constants/mutations.js";
import { handleMutation } from "../../../utils/errorHandling.js";
import { UploadFeedbackDocument } from "../../common/UploadFeedbackDocument/index.js";

// helper fn :: 
const isValidFeedbackDocumentUpload = (obj) => obj?.name && obj?.size && obj.originFileObj;


export const UploadDocumentModal = ({ 
    issueId, 
    modalIsOpen,
    setModalIsOpen,
    refetch,
    actionHappening,
    setActionHappening, 
    reuploadDocumentId,
    issueRefetch
}) => {
    const [uploadUnderway, setUploadUnderway] = useState(false);
    const [feedbackDocument, setFeedbackDocument] = useState({});
    const [createFeedbackDocument] = useMutation(CREATE_FEEDBACK_DOCUMENT);
    const [modifyFeedbackDocument] = useMutation(MODIFY_FEEDBACK_DOCUMENT);

    const forReupload = reuploadDocumentId && typeof reuploadDocumentId === "string";

    const modalTitle = forReupload 
        ? "Reupload Feedback Document" 
        : "Upload Feedback Document"; 

    const handleFeedbackDocumentUpload = async () => {       
        await handleMutation(
            createFeedbackDocument({
                variables: {
                    issueId,
                    feedbackDocumentInput: {
                        name: feedbackDocument.name, 
                        size: feedbackDocument.size, 
                        file: feedbackDocument.originFileObj 
                    }
                }
            })
        );
    };

    const handleReupload = async () => {
        await handleMutation(
            modifyFeedbackDocument({
                variables: {
                    id: reuploadDocumentId,
                    feedbackDocumentInput: {
                        name: feedbackDocument.name, 
                        size: feedbackDocument.size, 
                        file: feedbackDocument.originFileObj,
                        active: true
                    }
                }
            })
        );
    };

    const onModalConfirm = async () => { 
        if (!actionHappening) {
            setActionHappening(true);
            setUploadUnderway(true); 
            if (forReupload) {
                await handleReupload();
            } else {
                await handleFeedbackDocumentUpload();
            }
            await refetch();
            if (issueRefetch) await issueRefetch();
            setActionHappening(false);
            setModalIsOpen(false); 
            setUploadUnderway(false); 
        }
    };

    return (
        <Modal 
            visible={modalIsOpen}
            maskClosable={false}
            destroyOnClose={true}
            loading 
            confirmLoading={uploadUnderway}
            okText="Save"
            okButtonProps={{
                disabled: !isValidFeedbackDocumentUpload(feedbackDocument)
            }}
            cancelButtonProps={{
                disabled: uploadUnderway
            }}
            closable={!uploadUnderway}
            afterClose= {()=> {
                setFeedbackDocument({});
            }}
            onCancel={() => {
                setModalIsOpen(false);
            }}
            onOk={onModalConfirm}
        >
            <Spin size="large" spinning={uploadUnderway}>
                {/* just for spacing */} 
                <div style={{ height: "20px" }}></div>
                <UploadFeedbackDocument 
                    title={modalTitle}
                    feedbackDocument={feedbackDocument}
                    setFeedbackDocument={setFeedbackDocument}
                />
            </Spin>
        </Modal>
    );
};
