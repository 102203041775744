import React, { useContext, useRef, useState, useEffect } from "react";
import { Col, Form, DatePicker, Row, Select, Checkbox } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { handleNotification } from "../common/CsvExport/exportNotifications.js";
import { auditReportQuery, lastExportedQuery } from "./queries.js";
import ExportTablesButtons from "./buttons.js";
import { CANCEL_AUDIT_REPORT_EXPORT } from "../../constants/mutations.js";
import { SubmissionIdInput } from "./submissionIdInput.js";
import { POLLING_DELAY } from "./__exportTableConstants.js";

const { RangePicker } = DatePicker;

const ALL_VALUE = "all";  // string value of the -- All -- option in the select inputs 
const NOTIFICATION_LENGTH_IN_SECONDS = 5;
const FORM_DATA_FIELDS = [
    "categoryFilter", 
    "organizationFilter", 
    "createdAtFilter", 
    "dueDateFilter",
    "primaryReviewerFilter",
    "submissionFilter",
    "submissionTypeFilter",
    "includeArchivedCriteriaFilter"
];


// helper function -- normalize request to backend by removing "all" option :: 
const constructVariablesObject = (formData, type) => {
    return FORM_DATA_FIELDS.reduce((variables, field) => {
        variables[field] = Array.isArray(formData[field]) ? formData[field].filter(value => value !== ALL_VALUE) : formData[field];
        return variables;
    }, { type });
};

const renderFormItem = (type, displayTypes, item, shouldRender = true) => shouldRender && displayTypes.includes(type) ? item : null;

const ExportTableForm = ({
    type,
    data,
    updateHeaderPanel
}) => {
    const {
        userIsAdmin,
        userIsPrimaryReviewer,
        userId 
    } = useContext(UserContext);

    const {
        allAuditReportTypes,
        categories,
        organizations,
        primaryReviewersList, 
        submissionTypes 
    } = data;
    
    const DEFAULT_FILTERS_DATA = {
        categoryFilter: type === allAuditReportTypes.reviewer ? [ALL_VALUE] : [],
        organizationFilter: [],
        createdAtFilter: [],
        dueDateFilter: [],
        primaryReviewerFilter: type === allAuditReportTypes.reviewer 
            ? userIsPrimaryReviewer ? [userId] : [ALL_VALUE]
            : [],
        submissionFilter: [],
        submissionTypeFilter: [],
        includeArchivedCriteriaFilter: false 
    };
    
    const formRef = useRef(null);
    const [exportId, setExportId] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [includeArchivedCriteria, setIncludeArchivedCriteria] = useState(false);
    const [triggerExport, { loading }] = useLazyQuery(auditReportQuery, { fetchPolicy: "no-cache" });
    const [triggerGetExportUpdates, { data: auditReportResponse, startPolling, stopPolling }] = useLazyQuery(lastExportedQuery, { fetchPolicy: "no-cache" });
    const [cancelAuditReportExport] = useMutation(CANCEL_AUDIT_REPORT_EXPORT);
    const [form] = Form.useForm();

    const resetRequest = () => {
        setButtonDisabled(false);
        stopPolling();
        setExportId();
        updateHeaderPanel({ loading: false });
    };

    useEffect(() => {
        if (data.exportTableRequestAllLogs) {
            const exportResponse = data.exportTableRequestAllLogs.find(({ type: report }) => report === type);
            if (exportResponse) {
                setButtonDisabled(!exportResponse.canExport);
                setExportId(exportResponse.id);
            }
        }
    }, [data.exportTableRequestAllLogs]);

    useEffect(() => {
        if (exportId) {
            if (auditReportResponse?.exportTableRequestLogs) {
                const canExport = auditReportResponse.exportTableRequestLogs[0].canExport;
                if (canExport) {
                    const lastExportedDate = auditReportResponse.exportTableRequestLogs[0].dateOfLastSuccessfulExport;
                    resetRequest();
                    updateHeaderPanel({ lastExportedDate, loading: false });
                }
            }
        }
    }, [auditReportResponse]);

    // Form helper functions 
    const getFieldValue = (fieldName) =>  Form.useWatch(fieldName, form);
    const filtersData = FORM_DATA_FIELDS.map(fieldName => {
        // normalize current value :: 
        return {
            currentValue: getFieldValue(fieldName) ?? DEFAULT_FILTERS_DATA[fieldName],
            defaultValue: DEFAULT_FILTERS_DATA[fieldName]
        };
    });
    
    const getExportRecordStatus = async (exportId) => {
        setExportId(exportId);
        await triggerGetExportUpdates({ variables: { exportId } });
        startPolling(POLLING_DELAY);
    };

    const cancelExportRequest = async () => {
        if (exportId) {
            const { data } = await handleMutation(
                cancelAuditReportExport({
                    variables: {
                        exportId
                    }
                }), {
                    returnResponse: true
                }
            );
            const message = data?.cancelAuditReportExport?.status;
            const requestLog = data?.cancelAuditReportExport?.requestLog;
            const wasCancelled = data?.cancelAuditReportExport?.wasCancelled;
            handleNotification(message, NOTIFICATION_LENGTH_IN_SECONDS, "success");
            if (wasCancelled === false) {
                // audit report has already been processed, so we need to update "Last Exported On" date :: 
                updateHeaderPanel({ lastExportedDate: requestLog.dateOfLastSuccessfulExport, loading: false });
            }
            resetRequest();
        }
    };

    // other helper functions ::
    const addAllToOptions = (options) =>
        [{ label: "-- All --", value: ALL_VALUE }].concat(options);
    const resetFilters = () => formRef.current.resetFields();
    const createOnChangeHandler = (formNamePath) => {
        return (newFilter) => {
            const lastAdded = newFilter[newFilter.length - 1];
            if (lastAdded === ALL_VALUE) {
                formRef.current.setFieldsValue({ [formNamePath]: [ALL_VALUE] });
            } else if (newFilter.length > 1) {
                formRef.current.setFieldsValue({
                    [formNamePath]: newFilter.filter((value) => value !== ALL_VALUE)
                });
            }
        };
    };

    const primaryReviewersFromForm = getFieldValue("primaryReviewerFilter");

    const REPORT_DESCRIPTION_BY_TYPE = {
        [allAuditReportTypes.admin]: "A report detailing active submissions by category type, allowing the user to review status, version, and version detail.",
        [allAuditReportTypes.reviewer]: "A report detailing submissions for Reviewer Reports, allowing the user to review status, version, and version detail.",
        [allAuditReportTypes.submitter]: "A report detailing active submissions for Submitter Reports, allowing the user to review status, version, and version detail.",
        [allAuditReportTypes.archived]: "A report detailing archived submissions.",
        [allAuditReportTypes.criteria]: "Export containing details on criteria attached to Submission Types.",
        [allAuditReportTypes.document]: "Export containing information about individual files submitted to PCDU including file names and other key information about the document/file.",
        [allAuditReportTypes.dueDate]: "A report detailing the next available Submission Due Date for a Submission Type (By Plan).",
        [allAuditReportTypes.issue]: "Export containing details on active issues and their affiliated submissions.",
        [allAuditReportTypes.submissionType]: "A detailed report showing Submission Types."
    };

    const description = REPORT_DESCRIPTION_BY_TYPE[type] ?? "";

    const categoryOptions = addAllToOptions(
        categories.map((category) => ({
            label: category.name,
            value: category.id
        }))
    );

    const organizationOptions = addAllToOptions(
        organizations.map((organization) => ({
            label: organization.name,
            value: organization.id
        }))
    );

    const primaryReviewerOptions = addAllToOptions(primaryReviewersList.map(user => ({
        label: user.name,
        value: user.id
    })));

    const getSubmissionTypeOptions = () => {        
        if (type === allAuditReportTypes.archived) {
            // Filter Submission Types based on the primary reviewer selected 
            const filteredSubmissionTypes = primaryReviewersFromForm?.includes(ALL_VALUE) 
                ? submissionTypes 
                : submissionTypes.filter((val) => {
                    return val.reviewerAssignments.some((val) => primaryReviewersFromForm?.includes(val.assignee.id));
                });

            return addAllToOptions(filteredSubmissionTypes.map(subType => ({
                label: subType.specifier,
                value: subType.id 
            })));
        } else {
            // All Submission Types 
            return addAllToOptions(submissionTypes.map(subType => ({
                label: subType.specifier,
                value: subType.id 
            })));
        }
    };

    useEffect(() => {
        if (allAuditReportTypes.archived) {
            if (form.isFieldTouched("primaryReviewerFilter")) {
                form.resetFields(["submissionTypeFilter"]);
            }
        }
    }, [primaryReviewersFromForm]);
    
    return (
        <div className="export-table-form-container">
            <Row>
                <Col span={24}>
                    <p> {description} </p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        ref={formRef}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        onFinish={async (values) => {
                            setButtonDisabled(true);
                            updateHeaderPanel({loading: true});
                            const { data: auditReportResponse, error } =
                                await triggerExport({
                                    variables: constructVariablesObject(values, type)
                                });
                            const wasSuccessful =
                                auditReportResponse?.auditReport?.success;
                            const notificationType = wasSuccessful
                                ? "success"
                                : "warning";
                            const message = auditReportResponse?.auditReport?.status;
                            // Start Polling
                            getExportRecordStatus(auditReportResponse?.auditReport?.exportId);

                            if (message) {
                                handleNotification(
                                    message,
                                    NOTIFICATION_LENGTH_IN_SECONDS,
                                    notificationType
                                );
                            } else if (error) {
                                handleNotification(
                                    "There was an error requesting the Audit Report",
                                    NOTIFICATION_LENGTH_IN_SECONDS,
                                    "error"
                                );
                            }
                        }}
                        onFinishFailed={() =>
                            handleNotification(
                                `Please make the required selections for ${type}`,
                                NOTIFICATION_LENGTH_IN_SECONDS,
                                "warning"
                            )
                        }
                    >
                        <Row gutter={16}>
                            {renderFormItem(
                                type, 
                                [
                                    allAuditReportTypes.admin,
                                    allAuditReportTypes.reviewer,
                                    allAuditReportTypes.submitter],
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label="Category Type"
                                        name="categoryFilter"
                                        rules={[{ required: true, message: "Please select categories" }]}
                                        initialValue={DEFAULT_FILTERS_DATA.categoryFilter}
                                    >
                                        <Select
                                            id={`${type} - categoryFilter`}
                                            onChange={createOnChangeHandler("categoryFilter")}
                                            options={categoryOptions}
                                            mode={"multiple"}
                                            placeholder="Please select categories"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? "")
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {renderFormItem(
                                type,
                                [allAuditReportTypes.reviewer, allAuditReportTypes.archived],
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label="Primary Reviewer"
                                        name="primaryReviewerFilter"
                                        rules={[{ required: true, message: "Please select primary reviewers" }]}
                                        initialValue={DEFAULT_FILTERS_DATA.primaryReviewerFilter}
                                    >
                                        <Select
                                            id={`${type} - categoryFilter`}
                                            onChange={createOnChangeHandler(
                                                "primaryReviewerFilter"
                                            )}
                                            options={primaryReviewerOptions}
                                            mode={"multiple"}
                                            placeholder="Please select primary reviewers"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? "")
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {renderFormItem(
                                type, 
                                [
                                    allAuditReportTypes.admin,
                                    allAuditReportTypes.submitter
                                ],
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label="Created Date"
                                        name="createdAtFilter"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please specify date range"
                                            }
                                        ]}
                                        initialValue={DEFAULT_FILTERS_DATA.createdAtFilter}
                                    >
                                        <RangePicker
                                            id={`${type} - createdAtFilter`}
                                            format={"MM/DD/YYYY"}
                                            allowEmpty={[false, false]}
                                            allowClear
                                            onChange={(data) => {
                                                if (data) {
                                                    data[0].utc().hour("00");
                                                    data[0].utc().minutes("00");
                                                    data[0].utc().second("00");
                                                    data[1].utc().hour("23");
                                                    data[1].utc().minutes("59");
                                                    data[1].utc().second("59");
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {renderFormItem(
                                type, 
                                [
                                    allAuditReportTypes.dueDate
                                ],
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label="Submission Due Date"
                                        name="dueDateFilter"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please specify date range"
                                            }
                                        ]}
                                        initialValue={DEFAULT_FILTERS_DATA.dueDateFilter}
                                    >
                                        <RangePicker
                                            id={`${type} - dueDateFilter`}
                                            format={"MM/DD/YYYY"}
                                            allowEmpty={[false, false]}
                                            allowClear
                                            onChange={(data) => {
                                                if (data) {
                                                    data[0].utc().hour("00");
                                                    data[0].utc().minutes("00");
                                                    data[0].utc().second("00");
                                                    data[1].utc().hour("23");
                                                    data[1].utc().minutes("59");
                                                    data[1].utc().second("59");
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {renderFormItem(
                                type,
                                [allAuditReportTypes.submitter],
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label="Organization"
                                        name="organizationFilter"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select organizations"
                                            }
                                        ]}
                                        initialValue={DEFAULT_FILTERS_DATA.organizationFilter}
                                    >
                                        <Select
                                            id={`${type} - organizationFilter`}
                                            onChange={createOnChangeHandler("organizationFilter")}
                                            options={organizationOptions}
                                            mode={"multiple"}
                                            placeholder="Please select organizations"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? "")
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                </Col>,
                                userIsAdmin
                            )}
                            
                            {renderFormItem(
                                type,
                                [
                                    allAuditReportTypes.issue,
                                    allAuditReportTypes.archived,
                                    allAuditReportTypes.submissionType,
                                    allAuditReportTypes.document,
                                    allAuditReportTypes.criteria
                                ],
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label="Submission Type"
                                        name="submissionTypeFilter"
                                        rules={[{ required: true, message: "Please select submission types" }]}
                                        initialValue={DEFAULT_FILTERS_DATA.submissionTypeFilter}
                                    >
                                        <Select
                                            id={`${type} - submissionTypeFilter`}
                                            disabled={type === allAuditReportTypes.archived  && !primaryReviewersFromForm?.length }
                                            onChange={createOnChangeHandler("submissionTypeFilter")}
                                            options={getSubmissionTypeOptions()}
                                            mode={"multiple"}
                                            placeholder="Please select submission types"
                                            allowClear
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? "")
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {renderFormItem(
                                type,
                                [allAuditReportTypes.issue],
                                <Col lg={8} md={12} sm={24}>
                                    <SubmissionIdInput
                                        submissionTypeIDs={getFieldValue("submissionTypeFilter")}
                                        onChangeHandler={createOnChangeHandler("submissionFilter")}
                                        Item={Form.Item}
                                        FormInstance={formRef.current}
                                        initialValue={DEFAULT_FILTERS_DATA.submissionTypeFilter}
                                    />
                                </Col>
                            )}
                            {renderFormItem(
                                type,
                                [allAuditReportTypes.criteria], 
                                <Col lg={8} md={12} sm={24}>
                                    <Form.Item
                                        label="Include Archived Criteria"
                                        name="includeArchivedCriteriaFilter"
                                        valuePropName="checked"
                                        rules={[{ required: false, message: "Please select whether you would like to include archived criteria" }]}
                                        initialValue={DEFAULT_FILTERS_DATA.includeArchivedCriteriaFilter}
                                    >
                                        <Checkbox
                                            id={`${type} - includeArchivedCriteriaFilter`}
                                            checked={includeArchivedCriteria}
                                            onChange={()=>{
                                                setIncludeArchivedCriteria(!includeArchivedCriteria);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row justify="start" gutter={16}>
                            <Col>
                                <ExportTablesButtons
                                    resetFilters={resetFilters}
                                    filtersData={filtersData}
                                    exportLoading={loading}
                                    buttonDisabled={buttonDisabled}
                                    cancelExportRequest={cancelExportRequest}
                                    type={type}
                                /> 
                                {buttonDisabled 
                                    ? <>
                                        <p className="errorMessage">To cancel this Export, please click Cancel Export Button</p>
                                    </>
                                    : null 
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ExportTableForm;
