import moment from "moment";
import React, { useState } from "react";
import { Table } from "antd";
import { PCDULink } from "../common/PCDULink/index.js";
import { searchable } from "../../utils/searchable.js";
import { formatDate } from "../../utils/functions.js";
import { dateable, filterable } from "../../utils/index.js";

export const ContractTable = (({
    newBreadcrumb,
    organizations,
    applyFilters,
    contractsList,
    makeSearchFilter
}) => {

    const [contractIDSearch, setContractIDSearch] = useState("");
    const [submittingOrganizationFilter, setSubmittingOrganizationFilter] = useState([]);
    const [startDateRange, setStartDateRange] = useState([]);
    const [endDateRange, setEndDateRange] = useState([]);
    const contracts = contractsList?.contracts ?? [];

    const makeMatchFilter = (matchItems, accessorFunction) => {
        const shouldFilter = Array.isArray(matchItems) && matchItems.length > 0;
        return shouldFilter 
            ? (item) => matchItems.includes(accessorFunction(item)) 
            : null; 
    }; 
    
    const makeDateFilter = (dateRange = [], key) => {
        const [startDate, endDate] = dateRange;
        const shouldFilter = startDate && endDate;
        return shouldFilter 
            ? (item) => moment(item[key]).isBetween(startDate, endDate) 
            : null; 
    }; 

    const filteredContracts = applyFilters(contracts, [
        makeSearchFilter(contractIDSearch, "specifier"),
        makeMatchFilter(submittingOrganizationFilter, (contract) => contract?.promisor?.id),
        makeDateFilter(startDateRange, "startDate"),
        makeDateFilter(endDateRange, "endDate")
    ]);

    const contractsTable = () =>
    {
        return (
            <>
                <h2>Contracts</h2>
                <Table
                    id="contractsTable"
                    rowKey="id"
                    columns={[
                        searchable({
                            title: "Contract ID",
                            key: "specifier",
                            dataIndex: "specifier",
                            sorter: (a, b) => a.specifier.trim().localeCompare(b.specifier.trim()),
                            sortDirections: ["ascend", "descend", "ascend"],
                            width: "25%",
                            searchedText: contractIDSearch,
                            handleSearch: setContractIDSearch,
                            render: (highlightedText, { id }) => {
                                return (
                                    <PCDULink 
                                        to={`/contracts/${id}`}
                                        newBreadcrumbs={newBreadcrumb}
                                    >
                                        {highlightedText}
                                    </PCDULink>
                                );
                            }
                        }),
                        filterable({
                            title: "Submitting Organization",
                            key: "promisor",
                            dataIndex: "promisor",
                            width: "20%",
                            sorter: (a, b) => a.promisor.name.trim().localeCompare(b.promisor.name.trim()),
                            sortDirections: ["ascend", "descend", "ascend"],
                            searchFilters: true,
                            setFilter: setSubmittingOrganizationFilter,
                            filter: submittingOrganizationFilter,
                            domain: organizations?.map(org => {
                                return {
                                    value: org.id,
                                    label: org.name
                                };
                            }),
                            render: ({ id = "", name = "" }) => {
                                return (
                                    <PCDULink 
                                        to={`/organizations/${id}`}
                                        newBreadcrumbs={newBreadcrumb}
                                    >
                                        {name}
                                    </PCDULink>
                                );
                            }
                        }),
                        dateable({
                            title: "Start Date",
                            key: "start date",
                            sorter: (a, b) => moment(a.startDate).diff(b.startDate, "days"),
                            sortDirections: ["descend", "ascend", "descend"],
                            setFilter: setStartDateRange,
                            filter: startDateRange,
                            render: ({ startDate }) => {
                                return formatDate(startDate);
                            }

                        }),
                        dateable({
                            title: "End Date",
                            key: "end date",
                            sorter: (a, b) => moment(a.endDate).diff(b.endDate, "days"),
                            sortDirections: ["descend", "ascend", "descend"],
                            setFilter: setEndDateRange,
                            filter: endDateRange,
                            render: ({ endDate }) => {
                                return formatDate(endDate);
                            }
                        })
                    ]}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50"]
                    }}
                    dataSource={filteredContracts}
                />
            </>
        );
    };

    return (
        <>
            {contractsTable()}
        </>
    );
});