import React, { useEffect, useState } from "react";

import { Checkbox, Input, Button } from "antd";

import Highlighter from "react-highlight-words";

const DEFAULT_TRUNCATION = 25;

export const FilterDropdown = ({
    title,
    searchFilters,
    setFilter,
    passedKey,
    filter,
    truncate,
    clearFilters,
    resetFilterSearch,
    clearSearchOnReset,
    domain
}) => {
    const [filtersSearch, setFiltersSearch] = useState("");

    useEffect(() => {
        if (resetFilterSearch === true && filtersSearch) {
            setFiltersSearch("");
        }
    }, [resetFilterSearch]);

    return (
        <div style={{ padding: 8 }}>
            {searchFilters ? (
                <Input
                    placeholder={`Filter by ${title}`}
                    value={filtersSearch}
                    onChange={(e) => setFiltersSearch(e.target.value)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
            ) : (
                null
            )}
            <Checkbox.Group
                style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    maxHeight: "300px",
                    maxWidth: "300px",
                    overflowY: "auto"
                }}
                value={filter}
                onChange={(selected) => setFilter(selected)}
            >
                {domain.map(({ label, value, additionalText }, index) => {
                    let textToUse = label;
                    const optionVisible =
                        filtersSearch === "" ||
                        label.toLowerCase().includes(filtersSearch.toLowerCase());
                    
                    if (truncate) {
                        const truncateTo = typeof truncate === "number" ? truncate : DEFAULT_TRUNCATION;
                        if (textToUse.length > truncateTo) {
                            textToUse = `${textToUse.slice(0, truncateTo).trim()}...`;
                        }
                    }

                    return (
                        <Checkbox
                            key={`checkbox-${passedKey}-${index}`}
                            value={value}
                            style={{
                                marginLeft: "8px",
                                display: optionVisible ? "flex" : "none"
                            }}
                        >
                            <Highlighter
                                highlightStyle={{
                                    backgroundColor: "#ffc069",
                                    padding: 0
                                }}
                                searchWords={[filtersSearch]}
                                autoEscape
                                textToHighlight={textToUse}
                                className="wrap"
                            />
                            { additionalText && <div style={{ marginLeft: "14px" }}>
                                <span> {additionalText} </span>
                            </div>}
                        </Checkbox>
                    );
                })}
            </Checkbox.Group>
            <Button onClick={() => {
                if (typeof clearFilters === "function") {
                    if (clearSearchOnReset === true) {
                        setFiltersSearch("");
                    }
                    clearFilters();
                } else {
                    setFilter([]);
                    setFiltersSearch("");
                }
            }} size="small" style={{ width: 90 }}>
                Reset
            </Button>
        </div>
    );
};
