import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Table } from "antd";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { isUndefinedOrNull } from "../../utils/functions.js";

import {
    MODIFY_RELATED_DOCUMENT_STATUS, 
    CHANGE_RELATED_DOCUMENTS 
} from "../../constants/mutations.js";

import {
    ISSUE_STATUS_LIST,
    SUBMISSION_STATUS_LIST
} from "../../constants/index.js";

import { PCDULink } from "../common/PCDULink/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { handleMutation } from "../../utils/errorHandling.js";
import { RelatedDocumentsSelection } from "../common/RelatedDocumentsSelection/index.js";
import {handleControlledDefaultSortOrder} from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";

const { usePersistentState } = PersistentState();

const allowRelatedDocumentsChange = (updatedRelatedDocuments, issue) => {
    const currentRelatedDocuments = issue?.relatedDocuments?.map?.(({ id }) => id) ?? [];
    const atLeastOneDocument = (Array.isArray(updatedRelatedDocuments) && updatedRelatedDocuments.length > 0);
    const somethingIsDifferent = (updatedRelatedDocuments.length !== currentRelatedDocuments.length || updatedRelatedDocuments.some(id => !currentRelatedDocuments.includes(id)));
    return atLeastOneDocument && somethingIsDifferent; 
};

export const RelatedDocumentsTable = (({ 
    persistState = false,
    newBreadcrumbs,
    loading, 
    refetch, 
    issue 
}) => {
    const location = useLocation();

    const {
        userPermRejectnCloseIssue,
        userIsAdmin 
    } = useContext(UserContext);

    const [buttonsLoadingState, setButtonsLoadingState] = useState({});
    const [editingRelatedDocuments, setEditingRelatedDocuments] = useState(false);
    const [updatedRelatedDocuments, setUpdatedRelatedDocuments] = useState([]);
    const [modifyRelatedDocument] = useMutation(MODIFY_RELATED_DOCUMENT_STATUS);
    const [changeRelatedDocuments] = useMutation(CHANGE_RELATED_DOCUMENTS);

    // Persistent state
    const [sortOn, setSortOn] = usePersistentState("issueResolved", persistState);
    const [sortBy, setSortBy] = usePersistentState("ascend", persistState);
    const [currentPage, setCurrentPage] = usePersistentState(1, persistState);
    const [pageSize, setPageSize] = usePersistentState(10, persistState);

    const startingPage = currentPage;
    const startingPageSize = pageSize;

    useEffect(() => {
        setButtonsLoadingState({});
    }, [location.pathname]);

    if (loading) {
        return <LoadingContent />;
    }

    if (isUndefinedOrNull(issue)) {
        return <NoContent />;
    }
    
    const userCanRejectnCloseIssue = userIsAdmin || userPermRejectnCloseIssue(issue?.submission.obligation.submissionType.id);
    const submissionUnderReview = issue?.submission?.status === SUBMISSION_STATUS_LIST.review || issue?.submission?.status === SUBMISSION_STATUS_LIST.final_version_review; 
    const issueIsActive = issue.status === ISSUE_STATUS_LIST.active;
    const isPackagedSubmission = issue?.submission?.obligation?.submissionType?.packagedSubmission;

    const updateRelatedDocument = async (submissionDocumentId, issueId, resolved) => {
        await handleMutation(
            modifyRelatedDocument({
                variables: {
                    submissionDocumentId, 
                    issueId, 
                    resolved
                }  
            })
        );

        refetch();
    };

    const reopenForDocument = (documentId, issueId) => {
        return updateRelatedDocument(documentId, issueId, false);
    };

    const resolveForDocument = (documentId, issueId) => {
        return updateRelatedDocument(documentId, issueId, true);
    };

    const disableRelatedDocuments = !allowRelatedDocumentsChange(updatedRelatedDocuments, issue);

    return (
        <>
            <div style={{marginTop: "20px"}}>
                <h3> {`Related Document${isPackagedSubmission !== false ? "s" : ""}`} </h3>
                { userCanRejectnCloseIssue && issueIsActive && isPackagedSubmission === true && <Button
                    size={"small"}
                    style={{marginBottom: "10px"}}
                    type={"primary"}
                    disabled={!submissionUnderReview}
                    onClick={() => {
                        const startingDocuments = issue?.relatedDocuments?.map?.(({ id }) => id) ?? [];
                        setUpdatedRelatedDocuments(startingDocuments);
                        setEditingRelatedDocuments(true);
                    }}
                >
                    Change 
                </Button> }
                <Table 
                    id="related-documents-subtable"
                    rowKey={"id"}
                    dataSource={issue.relatedDocuments}
                    onChange={(pagination, _filters, { field, order })=>{
                        const { current, pageSize } = pagination;
                        setCurrentPage(current);
                        setPageSize(pageSize);
                        setSortOn(field);
                        setSortBy(order);
                    }}
                    pagination={{ 
                        defaultCurrent: startingPage, 
                        defaultPageSize: startingPageSize 
                    }}
                    columns={handleControlledDefaultSortOrder({sortOn, sortBy},[
                        {
                            title: "ID",
                            dataIndex: "specifier",
                            render: (document) => {
                                const documentId = issue.relatedDocuments.find((({specifier}) => specifier === document))?.id;
                                return <PCDULink 
                                    to={`/submissions/${issue.submission.id}/documents/${documentId}`}
                                    newBreadcrumbs={newBreadcrumbs}
                                >
                                    {document}
                                </PCDULink>;
                            },       
                            sorter: (a,b) => a.specifier.trim().toLowerCase() > b.specifier.trim().toLowerCase() ? 1 : -1,
                            sortDirections: ["ascend", "descend", "ascend"]
                        },
                        {
                            title: "Name",
                            dataIndex: "nickname",
                            sorter: (a,b) =>  a.nickname.trim().toLowerCase() > b.nickname.trim().toLowerCase() ? 1 : -1,
                            sortDirections: ["ascend", "descend", "ascend"] 
                        },
                        {
                            title: "Resolved For Document",
                            dataIndex: "issueResolved",
                            render: (bool) => bool === true ? "Yes" : "No",
                            sorter: (a,b) => a > b ? 1 : -1,
                            sortDirections: ["ascend", "descend", "ascend"]
                        }, 
                        ( userCanRejectnCloseIssue && issueIsActive 
                            ? {
                                title: "Actions",
                                render: (document) => {
                                    return (
                                        <Button
                                            size="small"
                                            type={document.issueResolved === true ? "danger" : "primary"}
                                            loading={buttonsLoadingState[document.id]}
                                            onClick={async () => {
                                                setButtonsLoadingState({
                                                    ...buttonsLoadingState,
                                                    [document.id]: true
                                                });
                                                if (document.issueResolved === true) {
                                                    await reopenForDocument(document.id, issue.id);
                                                } else {
                                                    await resolveForDocument(document.id, issue.id);
                                                }
                                                setButtonsLoadingState({
                                                    ...buttonsLoadingState,
                                                    [document.id]: false
                                                });
                                            }}
                                            disabled={!submissionUnderReview}
                                        >
                                            {document.issueResolved === true ? "Reopen" : "Resolve"}
                                        </Button>
                                    );
                                }
                            }
                            : null 
                        )
                    ].filter(column => column))}
                />
            </div>
            <Modal 
                okButtonProps={{ 
                    disabled: disableRelatedDocuments
                }}
                visible={editingRelatedDocuments}
                onOk={async () => { 
                    await handleMutation(
                        changeRelatedDocuments({
                            variables: {
                                submissionDocumentIds: updatedRelatedDocuments,
                                issueId: issue.id
                            }
                        })
                    ); 
                    await refetch(); 
                    setEditingRelatedDocuments(false); 
                }}
                onCancel={() => setEditingRelatedDocuments(false)}
                afterClose={() => setEditingRelatedDocuments(false)}
            >
                <>
                    <RelatedDocumentsSelection 
                        setSelectedRelatedDocuments={setUpdatedRelatedDocuments}
                        selectedRelatedDocuments={updatedRelatedDocuments ?? []}
                        submissionDocuments={issue?.submission?.submissionDocuments ?? []}
                    />
                </>
            </Modal> 
        </>
    );
});
