import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { useQuery } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { Main } from "../common/Main/index.js";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { NoContent } from "../common/NoContent/index.js";
import { formatDate, isUndefinedOrNull } from "../../utils/functions.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { LabeledDetail } from "../common/LabeledDetail/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { useAttemptToModifyContract } from "../../utils/attemptMutationHooks.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { contractDetailQuery } from "./query.js";


export const ContractDetail = () => {
    const params = useParams();

    const [modified, setModified] = useState({});
    const [attemptToModifyContract] = useAttemptToModifyContract();

    const {
        userPermEditContracts,
        userPermViewContractDetail,
        userPermViewContractTypeDetail,
        userPermViewUserAuditLog,
        userPermViewOrganizationDetails
    } = useContext(UserContext);

    const { loading, error, data } = useQuery(
        contractDetailQuery, 
        {
            variables: {
                id: params.id 
            },
            fetchPolicy: "no-cache"
        }
    );

    if (!userPermViewContractDetail) {
        return <NoPermission />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (isUndefinedOrNull(data.contract)) {
        return <NoContent />;
    }

    const contract = typeof data.contract === "undefined" || data.contract === null
        ? {}
        : data.contract;

    const newBreadcrumbForLinks = data?.contract 
        ? { 
            label: `${contract?.promisor?.specifier} - ${contract?.contractType?.name}`, 
            path: `/contracts/${contract.id}`, 
            tooltip: `Contract: ${contract?.promisor?.specifier} - ${contract?.contractType?.name}`
        }
        : [];

    const changeableFieldModified = modified.endDate
        || modified.startDate
        || modified.specifier;

    const LinkToContractType = () => <PCDULink 
        to={`/contract-types/${contract.contractType.id}`}
        newBreadcrumbs={newBreadcrumbForLinks}
    >
        {contract.contractType.name}
    </PCDULink>;

    const LinkToOrganization = () => <PCDULink 
        to={`/organizations/${contract.promisor.id}`}
        newBreadcrumbs={newBreadcrumbForLinks}
    >
        {contract.promisor.specifier} - {contract.promisor.name}
    </PCDULink>;

    const SaveChangesButton = () => (
        <Button
            type="primary"
            className="spaceAbove-xs"
            onClick={async () => {
                const modifiedContract = {
                    specifier: modified.specifier,
                    startDate: modified.startDate,
                    endDate: modified.endDate
                };

                const { success } = await attemptToModifyContract({
                    variables: {
                        id: contract.id,
                        newContract: modifiedContract
                    }
                });
                if (success) {
                    setModified({});
                }
            }}
        >
            Save Changes
        </Button>
    );

    const details = (
        <div id="details">
            { userPermViewUserAuditLog && contract.id  
                ? <div style={{margin: "10px 0px", fontSize: "16px"}}>
                    <PCDULink 
                        to={`/changelogs/contract-type-plans/${contract.id}`}
                        newBreadcrumbs={newBreadcrumbForLinks}
                    > 
                        Changelogs 
                    </PCDULink>
                </div>
                : null
            }
            {
                <EditableDetail
                    title="Contract ID"
                    key="specifier"
                    value={typeof modified.specifier === "undefined"
                        ? contract.specifier
                        : modified.specifier
                    }
                    readOnly={!userPermEditContracts}
                    onValueUpdated={(e) => {
                        setModified({
                            ...modified,
                            specifier: e.target.value
                        });
                    }}
                />
            }
            <LabeledDetail title="Contract Type">
                {
                    userPermViewContractTypeDetail
                        ? <LinkToContractType />
                        : contract.contractType.name
                }
            </LabeledDetail>
            <LabeledDetail title="Submitting Organization">
                {
                    userPermViewOrganizationDetails
                        ? <LinkToOrganization />
                        : `${contract.promisor.specifier} - ${contract.promisor.name}`
                }
            </LabeledDetail>
            <>
                <LabeledDetail title="Start Date">
                    {formatDate(contract.startDate)}
                </LabeledDetail>
                <LabeledDetail title="End Date">
                    {formatDate(contract.endDate)}
                </LabeledDetail>
            </>
            { changeableFieldModified
                ? <SaveChangesButton />
                : null 
            }
        </div>
    );

    return (
        <Main
            title={`${contract.promisor.specifier} - ${contract.promisor.name} - ${contract.contractType.name}`}
            breadcrumbs={[
                <PCDULink to="/contracts" tooltip="Contracts">Contracts</PCDULink>, 
                `${contract?.promisor?.specifier} - ${contract?.contractType?.name}`
            ]}
            details={details}
        />
    );
};