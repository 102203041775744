import React, { useEffect, useState, useContext, useRef } from "react";
import moment from "moment";
import { Table, Pagination } from "antd";
import { useQuery } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { formatTimeValue, filterable, isoDateRange, formatDate, displayResubmissionDueDate } from "../../utils/index.js";
import { isUndefinedOrNull } from "../../utils/functions.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { submissionsQuery } from "./queries.js";
import { EXPORTSIZE } from "../../constants/index.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { CALENDAR_BREADCRUMB, submissionTableTitle } from "./helpers.js"; 
import { ExtendDueDate } from "../common/ExtendDueDate/index.js";
import { CommentsList } from "../common/CommentsList/index.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { PCDULink } from "../common/PCDULink/index.js";

const { usePersistentState } = PersistentState();

export const SUBMISSION_HEADERS = [
    {
        label: "ID",
        key: "id"
    },
    {
        label: "Submission Type",
        key: "submissionType"
    },
    {
        label: "Submitting Organization",
        key: "submittingOrganization"
    },
    {
        label: "Submission Date",
        key: "submissionDate"
    },
    {
        label: "Status",
        key: "status"
    },
    {
        label: "Current Task Due Date",
        key: "taskDueDate"
    }
];

const transformSubmissionsForExport = (data) => {
    const submissions = data?.submissionsList?.submissions ?? [{}];
    return submissions.map(sub => {
        const taskDueISODate = sub.reviewerDueDate ?? (sub.submitterDueDate ?? null);
        return {
            id: sub.specifier ?? "",
            submissionType: `${sub?.submissionType?.specifier ?? ""} - ${sub?.submissionType?.name ?? ""}`,
            submittingOrganization: sub?.submitter?.name ?? "",
            submissionDate: formatTimeValue(sub.latestVersionCreatedAt),
            status: sub.status ?? "",
            taskDueDate: taskDueISODate === null ? "" : formatDate(taskDueISODate)
        };
    });
};

const dueForActionMarkup = (dueDate) => {
    if (!dueDate) {
        return "N/A";
    } else {
        const difference = moment(dueDate).diff(moment().startOf("day"), "days");
        if (difference >= 0 ) {
            return <span> {moment(dueDate).format("MM/DD/YYYY")} </span>;
        } else {
            const absDiff = Math.abs(difference);
            return <span style={{color: "red"}}> {absDiff && moment(dueDate).format("MM/DD/YYYY")} </span>;
        }
    }
};

export const SubmissionsTable = ({ 
    shouldReset,
    categoryFilterChanged, 
    containerLoaded,
    permissions,
    constants,
    submissionDueRange, 
    categoryFilter,
    submissionTypesFilter, 
    setSubmissionTypesFilter,
    organizationsFilter, 
    setOrganizationsFilter,
    statusFilter, 
    setStatusFilter 
}) => { 
    const defaultSortOn = "latestVersionCreatedAt";
    const defaultSortBy = "descend";

    const refAnchor = useRef();

    // normal useState :: 
    const [dataToUse, setDataToUse] = useState(null);
    const [scrollToAnchor, setScrollToAnchor] = useState(false);

    // "persistent state" -- latest value stored and retained ::  
    const [page, setPage] = usePersistentState(1);
    const [pageSize, setPageSize] = usePersistentState(10);
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);

    const offset = (page - 1) * pageSize;

    const { 
        canFilterByOrganization
    } = permissions;

    const {
        userPermCreateSubmission, 
        userPermViewAllEntries,
        userPermCreateExtension,
        userId,
        userIsReviewerOrNotifier
    } = useContext(UserContext);

    const variables = {
        submissionDueRange: isoDateRange(submissionDueRange),
        submissionTypesFilter,
        organizationsFilter,
        categoryFilter,
        statusFilter, 
        reviewerFilter: userId,
        reviewerCurrentOnly: !userPermViewAllEntries && userIsReviewerOrNotifier, 
        limit: pageSize, 
        offset, 
        sortOn,
        sortBy,
        preSelectedStatusFilter: true
    };
    
    const { loading, data, error, refetch } = useQuery(submissionsQuery, {
        variables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    },[data]);

    useEffect(() => {
        if (categoryFilterChanged === true) {
            setPage(1);
        }
    }, [categoryFilterChanged]);

    useEffect(() => {
        if (shouldReset) {
            setPage(1);
            setPageSize(10);
            setSortOn(defaultSortOn);
            setSortBy(defaultSortBy);
        }
    }, [shouldReset]);

    useEffect(() => {
        if (scrollToAnchor) {
            refAnchor.current.scrollIntoView(true);
            setScrollToAnchor(false);
        }
    }, [data]);

    if (error) {
        return handleGraphQLError(error);
    }

    const submissions = dataToUse?.submissionsList?.submissions ?? [];
    const submissionsCount = dataToUse?.submissionsList?.count ?? 0;

    const {
        organizations, 
        submissionTypes, 
        applicableStatuses
    } = constants;

    return (
        <>
            { Array.isArray(submissions) &&  submissions.length > 0 &&  
                <CsvExport
                    rightAligned 
                    csvFilename={"Submissions_Review_Report.csv"}
                    csvHeaders={SUBMISSION_HEADERS}
                    query={submissionsQuery}
                    variables={{...variables, offset: 0, limit: EXPORTSIZE}}
                    transformData={transformSubmissionsForExport}
                />
            }

            <h3
                style={{
                    marginTop: "24px",
                    border: "1px solid prink"
                }}
            >
                {submissionTableTitle({
                    canCreateSubmission: userPermCreateSubmission,
                    userIsReviewerOrNotifier,
                    canViewAllEntries: userPermViewAllEntries,
                    submissionDueRange
                })}
            </h3>

            <Table
                tableLayout="fixed"
                ref={refAnchor}
                rowKey={({ id }) => `calendar-submission-table-${id}`}
                style={{
                    backgroundColor: "#fff"
                }}
                getPopupContainer={(node) => node ? node.parentNode : document.body}
                size="middle"
                columns={handleControlledDefaultSortOrder({ 
                    sortOn, 
                    sortBy, 
                    customHandler: ({ key }) => key === sortOn 
                }, [
                    {
                        title: "ID",
                        dataIndex: "id",
                        key: "specifier",
                        render: (id, { specifier }) => {
                            return (
                                <PCDULink 
                                    to={`/submissions/${id}`} 
                                    newBreadcrumbs={CALENDAR_BREADCRUMB}
                                >
                                    {specifier}
                                </PCDULink>
                            );
                        },
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    filterable({
                        title: "Submission Type",
                        dataIndex: ["submissionType"],
                        key: "submissionType",
                        domain: submissionTypes.map(({ id, specifier }) => {
                            return {
                                label: specifier,
                                value: id
                            };
                        }),
                        searchFilters: true,
                        resetFilterSearch: shouldReset,
                        filter: submissionTypesFilter,
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        setFilter: (subTypes) => {
                            setScrollToAnchor(true);
                            setSubmissionTypesFilter(subTypes);
                        },
                        setPage: setPage,
                        render: ({ id, name }) => {
                            return (
                                <PCDULink 
                                    to={`/submission-types/${id}`} 
                                    newBreadcrumbs={CALENDAR_BREADCRUMB}
                                >
                                    {name}
                                </PCDULink>
                            );
                        }
                    }),
                    canFilterByOrganization
                        ? filterable({
                            title: "Submitting Organization",
                            dataIndex: ["submitter"],
                            key: "submitter",
                            domain: organizations.map(({ id, name }) => {
                                return {
                                    label: name,
                                    value: id
                                };
                            }),
                            filter: organizationsFilter,
                            setFilter:(orgs) => {
                                setScrollToAnchor(true);
                                setOrganizationsFilter(orgs);
                            },
                            setPage: setPage,
                            sorter: true,
                            sortDirections: ["ascend", "descend", "ascend"],
                            render: ({ id, name }) => {
                                return (
                                    <PCDULink 
                                        to={`/organizations/${id}`} 
                                        newBreadcrumbs={CALENDAR_BREADCRUMB}
                                    >
                                        {name}
                                    </PCDULink>
                                );
                            }
                        })
                        : null
                    ,
                    {
                        title: "Submission Date",
                        dataIndex: "latestVersionCreatedAt",
                        key: "latestVersionCreatedAt",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (latestVersionCreatedAt) => (<span> {formatTimeValue(latestVersionCreatedAt)} </span>)
                    },
                    filterable({
                        title: "Status",
                        dataIndex: "status",
                        key: "status",
                        domain: applicableStatuses.map((status_name) => {
                            return {
                                label: status_name,
                                value: status_name
                            };
                        }),
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        filter: statusFilter,
                        setFilter: (statuses) => {
                            setScrollToAnchor(true);
                            if (Array.isArray(statuses) && statuses.length > 0) {
                                setStatusFilter(statuses);
                            } else {
                                setStatusFilter();
                            }
                        },
                        setPage: setPage,
                        clearFilters: () => {
                            setStatusFilter();
                        }
                    }),
                    {
                        title: "Resubmission Due",
                        key: "submitterDueDate",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (submission) => {
                            return displayResubmissionDueDate(submission.submitterDueDate, submission.initialSubmitterDueDate);
                        } 
                    },
                    userPermCreateExtension
                        ? {
                            title: "Actions",
                            key: "actions",
                            render: (submission) => {
                                return (
                                    <ExtendDueDate
                                        submission={submission}
                                        refetch={refetch}
                                        latestSubmissionVersionId={submission.submissionNewestVersion.id}
                                    />
                                );
                            }
                        } : null,
                    userPermCreateSubmission ? null : {
                        title: "Review Due",
                        key: "reviewerDueDate",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (submission) => dueForActionMarkup(submission.reviewerDueDate)
                    }
                ].filter(Boolean))}
                dataSource={submissions}
                pagination={false}
                loading={loading || !containerLoaded}
                onChange={(_, __, { columnKey, order }) => {
                    setSortOn(columnKey);
                    setSortBy(order);
                }}
                expandable={{
                    expandedRowRender: submission => {
                        return (
                            <div>
                                <CommentsList
                                    title={""}
                                    parent={submission}
                                    userId={userId}
                                    refetch={() => refetch()} 
                                    filterForDueDateComments={true}
                                />
                            </div>
                        );
                    },
                    rowExpandable: submission => Array.isArray(submission.comments) && submission.comments.length > 0 && submission.comments.some(comment => !isUndefinedOrNull(comment.previousDueDate))
                }}
            />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onShowSizeChange={(targetPage, pageSize) => {
                    setPage(targetPage);
                    setPageSize(pageSize);
                }}
                onChange={(targetPage, pageSize) => {
                    setPage(targetPage);
                    setPageSize(pageSize);
                }}
                current={page}
                pageSize={pageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={submissionsCount}
            />
        </>
    );
};
