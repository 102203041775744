import React, { useState, useEffect } from "react";
import { Collapse, Spin } from "antd";
import ExportTableForm from "./form.js";

const { Panel } = Collapse;

const ExportTablesCollapseMenus = ({
    data,
    report,
    index
}) => { 

    const [lastUpdatedDate, setLastUpdatedDate] = useState();
    const [exportProcessing, setExportProcessing] = useState(false);

    useEffect(() => {
        const latestRecord = data.exportTableRequestAllLogs.find(({ type }) => report === type);
        if (latestRecord) {
            setLastUpdatedDate(latestRecord.dateOfLastSuccessfulExport);
            if (latestRecord.canExport) {
                setExportProcessing(false);
            }
        }
    }, [data.exportTableRequestAllLogs]);

    const updateHeaderPanel = ({ lastExportedDate, loading }) => {
        setExportProcessing(loading);
        if (lastExportedDate) {
            setLastUpdatedDate(lastExportedDate);
        }
    };

    const extraPanelContent = () => {
        return (
            <div style={{
                height: "10px",
                fontSize: "small",
                fontWeight: 100
            }}
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
            }}
            >
                {
                    exportProcessing ? (
                        <>
                            <span className="export-processing">Exporting... &nbsp;<Spin size="small" /></span>
                        </>
                    ) : (
                        <span>Last Exported:&nbsp;{lastUpdatedDate ?? "--"}</span>
                    )
                }
            </div>
        );
    };


    const collapseMenus = (
        <Collapse
            expandIconPosition="end"
            key={index}
        >
            <Panel
                header={report}
                key="1"
                extra={extraPanelContent(report)}
            >
                <ExportTableForm
                    type={report}
                    data={data}
                    refetchExportFileStatus={data.exportTableRefetch}
                    updateHeaderPanel={updateHeaderPanel}
                />
            </Panel>
        </Collapse>
    );

    return (
        <>
            { collapseMenus }
        </>
    );
};

export default ExportTablesCollapseMenus;