import React, { useState, useEffect, useContext } from "react";
import { Button, Pagination, Modal, Radio } from "antd";
import { Table } from "../common/Table/index.js";
import { UserContext } from "../Application/UserContext.js";
import { formatTimeValue, isUndefinedOrNull } from "../../utils/functions.js";
import { searchable, displayFileSizeUnit } from "../../utils/index.js";
import { MODIFY_LIBRARY_DOCUMENT } from "../../constants/mutations.js";
import { LibraryDocumentsQuery } from "./queries.js";
import { useQuery, useMutation } from "@apollo/client";
import { handleGraphQLError, handleMutations } from "../../utils/errorHandling.js";
import { LibraryDocumentActions } from "../common/LibraryDocumentActions/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { DocumentUploadStatus } from "./document-upload-status.js";
import { PCDULink } from "../common/PCDULink/index.js";

const nullResult = <span> -- </span>;

const { confirm } = Modal; 

const mergeDocumentData = (previousRecords, updatedRecords = []) => { 
    if (!Array.isArray(previousRecords)) {
        return [];
    } else {
        return previousRecords.map((previousRec) => {
            const updatedRec = updatedRecords.find(({ id }) => id === previousRec.id); 
            return updatedRec ? Object.assign({}, previousRec, updatedRec) : previousRec;     
        });
    }
};


export const LibraryDocumentsTable = ({
    uploadLibraryDocumentButton,
    inArchivedFolder = false,
    subtitle,
    locationState,
    searchData = null,
    refetchDocuments = false,
    setRefetchDocuments,
    contractTypeId,
    libraryFolderId,
    search = false,
    getFolderPathway
}) => { 
    const [sortOn, setSortOn] = useState("dateModified");
    const [sortBy, setSortBy] = useState("DESC");
    const [documentSelection, setDocumentSelection] = useState([]);
    const [documentsPage, setDocumentsPage] = useState(1);
    const [documentsPageSize, setDocumentsPageSize] = useState(10);
    const [documentNameSearch, setDocumentNameSearch] = useState("");
    const [activeFilter, setActiveFilter] = useState(!inArchivedFolder);
    const [modifyLibraryDocument] = useMutation(MODIFY_LIBRARY_DOCUMENT);
    const [componentUnmounting, setComponentUnmounting] = useState(false); 
    const [updatedDocuments, setUpdatedDocuments] = useState([]);
    
    const documentsOffset = Math.max(0, ((documentsPage - 1) * documentsPageSize));
    const archiveView = !activeFilter;

    const resetStateOnFolderChange = (active) => {
        setDocumentsPage(1);
        setDocumentsPageSize(10);
        setActiveFilter(active);
        setDocumentSelection([]);
        setDocumentNameSearch("");
        setSortOn("name");
        setSortBy("ASC");
    };

    const { data, previousData, error, loading, refetch } = useQuery(LibraryDocumentsQuery, {
        variables: {
            search,
            libraryFolderId,
            contractTypeId,
            activedocumentFilter: activeFilter,
            contractTypeFilter: searchData?.contractTypes ?? [],
            documentNameSearch: searchData?.name ? searchData.name : (documentNameSearch ?? ""),
            documentsSortOn: sortOn,
            documentsSortBy: sortBy,
            documentsOffset,
            documentsLimit: documentsPageSize, 
            includeS3Metadata: true 
        },
        fetchPolicy: "no-cache"
    }); 

    // component is unmounting :: 
    useEffect(() => () => {
        setUpdatedDocuments([]);
        setComponentUnmounting(true);
    }, []); 

    useEffect(() => {
        if (locationState?.clearOut && !componentUnmounting) {
            resetStateOnFolderChange(true);
        }

        setUpdatedDocuments([]);
    }, [locationState]);

    useEffect(() => {
        if (refetchDocuments === true && typeof setRefetchDocuments === "function" && !componentUnmounting) {
            setRefetchDocuments(false);
            refetch();
        }
    }, [refetchDocuments]);

    useEffect(() => {
        setActiveFilter(!inArchivedFolder);
    }, [inArchivedFolder]); 

    useEffect(() => {
        setUpdatedDocuments([]);
    }, [data]);

    const {
        userPermDownloadLibDoc,
        userPermDownloadAssociatedLibDoc,
        userPermUploadnModifyLibDoc,
        userIsSubmitter
    } = useContext(UserContext);

    // normalize fetched library documents w/ any updates since last refetch :: 
    const libraryDocuments = loading 
        ? (previousData?.libraryDocuments ?? [])
        : mergeDocumentData(data?.libraryDocuments, updatedDocuments); 
    const documentCount = libraryDocuments[0]?.count ?? 0;
    const userCanDownloadDocuments = userPermDownloadLibDoc || userPermDownloadAssociatedLibDoc;

    // ________________________________
    // early return situations :: 

    if (error) {
        return handleGraphQLError(error);
    }

    if (search === true && loading) {
        return <LoadingContent />;
    }

    if (search === true && !loading && libraryDocuments.length === 0) { 
        // search without results 
        return <h2> No documents found </h2>;
    } 
    // ________________________________

    const showConfirmArchiveDocument = () => {

        const documentNames = libraryDocuments.map(({ id, name }) => documentSelection.includes(id) ? name : null)
            .filter(item => !isUndefinedOrNull(item));

        confirm({
            title:
                `Do you really want to ${activeFilter ? "Archive" : "De-Archive"} the ${documentNames.length === 1 ? "document" : "documents"}:
                ${documentNames.join(", ")}?`,
            okText: "Yes",
            async onOk() {
                const active = !activeFilter;
                const mutations = documentSelection.map((id) => {
                    return modifyLibraryDocument({
                        variables: {
                            id,
                            active
                        }
                    });
                });

                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${documentSelection.length === 1 ? "Document" : "Documents"} successfully modified`
                });

                setDocumentSelection([]);
                await refetch();
            }
        });
    };

    const archiveDocument = <div style={{ marginBottom: "15px" }}>
        <Button
            style={{
                display: userPermUploadnModifyLibDoc ? "block" : "none"
            }}
            className="ownLine"
            type="danger"
            size="small"
            onClick={showConfirmArchiveDocument}
            disabled={inArchivedFolder || documentSelection.length <= 0}
        >
            {activeFilter ? "Archive" : "De-Archive"} {documentSelection?.length ?? 0} {documentSelection.length === 1 ? "document" : "documents"}
        </Button>
    </div>;

    const toggleArchiveView = search !== true
        ? <Radio.Group
            defaultValue={activeFilter}
            value={activeFilter}
            onChange={(e) => {
                setActiveFilter(e.target.value);
                setDocumentsPage(1);
            }}
            buttonStyle="solid"
        >
            <Radio.Button value={true} >Active </Radio.Button>
            <Radio.Button value={false}> Archived </Radio.Button>
        </Radio.Group> 
        : null; 

    return (
        <div>
            {subtitle ? <h2>{subtitle}</h2> : null}
            <h2> {archiveView ? "Archived Documents" : "Documents"} </h2>
            <div style={{ marginBottom: "10px", marginTop: "-5px" }}>
                { uploadLibraryDocumentButton }
            </div>
            <Table 
                clearFiltersButton={archiveDocument}
                rightAlignedButtonBarElement={toggleArchiveView}
                loading={loading} 
                columns={[
                    (search !== true
                        ? searchable({
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            sortDirections: ["ascend", "descend", "ascend"],
                            searchedText: documentNameSearch,
                            handleSearch: setDocumentNameSearch,
                            setPage: setDocumentsPage,
                            handleReset: () => setDocumentNameSearch(""), 
                            render: (name, { id }) => <PCDULink to={`/library/documents/${id}`}>{ name }</PCDULink> 
                        })
                        : {
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            sortDirections: ["ascend", "descend", "ascend"],
                            render: (name, { id }) => <PCDULink to={`/library/documents/${id}`}>{ name }</PCDULink>
                        }
                    ),
                    {
                        title: "Size",
                        dataIndex: "fileSize",
                        key: "fileSize",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (fileSize) => {
                            return displayFileSizeUnit(fileSize);
                        }
                    },
                    {
                        title: "Last Modified",
                        dataIndex: "dateModified",
                        key: "dateModified",
                        sorter: true,
                        sortDirections: ["descend", "ascend", "descend"],
                        defaultSortOrder: "descend",
                        render: (dateModified) => {
                            const text = formatTimeValue(dateModified, true);
                            return (<span>{text}</span>);
                        }
                    },
                    {
                        title: "Author",
                        key: "author-name",
                        dataIndex: "authorName",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name, record) => {
                            const nameToUse = record?.author?.name ?? name;
                            return !nameToUse ? nullResult : <span>{ nameToUse }</span>;
                        }
                    },
                    {
                        title: "Archived",
                        key: "active",
                        dataIndex: "active",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (active) => {
                            return active ? "No" : "Yes";
                        }
                    },
                    {
                        title: "File Upload Status",
                        key: "uploadStatus",
                        dataIndex: "S3UploadStatus",
                        sorter: true,
                        render: (S3UploadStatus, documentData) => {
                            return <DocumentUploadStatus
                                status={S3UploadStatus}
                                documentDetail={documentData}
                            />;
                        }
                    },
                    (!search ? null : {
                        title: "Location",
                        dataIndex: "parentFolderName",
                        key: "parent-folder",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name, { contractTypeCategoryName, parentFolderId }) => {
                            const nameToUse = contractTypeCategoryName ? contractTypeCategoryName : name;
                            if (!nameToUse) {
                                return nullResult;
                            } else if (typeof getFolderPathway !== "function") {
                                return <span>{nameToUse}</span>;
                            } else {
                                return (
                                    <span
                                        style={{
                                            color: "blue",
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            getFolderPathway({
                                                variables: {
                                                    folderId: parentFolderId
                                                }
                                            });
                                        }}
                                    >
                                        {nameToUse}
                                    </span>
                                );
                            }
                        }
                    }),
                    (!search ? null : {
                        title: "Contract Type",
                        dataIndex: "contractTypeName",
                        key: "contract-type",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (name) => {
                            return !name ? nullResult : <span>{name}</span>;
                        }
                    }),
                    (!userCanDownloadDocuments ? null : {
                        title: "Actions",
                        key: "library-documents-actions",
                        render: (_, record) => <LibraryDocumentActions 
                            isSubmitter={userIsSubmitter}
                            record={record}
                            userCanUpload={userPermUploadnModifyLibDoc}
                            libraryFolderId={libraryFolderId}
                            reportUpdatedDocument={(updatedDocument) => { 
                                const updatedDocumentToUse = updatedDocument && typeof updatedDocument === "object" ? updatedDocument : {};
                                const latestDocuments = mergeDocumentData(libraryDocuments, [updatedDocumentToUse]); 
                                setUpdatedDocuments(latestDocuments);
                            }} 
                            onReuploadFailure={() => {
                                refetch().finally(() => setUpdatedDocuments([]));
                            }}
                        />
                    })
                ].filter(col => col)}
                dataSource={libraryDocuments}
                rowKey="id"
                pagination={false}
                rowSelection={(userPermUploadnModifyLibDoc && search !== true && !inArchivedFolder) && {
                    selectedRowKeys: documentSelection,
                    onChange: (selectedRowKeys) => {
                        setDocumentSelection(selectedRowKeys);
                    }
                }}
                onChange={(_pagination, _filters, { field, order }) => {
                    setSortOn(field);
                    setSortBy(order);
                }}
            />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onShowSizeChange={(targetPage, pageSize) => {
                    setDocumentsPage(targetPage);
                    setDocumentsPageSize(pageSize);
                }}
                onChange={(targetPage, pageSize) => {
                    setDocumentsPage(targetPage);
                    setDocumentsPageSize(pageSize);
                }}
                current={documentsPage}
                defaultCurrent={documentsPage}
                pageSize={documentsPageSize}
                defaultPageSize={documentsPageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={documentCount}
            />
        </div>
    );  
};
