import React from "react";
import { EXPORTSIZE, ROLE_DISPLAY_CONVERSION } from "../../constants/index.js";
import { CsvExport } from "../common/CsvExport/index.js";
import { usersQuery } from "./query.js";

const transformUsersDataForCSV = (data) => {
    return data.usersList.users.map((user) => {
        return {
            name: user.name,
            ncid: user.ncid || "",
            organization: user.organizations.map(org => org.name).join(", "),
            role: user.roles.map((role) => ROLE_DISPLAY_CONVERSION[role.specifier]).join(", "),
            status: user.active ? "Active User" : "Disabled User",
            email :user.email
        };
    });
};

export const UsersCsvExport = ({ variables, showStatus }) => {
    const csvHeaders = [
        { label: "Name", key: "name" },
        { label: "NCID", key: "ncid" },
        { label: "Organization", key: "organization" },
        { label: "Role", key: "role" },
        { label: "Email", key: "email" } 
    ];
    if (showStatus) {
        csvHeaders.push({ label: "Status", key: "status" });
    }
    return <CsvExport
        csvFilename={"Users_Report.csv"}
        csvHeaders={csvHeaders}
        query={usersQuery}
        variables={{...variables, offset: 0, limit: EXPORTSIZE}}
        transformData={transformUsersDataForCSV}
    />;
};