import React, { useState, useEffect } from "react";
import { LibraryDocumentQuery } from "./queries.js";
import { useQuery } from "@apollo/client";
import { S3UploadStatus } from "../common/S3UploadStatus/index.js";
import { S3_UPLOAD_STATUS, S3_UPLOAD_STATUS_FETCH_ERROR } from "../../constants/index.js";
import { pollingTimeByFileSize } from "../../utils/index.js";


const ActiveStatusTracker = ({ id, fileSize, status }) => {
    // we want to set a single polling interval that's used throughout lifecycle :: 
    const [pollingInterval] = useState(pollingTimeByFileSize(fileSize));
    const [documentStatus, setDocumentStatus] = useState(status); 

    // in this case, we don't want to include S3 metadata -- 
    // we are just going off of the S3UploadStatus property of the document :: 
    const fetchOptions = {
        variables: { 
            id, 
            includeS3Metadata: false 
        }, 
        fetchPolicy: "no-cache"
    };

    const {
        data, 
        error, 
        stopPolling, 
        startPolling 
    } = useQuery(LibraryDocumentQuery, fetchOptions);

    useEffect(() => { 
        if (data) {
            const currentStatus = data?.libraryDocument?.S3UploadStatus; 
            if (currentStatus === S3_UPLOAD_STATUS.uploading) {
                startPolling(pollingInterval);
            } else { 
                stopPolling(); 
                setDocumentStatus(currentStatus);
            } 
        }

        if (error) {
            stopPolling(); 
            setDocumentStatus(S3_UPLOAD_STATUS_FETCH_ERROR);
        }
    
        return stopPolling;
    }, [data, error]); 

    return (
        <div>
            {S3UploadStatus(documentStatus)}
        </div>
    );
};


export const DocumentUploadStatus = ({
    documentDetail,
    status = null
}) => { 
    const { id, fileSize } = documentDetail; 
    switch (status) { 
        // in these cases, we don't need to fetch data :: 
        case S3_UPLOAD_STATUS.error: 
        case S3_UPLOAD_STATUS.quarantine: 
        case S3_UPLOAD_STATUS.uploaded: 
            return (
                <div>
                    {S3UploadStatus(status)}
                </div>
            );
        default: 
            // if status is uploading, or invalid -- nullish (i.e., unknown), nonsensical, etc. -- we fetch and render the status :: 
            return <ActiveStatusTracker 
                id={id} 
                fileSize={fileSize} 
                status={status}
            />;
    }
};
