import React from "react";
import { Button, Radio } from "antd";
import { EditableDetail } from "../common/EditableDetail/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { TextAreaDetail } from "../common/TextAreaDetail/index.js";
import {
    getSubmissionTypeSelectFields,
    getModifiedForCategory
} from "./_helpers.js";


export const SubmissionTypeDetailFields = ({ 
    isEditable,
    submissionType,
    addingDescription,
    setAddingDescription,
    modified,
    setModified,
    constants,
    categories,
    businessUnits, 
    otherFields, 
    setFields 
}) => {
    const dayTypes = Array.isArray(constants?.DAY_TYPE) ? constants.DAY_TYPE : [];
    
    const getValueToUse = (key, transform) => {
        return modified && typeof modified === "object" && Object.hasOwn(modified, key) 
            ? modified[key] 
            : typeof transform === "function" ? transform(submissionType?.[key]) : submissionType?.[key];
    };

    const mapDayTypeSelectionToText = (text) => dayTypes.find(dayType => dayType.displayValue === text)?.text;

    const submissionTypeCategory = { text: submissionType?.categoryName, value: submissionType?.categoryId };

    const activeSubmissionTypeCategories = Object.values(categories).map((option) => {
        return {
            text: option.name,
            value: option.id
        };
    });

    const allCategories = [submissionTypeCategory, ...activeSubmissionTypeCategories];

    const uniqueCategories = allCategories.filter((val, index, self) => index === self.findIndex(obj => (obj.value === val.value)) && val.text !== undefined);

    return (
        <div id="details">   
            {[
                {
                    title: "Name",
                    key: "name"
                },
                {
                    title: "ID",
                    key: "specifier"
                }
            ].map(({ title, key }) => {
                return (
                    <EditableDetail
                        title={title}
                        key={`editable-detail-sub-type-${key}`}
                        value={getValueToUse(key)}
                        readOnly={!isEditable}
                        onValueUpdated={(e) => {
                            setModified({
                                ...modified,
                                [key]: e.target.value
                            });
                        }}
                    />
                );
            })}
            {addingDescription 
                ? <TextAreaDetail
                    title="Description (Optional)"
                    key="description"
                    placeholder="Description of submission type (optional)..."
                    strict={true}
                    readOnly={!isEditable}
                    value={getValueToUse("description")}
                    onValueUpdated={(e) => {
                        setModified({
                            ...modified,
                            description: e.target.value
                        });
                    }}
                    passedClasses='input__field--saeTmp'
                    preventNewLine={true}
                />
                : (<span className="input input--sae input--filled">
                    <label className="input__label input__label--sae">
                        <span className="input__label-content input__label-content--sae">
                            Description (Optional)
                        </span>
                    </label>
                    { isEditable 
                        ? <Button
                            size="small"
                            type="link"
                            onClick={() => setAddingDescription(true)}
                            style={{ marginTop: "15px", boxShadow: "none", border: "none" }}
                        >
                            + Add
                        </Button>
                        : null 
                    }
                </span>
                )
            }
            <SelectableDetail
                title="Category"
                passedKey="category-selection"
                options={uniqueCategories}
                strict={true}
                readOnly={!isEditable}
                value={getValueToUse("categoryId")}
                onValueUpdated={(value) => {
                    const category = categories.find(category => category.id === value);
                    setModified(getModifiedForCategory(category, modified));
                    setFields(getSubmissionTypeSelectFields({ 
                        category, 
                        businessUnits,
                        constants,
                        submissionType
                    }));
                }}
            />


            {otherFields.map(({ title, key, options, readOnly = false, daysTypeFields = false }, index) => {
                return (
                    <div id={title} key={`sd-${key}-${index}`} style={{ display: "flex" }}>
                        <div style={{ width: "100%" }}>
                            <SelectableDetail
                                title={title}
                                passedKey={`submission-type-selection-${index}`}
                                readOnly={!isEditable || readOnly}
                                value={getValueToUse(key)}
                                strict={true}
                                onValueUpdated={(value) => {
                                    setModified({
                                        ...modified,
                                        [key]: value
                                    });
                                }}
                                options={options}
                            />
                        </div>
                        {daysTypeFields 
                            ? <Radio.Group
                                style={{ display: "flex", justifyItems: "center", alignItems: "center" }}
                                name="daysTypeFields"
                                disabled={!isEditable || readOnly}   
                                onChange={(e) => {
                                    setModified({
                                        ...modified,
                                        [`${key}Type`]: e.target.value
                                    });
                                }}
                                defaultValue={getValueToUse(
                                    `${key}Type`, 
                                    mapDayTypeSelectionToText
                                )}
                            >
                                { dayTypes.map(({ displayValue, text }) => (
                                    <Radio key={displayValue} style={{ width: "150px", justifyContent: "space-evenly" }} value={text}>
                                        {displayValue} days
                                    </Radio>
                                ))}
                            </Radio.Group>
                            : null 
                        }
                    </div>
                );
            })}
        </div>
    );
};