import React, { useEffect, useState, useContext } from "react";
import { Main } from "../common/Main/index.js";
import { Card } from "antd";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { UserContext } from "../Application/UserContext.js";
import { checkUserChangeLogViews } from "../../utils/changeLogPermissionFunctions.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { SubmissionChangelogs } from "./submission-changelogs.js";
import { SubmissionTypeChangelogs } from "./submission-type-changelogs.js";
import { ActorChangelogs } from "./actor-changelogs.js";
import { ContractTypePlanChangelogs } from "./contract-type-plan-changelogs.js";
import { OrganizationChangelogs } from "./organization-changelogs.js";
import { UsersChangelogs } from "./users-changelogs.js";
import { LibraryChangelogs } from "./library-changelogs.js";
import { FilterDomainOptionsQuery } from "./queries.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";
import { SubmissionTypeCategoryChangelogs } from "./submission-type-category-changelogs.js";


// helper functions :: 
const getChangelogsTitle = (title, subject, finalCrumb) => {
    if (subject === "acting-user") {
        return `Changelogs ${finalCrumb}`;
    } else if (title & finalCrumb) {
        `${title}: ${finalCrumb}`;
    } else {
        return title ? title : "Changelogs";
    }
};

const getChangelogsData = () => {
    return [
        {
            key: "submissions",
            subject: "submissions",
            breadcrumb: <PCDULink to={"/changelogs/submissions"} tooltip="Submission Changelogs"> Submissions </PCDULink>,
            title: "Submission Changelogs",
            subtitle: "Events related to submissions",
            getFinalCrumb: (subjectData) => subjectData.specifier 
        },
        {
            key: "submission-types",
            subject: "submission-types",
            breadcrumb: <PCDULink to={"/changelogs/submission-types"} tooltip="Submission Type Changelogs"> Submission Types </PCDULink>,
            title: "Submission Type Changelogs",
            subtitle: "Events related to submission types",
            getFinalCrumb: (subjectData) => subjectData.specifier
        },
        {
            key: "contract-type-plans",
            subject: "contract-type-plans",
            breadcrumb: <PCDULink to={"/changelogs/contract-type-plans"} tooltip="Contract Type Plan Changelogs"> Contract Type Plans </PCDULink>,
            title: "Contract Type Plan Changelogs",
            subtitle: "Events related to Contract Type Plans", 
            getFinalCrumb: ({ specifier }) => specifier 
        },
        {
            key: "acting-user",
            subject: "acting-user",
            breadcrumb: null,
            title: "Changelogs by User",
            excludeFromIndex: true,
            getFinalCrumb: (user) => `By User: ${user.name}`
        },
        {
            key: "user",
            subject: "user",
            breadcrumb: <PCDULink to={"/changelogs/user"} tooltip="User Changelogs"> Users </PCDULink>,
            title: "User Changelogs",
            subtitle: "Events related to Users",
            getFinalCrumb: (user) => user.name
        },
        {
            key: "organization",
            subject: "organization",
            breadcrumb: <PCDULink to={"/changelogs/organization"} tooltip="Organization Changelogs"> Organization </PCDULink>,
            title: "Organization Changelogs",
            subtitle: "Events related to Organizations",
            getFinalCrumb: (subjectData) => `${subjectData.specifier} - ${subjectData.name}`
        },
        {
            key: "libraryFolder",
            subject: "libraryFolder",
            breadcrumb: <PCDULink to={"/changelogs/library"} tooltip="Library Changelogs"> Library </PCDULink>,
            title: "Library Changelogs",
            subtitle: "Events related to Library"
        },
        {
            key: "submissionTypeCategory",
            subject: "submissionTypeCategory",
            breadcrumb: <PCDULink to={"/changelogs/submissionTypeCategory"} tooltip="Submission Type Category Changelogs"> Submission Type Category </PCDULink>,
            title: "Category Changelogs",
            subtitle: "Events related to Submission Type Category"
        }
    ];
};

const getActions = (allChangelogActions, key) => {
    const targetObj = allChangelogActions?.[key] ?? {};
    return Object.values(targetObj).sort();
};

const ChangelogCard = ({
    subject,
    title = "",
    subtitle = ""
}) => {
    return (
        <div style={{width: "400px"}}>
            <PCDULink to={`/changelogs/${subject}`}>
                <Card
                    hoverable={true}
                    style={{
                        position: "relative",
                        paddingRight: "48px",
                        height: "100%"
                    }}
                >
                    <h3>{title}</h3>
                    <h4 style={{ color: "#8c8c8c", fontWeight: 600 }}>{subtitle}</h4>
                </Card>
            </PCDULink>
        </div>
    );
};


export const AuditChangelogs = (() => {
    const CHANGELOGS = getChangelogsData();
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);
    const params = useParams();
    const location = useLocation();
    const subject = params.subject;
    const subjectId = params.id;

    const [subjectData, setSubjectData] = useState(null);
    const {
        userPermViewChangeLog,
        userPermViewSubmissionLogs,
        userPermViewUserLogs,
        userPermViewSubmissionTypeLogs,
        userPermViewSubmissionTypeCategoryLogs,
        userPermViewContractTypePlanLogs,
        userPermViewOrganizationLogs,
        userPermViewLibraryLogs
    } = useContext(UserContext);

    useEffect(() => {
        if (subjectId !== subjectData?.id) {
            setSubjectData(null);
        }
    }, [location, setSubjectData]);

    const changelog = CHANGELOGS.find(changelog => changelog.subject === subject); 

    let finalCrumb;
    if (subjectData && subjectId) {
        if (typeof changelog.getFinalCrumb === "function") {
            finalCrumb = changelog.getFinalCrumb(subjectData);
        } else {
            finalCrumb = subjectId;
        }
    } 

    const breadcrumbs = [
        lastHistoryBreadcrumb?.label?.includes("Changelogs") 
            ? null 
            : <PCDULink to="/changelogs" tooltip="Changelogs">Changelogs</PCDULink>, 
        changelog?.breadcrumb,
        finalCrumb
    ].filter(Boolean);

    const {
        error: filterOptionsDataError,
        loading: filterOptionsDataLoading,
        data: filterOptionsData
    } = useQuery(FilterDomainOptionsQuery);

    if (filterOptionsDataError) {
        return handleGraphQLError(filterOptionsDataError);
    }

    if (filterOptionsDataLoading) {
        return <LoadingContent />;
    }


    const CHECKEDCHANGELOGS = checkUserChangeLogViews({
        userPermViewSubmissionLogs,
        userPermViewUserLogs,
        userPermViewSubmissionTypeLogs,
        userPermViewSubmissionTypeCategoryLogs,
        userPermViewContractTypePlanLogs,
        userPermViewOrganizationLogs,
        userPermViewLibraryLogs
    },
    CHANGELOGS);

    if (!userPermViewChangeLog) {
        return <NoPermission />;
    }

    const changelogActions = filterOptionsData?.constants?.CHANGELOG_ACTIONS ?? {};
    const roles = filterOptionsData?.roles ?? [];

    return (
        <Main
            title={getChangelogsTitle(changelog?.title, subject, finalCrumb)}
            breadcrumbs={breadcrumbs}
        >
            { !subject && 
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", minWidth: "500px", maxWidth: "1000px"}}>
                    {
                        CHECKEDCHANGELOGS.filter(({ excludeFromIndex }) => excludeFromIndex !== true).map(changelog => {
                            return <div key={`changelog-card-container-${changelog.key}`} className="spaceAbove-sm spaceBelow-sm">
                                <ChangelogCard
                                    {...changelog}
                                />
                            </div>;
                        })
                    }
                </div> 
            }
            { subject === "acting-user" && 
                <ActorChangelogs 
                    // we pass this one all changelog actions :: 
                    actions={changelogActions}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}
                />
            }
            { subject === "submissions" && 
                <SubmissionChangelogs 
                    actions={getActions(changelogActions, "submission")}
                    roles={roles}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}
                /> 
            }
            { subject === "user" &&
                <UsersChangelogs 
                    actions={getActions(changelogActions, "users")}
                    roles={roles}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}
                /> 
            }
            { subject === "submission-types" &&
                <SubmissionTypeChangelogs 
                    actions={getActions(changelogActions, "submissionType")}
                    roles={roles}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}
                /> 
            }

            { subject === "contract-type-plans" &&
                <ContractTypePlanChangelogs
                    actions={getActions(changelogActions, "contractTypePlan")}
                    roles={roles}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}    
                />
            }
            { subject === "organization" &&
                <OrganizationChangelogs 
                    actions={getActions(changelogActions, "organization")}
                    roles={roles}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}
                /> 
            }
            { subject === "libraryFolder" &&
                <LibraryChangelogs 
                    actions={getActions(changelogActions, "library")}
                    roles={roles}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}
                /> 
            }
            { subject === "submissionTypeCategory" &&
                <SubmissionTypeCategoryChangelogs 
                    actions={getActions(changelogActions, "submissionTypeCategory")}
                    roles={roles}
                    setSubjectData={setSubjectData}
                    subjectData={subjectData}
                /> 
            }
        </Main>
    );
});
