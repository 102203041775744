import React, { useEffect, useState, useContext } from "react";
import { Pagination, Radio, Button, Modal } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { formatTimeValue, isUndefinedOrNull } from "../../utils/functions.js";
import { handleGraphQLError, handleMutations } from "../../utils/errorHandling.js";
import { MODIFY_LIBRARY_FOLDER } from "../../constants/mutations.js";
import { Table } from "../common/Table/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { UserContext } from "../Application/UserContext.js";
import { searchable } from "../../utils/index.js";
import { FoldersQuery } from "./queries.js";

const { confirm } = Modal;

export const LibraryFoldersTable = ({
    subtitle,
    createFolderButton,
    isActive,
    contractTypeId,
    libraryFolderId,
    setCurrentFolderName,
    setCurrentFolderId, 
    locationState,
    refetchFolders,
    setRefetchFolders
}) => {
    const [sortOn, setSortOn] = useState("name");
    const [sortBy, setSortBy] = useState("ascend");
    const [foldersPage, setFoldersPage] = useState(1);
    const [foldersPageSize, setFoldersPageSize] = useState(10);
    const [searchInput, setSearchInput] = useState("");
    const [activeFilter, setActiveFolderFilter] = useState(isActive);
    const [folderSelection, setFolderSelection] = useState([]);
    const [modifyLibraryFolder] = useMutation(MODIFY_LIBRARY_FOLDER);
    const [dataToUse, setDataToUse] = useState(null);
    const { userPermUploadnModifyLibDoc } = useContext(UserContext);
    
    const inArchivedFolder = !isActive;
    const archiveView = !activeFilter;
    const foldersOffset = Math.max(0, ((foldersPage - 1) * foldersPageSize));

    const resetStateOnFolderChange = (active) => {
        setFoldersPage(1);
        setFoldersPageSize(10);
        setActiveFolderFilter(active);
        setFolderSelection([]);
        setSearchInput("");
        setSortOn("name");
        setSortBy("ascend");
    };

    const { data, previousData, error, loading, refetch } = useQuery(FoldersQuery, {
        variables: {
            search: false,
            libraryFolderId,
            contractTypeId,
            activefolderFilter: activeFilter,
            foldersSortOn: sortOn,
            foldersSortBy: sortBy,
            foldersOffset,
            foldersLimit: foldersPageSize,
            folderSelection,
            folderNameSearch: searchInput          
        },
        fetchPolicy: "no-cache"
    });
   
    useEffect(() => {
        if (locationState?.clearOut) {
            resetStateOnFolderChange(true);
        }
    }, [locationState]);

    useEffect(() => {
        if (refetchFolders === true) {
            setRefetchFolders(false);
            refetch();
        }
    }, [refetchFolders]);

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    }, [data]);

    if (error) {
        return handleGraphQLError(error);
    } 

    if (!dataToUse) {
        return null;
    }

    const folders = Array.isArray(dataToUse?.libraryFolders) 
        ? dataToUse.libraryFolders 
        : previousData?.libraryFolders ?? []; 
        
    const folderCount = folders[0]?.count ?? 0;

    const searchableName = typeof setSearchInput === "function" && typeof searchInput === "string";
  
    const showConfirmArchiveFolder = () => {
        const folderNames = folders.map(({ name, id }) => folderSelection.includes(id) ? name : null)
            .filter(item => !isUndefinedOrNull(item));
        
        confirm({
            title:
                `Do you really want to ${activeFilter ? "Archive" : "De-Archive"} the ${folderNames.length === 1 ? "folder" : "folders" }: ${
                    folderNames.join(", ")
                }?`,
            content: `All documents and folders inside the folders listed above will also be ${activeFilter ? "archived" : "de-archived"}.`,
            okText: "Yes",
            async onOk() {
                const mutations = folderSelection.map((id) => {
                    return modifyLibraryFolder({
                        variables: {
                            id,
                            active: !activeFilter

                        }
                    });
                });

                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${folderSelection.length === 1 ? "Folder" : "Folders"} successfully modified`
                });
                setFolderSelection([]);
                await refetch();
            }
        });
    };

    const archiveFolder = (
        <div style={{ marginBottom: "15px" }}>
            <Button
                style={{
                    display: userPermUploadnModifyLibDoc ? "block" : "none"
                }}
                className="ownLine"
                type="danger"
                size="small"
                onClick={showConfirmArchiveFolder}
                disabled={!isActive || folderSelection.length <= 0}
            >
                {`${activeFilter ? "Archive" : "De-Archive"} ${folderSelection?.length ?? 0} ${folderSelection.length === 1 ? "folder" : "folders"}`}
            </Button>
        </div>
    );

    const toggleArchiveView = <Radio.Group
        defaultValue={activeFilter}
        value={activeFilter}
        onChange={(e) => {
            setActiveFolderFilter(e.target.value);
            setFoldersPage(1);
        }}
        buttonStyle="solid"
    >
        <Radio.Button value={true} > Active </Radio.Button>
        <Radio.Button value={false}> Archived </Radio.Button>
    </Radio.Group>;

    return (
        <div>
            <div>
                { subtitle && <h2>{ subtitle }</h2> }
                <h2> {`${archiveView ? "Archived Folders" : "Folders"}`} </h2>
                <div style={{ marginBottom: "10px", marginTop: "-5px" }}>
                    { createFolderButton }
                </div>
            </div>
            <Table
                clearFiltersButton={archiveFolder}
                rightAlignedButtonBarElement={toggleArchiveView}
                loading={loading}
                columns={[
                    (searchableName
                        ? searchable({
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            sortDirections: ["ascend", "descend", "ascend"],
                            defaultSortOrder: "ascend",
                            searchedText: searchInput,
                            handleSearch: setSearchInput,
                            setPage: setFoldersPage,
                            handleReset: () => setSearchInput(""),
                            render: (name, { id, name: folderName, active }) => {
                                return (
                                    <PCDULink
                                        preventDefault={false}
                                        key={id}
                                        to={{}}
                                        onClick={() => {
                                            resetStateOnFolderChange(active);
                                            setCurrentFolderName(folderName);
                                            setCurrentFolderId(id);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} className="far" />
                                        { name }
                                    </PCDULink>
                                );
                            }
                        })
                        : {
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                            sorter: true,
                            sortDirections: ["ascend", "descend", "ascend"],
                            defaultSortOrder: "ascend",
                            render: (name, { id, name: folderName, active }) => {
                                return (
                                    <PCDULink
                                        preventDefault={false}
                                        key={id}
                                        to={{}}
                                        onClick={() => {
                                            resetStateOnFolderChange(active);
                                            setCurrentFolderName(folderName);
                                            setCurrentFolderId(id);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFolder} style={{ marginRight: "10px" }} className="far" />
                                        {name}
                                    </PCDULink>
                                );
                            }
                        }
                    ),
                    {
                        title: "Date Created",
                        dataIndex: "dateCreated",
                        key: "dateCreated",
                        sorter: true,
                        sortDirections: ["descend", "ascend", "descend"],
                        render: (dateCreated) => {
                            const dateString = formatTimeValue(dateCreated, true);
                            return (<span>{dateString}</span>);
                        }
                    },
                    {
                        title: "Author",
                        dataIndex: ["author", "name"],
                        key: "author",
                        sorter: true, 
                        sortDirections: ["ascend", "descend", "ascend"]
                    },
                    {
                        title: "Archived",
                        key: "active",
                        dataIndex: "active",
                        sorter: true,
                        sortDirections: ["ascend", "descend", "ascend"],
                        render: (active) => {
                            return active ? "No" : "Yes";
                        }
                    }

                ]}
                dataSource={folders}
                rowKey="id"
                pagination={false}
                rowSelection={(userPermUploadnModifyLibDoc && !inArchivedFolder) && {
                    selectedRowKeys: folderSelection,
                    onChange: (selectedRowKeys) => {
                        setFolderSelection(selectedRowKeys);
                    }
                }}
                onChange={(_pagination, _filters, { field, order, columnKey }) => {
                    setSortBy(order);
                    if (columnKey === "author") {
                        setSortOn("author");
                    } else {
                        setSortOn(field);
                    }
                }}
            />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onChange={(targetPage, pageSize) => {
                    setFoldersPage(targetPage);
                    setFoldersPageSize(pageSize);
                }}
                current={foldersPage}
                pageSize={foldersPageSize}
                pageSizeOptions={["10", "25", "50", "100"]}
                total={folderCount}
            />
        </div>
    );
};
